export const PAGE_WWW = {
    WWW_HOME: "Home",
    WWW_HOME_CRACK: "Home/Crack",
    WWW_HOME_CATEGORY: "Home/Category",
    WWW_SUPPORT: "Support",
    WWW_ABOUT: "About",
    WWW_CHAT: "Chat",
    WWW_CUSTOM_LANDING: "Landing",
    WWW_BLOG: "Blog",
    WWW_BLOG_CRACK: "Blog/BlogCrack",
} as const;
