import { DEFAULT_LANGUAGE, DATA_LANGUAGE } from "@common/content/language";
import type { UuidLanguage } from "@common/content/language";

import { TermsES } from "@common/content/text/terms-es";
import { TermsEN } from "@common/content/text/terms-en";
import { PrivacyES } from "@common/content/text/privacy-es";
import { PrivacyEN } from "@common/content/text/privacy-en";

const email = "team@mastercracks.club";

const date = new Date();
const dateES = new Intl.DateTimeFormat("es-mx", { dateStyle: "full" }).format(date);
const dateEN = new Intl.DateTimeFormat("en-us", { dateStyle: "full" }).format(date);

const termsES: string = TermsES.replace(/{{DATE}}/g, dateES).replace(/{{EMAIL}}/g, email);
const termsEN: string = TermsEN.replace(/{{DATE}}/g, dateEN).replace(/{{EMAIL}}/g, email);
const privacyES: string = PrivacyES.replace(/{{DATE}}/g, dateES).replace(/{{EMAIL}}/g, email);
const privacyEN: string = PrivacyEN.replace(/{{DATE}}/g, dateEN).replace(/{{EMAIL}}/g, email);

export const DATA_TEXT = get({
    version: {
        "en-us": "Version",
        "es-mx": "Versión",
    },
    "no-copy": {
        "en-us": "",
        "es-mx": "",
    },
    "website-footer-date": {
        "en-us": "Last access: %",
        "es-mx": "Último acceso: %",
    },
    test: {
        "en-us": "<h3 data-preset='cancun'>Hello <span data-preset='torreon'>world</span></h3>",
        "es-mx": "<h3 data-preset='cancun'>Hola <span data-preset='torreon'>mundo</span></h3>",
    },
    "delete-account-reason": {
        "en-us": "Tell us the reason",
        "es-mx": "Dinos el motivo",
    },
    "download-ios": {
        "en-us": "Instructions to download MasterCracks on iOS",
        "es-mx": "Instrucciones para descargar MasterCracks en iOS",
    },
    "download-ios-1": {
        "en-us":
            "We are sorry, at the moment we are not available in this operating system. We will notify you when we are ready!",
        "es-mx":
            "Lo sentimos, por el momento no estamos disponibles en éste sistema operativo. ¡Te notificaremos cuando estemos listos!",
    },
    "download-android": {
        "en-us": "Instructions to download MasterCracks on Android",
        "es-mx": "Instrucciones para descargar MasterCracks en Android",
    },
    "download-android-1": {
        "en-us": "1. Accept the App Center invitation from your email.",
        "es-mx": "1. Acepta la invitación del App Center de tu correo electrónico.",
    },
    "download-android-2": {
        "en-us":
            "2. Create an account in App Center by logging in with the email to which the invitation arrived.",
        "es-mx":
            "2. Crea una cuenta en App Center loggeandote con el correo al que te llegó la invitación.",
    },
    "download-android-3": {
        "en-us": "3. Choose a username.",
        "es-mx": "3. Elige un nombre de usuario.",
    },
    "download-android-4": {
        "en-us": "4. Click on “Join organization”.",
        "es-mx": "4. Da clic en “Join organization”.",
    },
    "download-android-5": {
        "en-us":
            "5. From your Android device, start installing the app from the second email you received from the App Center.",
        "es-mx":
            "5. Desde tu dispositivo Android comienza a instalar la app desde el segundo correo que recibiste del App Center.",
    },
    "download-android-6": {
        "en-us": "6. Sign in with the service you created your App Center account with.",
        "es-mx": "6. Inicia sesión con el servicio con el que creaste tu cuenta de App Center.",
    },
    "download-android-7": {
        "en-us": "7. Download the version of the app you received.",
        "es-mx": "7. Descarga la versión de la app que recibiste.",
    },
    "download-android-8": {
        "en-us": "8. Wait for the app to download and open it.",
        "es-mx": "8. Espera a que se descargue la app y ábrela.",
    },
    "download-android-9": {
        "en-us": "9. Install the app.",
        "es-mx": "9. Instala la aplicación.",
    },
    "download-android-10": {
        "en-us": "10. Confirm additional windows and permissions.",
        "es-mx": "10. Confirma las ventanas adicionales.",
    },
    "download-android-10-desc": {
        "en-us":
            "Depending on the model and version of the Operating System of your device, some additional confirmations may appear: In this case, click on “install anyway” and “Do not send”.",
        "es-mx":
            "Dependiendo del modelo y versión del Sistema Operativo de tu dispositivo, podrían aparecerte algunas confirmaciones adicionales: En este caso da clic en “instalar de todas formas” y “No enviar”.",
    },
    "download-android-11": {
        "en-us": "11. Open the app.",
        "es-mx": "11. Abre la aplicación.",
    },
    "crack-stats-performance": {
        "en-us": "Performance",
        "es-mx": "Rendimiento",
    },
    "crack-stats-teams": {
        "en-us": "Teams",
        "es-mx": "Equipos",
    },
    "crack-stats-titles": {
        "en-us": "Titles",
        "es-mx": "Títulos",
    },
    "crack-bio-pavel-pardo": {
        "en-us":
            "From making his debut in Atlas, to conquering the Bundesliga. Yes, Pavel was always chasing his dreams and making his way through soccer’s elite. He appeared in 2 World Cups and was loved by his fans. With 430 games and a strong winning mentality he was nicknamed “El Comandante”.",
        "es-mx":
            "Su mentalidad ganadora lo guió a ser el primer mexicano en conquistar la Bundesliga, jugar dos mundiales y más de 430 partidos a nivel profesional. Se ganó el respeto de los alemanes que lo llamaron “el Comandante”.",
    },
    "crack-stats-pavel-pardo-title-1": {
        "en-us": "Performance",
        "es-mx": "Rendimiento",
    },
    "crack-stats-pavel-pardo-body-1": {
        "en-us":
            "287 games with clubs, 17 goals, 49 assists, 148 games with the senior team, 11 goals.",
        "es-mx":
            "287 partidos con clubes, 17 goles, 49 asistencias, 148 partidos con selección mayor 11 goles.",
    },
    "crack-stats-pavel-pardo-title-2": {
        "en-us": "Teams",
        "es-mx": "Equipos",
    },
    "crack-stats-pavel-pardo-body-2": {
        "en-us": "Atlas, Tecos, América, Stuttgart, Chicago Fire.",
        "es-mx": "Atlas, Tecos, América, Stuttgart, Chicago Fire.",
    },
    "crack-stats-pavel-pardo-title-3": {
        "en-us": "Titles",
        "es-mx": "Títulos",
    },
    "crack-stats-pavel-pardo-body-3": {
        "en-us":
            "2X Gold Cup, 2X Mexican League, 1X Concacaf Champions Cup, 1X FIFA Confederations Cup, 1X German Champion.",
        "es-mx": "2 Copa Oro, 2 Liga MX, 1 Concachampions,1 Copa Confederaciones, 1 Copa Alemana.",
    },
    "crack-bio-jo-canales": {
        "en-us":
            "He was a professional player until 2012, after which he became a Performance Coach, he prepares Checo Pérez to withstand the demands of a machine that advances at 300 kilometers per hour. To Diego Reyes, Miguel Layún and other players to improve their reaction, power, muscle strength and speed. Simply the best training the bests.",
        "es-mx":
            "Fue jugador profesional hasta 2012, después se convirtió en entrenador de atletas de alto rendimiento. A Checo Pérez lo prepara para soportar la exigencia de una máquina que avanza a 300 kilómetros por hora, a Diego Reyes, Miguel Layún y otros jugadores para mejorar su reacción, potencia, fuerza muscular y velocidad. Simplemente el mejor entrenando a los mejores.",
    },
    "crack-stats-jo-canales-title-1": {
        "en-us": "Performance Coach",
        "es-mx": "Coach de Performance",
    },
    "crack-stats-jo-canales-body-1": {
        "en-us":
            "F1: Sergio “Checo” Perez | Soccer: Miguel Layún, Diego Reyes, Hugo Gonzalez, Javier “Chicharito” Hernandez, Eduardo Herrera.",
        "es-mx":
            "F1: Sergio “Checo” Pérez | Fútbol: Miguel Layún, Diego Reyes, Hugo Gonzalez, Javier “Chicharito” Hernandez, Eduardo Herrera.",
    },
    "crack-stats-jo-canales-title-2": {
        "en-us": "Teams",
        "es-mx": "Equipos",
    },
    "crack-stats-jo-canales-body-2": {
        "en-us": "America, Pumas, Puebla, Lobos BUAP.",
        "es-mx": "América, Pumas, Puebla, Lobos BUAP.",
    },
    "crack-stats-jo-canales-title-3": {
        "en-us": "Ambassador",
        "es-mx": "Embajador",
    },
    "crack-stats-jo-canales-body-3": {
        "en-us": "Lulu Lemon.",
        "es-mx": "Lulu Lemon.",
    },
    "crack-bio-rafa-marquez": {
        "en-us":
            "With only 20 years of age and a one way ticket to Europe, it was a stairway to heaven for “El Kaiser”. On his count he achieved 2 champions league titles, 4 league titles with FC Barcelona and 5 World Cup appearances. His leadership inside and outside the field made him go down in history as one of the best players Mexico has ever seen.",
        "es-mx":
            "A los 20 años se fue a Europa para ganarlo todo: 12 títulos, entre ellos dos Champions League. Su liderazgo y talento lo convirtieron en uno de los mejores jugadores en la historia de México.",
    },
    "crack-stats-rafa-marquez-title-1": {
        "en-us": "Performance",
        "es-mx": "Rendimiento",
    },
    "crack-stats-rafa-marquez-body-1": {
        "en-us":
            "561 club games, 21 goals, 26 assists. 147 games with the national team, 17 goals.",
        "es-mx":
            "561 partidos en clubes, 21 goles, 26 asistencias. 147 partidos con selección mayor, 17 goles.",
    },
    "crack-stats-rafa-marquez-title-2": {
        "en-us": "Teams",
        "es-mx": "Equipos",
    },
    "crack-stats-rafa-marquez-body-2": {
        "en-us":
            "HC: Barcelona Athletic | Barcelona, ​​Monaco, Atlas, Leon, NY Red Bull, Hellas Verona.",
        "es-mx":
            "DT: Barcelona Athletic | Barcelona, Mónaco, Atlas, León, NY Red Bull, Hellas Verona.",
    },
    "crack-stats-rafa-marquez-title-3": {
        "en-us": "Titles",
        "es-mx": "Títulos",
    },
    "crack-stats-rafa-marquez-body-3": {
        "en-us":
            "2 Champions League, 2 Gold Cup, 4 Spanish Leagues, 1 Copa del Rey, 2 Spanish Super Champion, 1 French League, 1 French Cup, 1 Club World Cup, MX League Champion, 1 Confederations Cup, 1 European Super Champion.",
        "es-mx":
            "2 Champions League, 2 Copa Oro, 4 Ligas Española, 1 copa del Rey, 2 Supercampeón de España, 1 Liga de Francia, 1 Copa de Francia, 1 Mundial de clubes,  Campeón en Liga MX, 1 Copa Confederaciones, 1 supercampeón de Europa.",
    },
    "crack-bio-carlos-salcido": {
        "en-us":
            "Adversity didn't prevent Carlos Salcido from winning a championship in Mexico and in Holland, nor from becoming an undisputed player for the Mexican National team. For him, Dream big or go home is winning an Olympic medal and starring in 3 World Cups. ",
        "es-mx":
            "Ninguna adversidad le impidió ser campeón en México y Países Bajos, ni tampoco ser referente de la única Selección Mexicana en ganar una medalla de oro olímpica o de jugar tres copas del mundo.",
    },
    "crack-stats-carlos-salcido-title-1": {
        "en-us": "Performance",
        "es-mx": "Rendimiento",
    },
    "crack-stats-carlos-salcido-body-1": {
        "en-us":
            "522 club games, 14 goals, 15 assists. 120 games with the national team, 10 goals.",
        "es-mx":
            "522 partidos en clubes, 14 goles, 15 asistencias. 120 partidos con selección mayor, 10 goles.",
    },
    "crack-stats-carlos-salcido-title-2": {
        "en-us": "Teams",
        "es-mx": "Equipos",
    },
    "crack-stats-carlos-salcido-body-2": {
        "en-us": "Chivas, PSV, Fulham, Tigres, Veracruz",
        "es-mx": "Chivas, PSV, Fulham, Tigres, Veracruz.",
    },
    "crack-stats-carlos-salcido-title-3": {
        "en-us": "Titles",
        "es-mx": "Títulos",
    },
    "crack-stats-carlos-salcido-body-3": {
        "en-us":
            "Olympic Gold Medal, 1 Dutch League, 1 Dutch Cup, 1 Gold Cup, 2 Liga MX, 1 Concachampions, 2 Copa MX.",
        "es-mx":
            "Medalla de Oro olímpica, 1 Liga Holandesa, 1 Copa Holandesa, 1 Copa Oro, 2 Liga MX, 1 Concachampions, 2 Copa MX.",
    },
    "crack-bio-norma-palafox": {
        "en-us":
            "Elite soccer player, Influencer, businesswoman, and soon to be lawyer. All that within only 23 years of age, Norma Palafox was born to win, whether it is conquering a title with Chivas or being undefeated in Exatlon her determination and passion has brought her pure bliss.",
        "es-mx":
            "Futbolista, campeona, empresaria, influencer y futura abogada, todo eso con apenas 23 años. Su nombre ya es una marca personal que conquista títulos en la cancha, televisión, redes sociales y los negocios.",
    },
    "crack-bio-norma-palafox-desc": {
        "en-us": "Until May of the year 2022",
        "es-mx": "Hasta Mayo del año 2022",
    },
    "crack-stats-norma-palafox-title-1": {
        "en-us": "Performance",
        "es-mx": "Rendimiento",
    },
    "crack-stats-norma-palafox-body-1": {
        "en-us": "93 club games, 18 goals.",
        "es-mx": "93 partidos en clubes, 18 goles.",
    },
    "crack-stats-norma-palafox-title-2": {
        "en-us": "Teams",
        "es-mx": "Equipos",
    },
    "crack-stats-norma-palafox-body-2": {
        "en-us": "Chivas Women, Pachuca Women, Atlas Women",
        "es-mx": "Chivas Femenil, Pachuca Femenil, Atlas Femenil",
    },
    "crack-stats-norma-palafox-title-3": {
        "en-us": "Titles",
        "es-mx": "Títulos",
    },
    "crack-stats-norma-palafox-body-3": {
        "en-us": "Women's MX League, Exatlon USA",
        "es-mx": "Liga MX Femenil, Exatlón USA",
    },
    "crack-bio-oswaldo-sanchez": {
        "en-us":
            "King of the net, leader and captain. If someone knows well the Liga Mx is “San Oswaldo”. Conquering 4 teams and winning titles, personal awards and always striving for greatness in all of the jerseys he wore. That is Oswaldo, now playing a very important role as a sports analyst and always breaking down the best in soccer.",
        "es-mx":
            "Líder, capitán y crack con una calidad en la portería cercana a la divinidad; no en vano lo apodaron “San Oswaldo”. Su técnica dejó escuela en el fútbol mexicano y su nombre se convirtió en sinónimo de campeonatos.",
    },
    "crack-stats-oswaldo-sanchez-title-1": {
        "en-us": "Performance",
        "es-mx": "Rendimiento",
    },
    "crack-stats-oswaldo-sanchez-body-1": {
        "en-us": "407 club games, 103 unbeaten fences. 98 games with the national team.",
        "es-mx": "407 partidos en clubes, 103 vallas invictas. 98 partidos con selección mayor.",
    },
    "crack-stats-oswaldo-sanchez-title-2": {
        "en-us": "Teams",
        "es-mx": "Equipos",
    },
    "crack-stats-oswaldo-sanchez-body-2": {
        "en-us": "Atlas, America, Chivas, Santos",
        "es-mx": "Atlas, América, Chivas, Santos.",
    },
    "crack-stats-oswaldo-sanchez-title-3": {
        "en-us": "Titles",
        "es-mx": "Títulos",
    },
    "crack-stats-oswaldo-sanchez-body-3": {
        "en-us": "6 Liga MX, 2 Gold Cup, 1 MX Cup.",
        "es-mx": "6 Liga MX, 2 Copa Oro, 1 Copa MX.",
    },
    "crack-bio-charlyn-corral": {
        "en-us":
            "She was set to inspire from a very young age, Charlyn Corral is a strong advocate to stop bullying and she always calls for a better world. She is the first mexican female soccer player to win a “Pichichi” with Atletico de Madrid.",
        "es-mx":
            "De las canchas de Ecatepec a convertirse en la primera mexicana en ganar un Pichichi, para Charlyn Corral no hay límites. Pero su mayor logro es y será abrir un camino inexistente para las mujeres futbolistas en México.",
    },
    "crack-bio-charlyn-corral-desc": {
        "en-us": "Until May of the year 2022",
        "es-mx": "Hasta Mayo del año 2022",
    },
    "crack-stats-charlyn-corral-title-1": {
        "en-us": "Performance",
        "es-mx": "Rendimiento",
    },
    "crack-stats-charlyn-corral-body-1": {
        "en-us": "112 club appearances, 76 goals. 15 games with the national team, 11 goals.",
        "es-mx": "112 partidos en clubes, 76 goles. 15 partidos con selección mayor, 11 goles.",
    },
    "crack-stats-charlyn-corral-title-2": {
        "en-us": "Teams",
        "es-mx": "Equipos",
    },
    "crack-stats-charlyn-corral-body-2": {
        "en-us": "Atletico Madrid Women, Levante Women, Pachuca Women, Merilappi.",
        "es-mx": "Atlético de Madrid Femenil,Levante Femenil, Pachuca Femenil, Merilappi.",
    },
    "crack-stats-charlyn-corral-title-3": {
        "en-us": "Titles",
        "es-mx": "Títulos",
    },
    "crack-stats-charlyn-corral-body-3": {
        "en-us":
            "Pichichi Español, SuperCopa Española, 2 medals in Central American and Caribbean games. Youngest player to be nationally selected for a junior world cup at age 11.",
        "es-mx":
            "Pichichi Español, SuperCopa Española, 2 medallas en juegos centroamericanos y del caribe. La jugadora más joven en ser seleccionada nacional para un mundial juvenil a los 11 años.",
    },
    "crack-bio-jared-borgetti": {
        "en-us":
            "The best Mexican header of all time, scorer by nature, the nicknamed “desert fox” broke records in practically all the clubs he played for.",
        "es-mx":
            "El mejor cabeceador mexicano de todos los tiempos,trotamundos y goleador nato, el apodado “zorro del desierto” rompió récords prácticamente en todos los clubes en los que jugó.",
    },
    "crack-stats-jared-borgetti-title-1": {
        "en-us": "Performance",
        "es-mx": "Rendimiento",
    },
    "crack-stats-jared-borgetti-body-1": {
        "en-us":
            "587 club games, 299 goals, 28 assists. 89 games with the national team, 46 goals.",
        "es-mx":
            "587 partidos en clubes, 299 goles, 28 asistencias. 89 partidos con selección mayor, 46 goles.",
    },
    "crack-stats-jared-borgetti-title-2": {
        "en-us": "Teams",
        "es-mx": "Equipos",
    },
    "crack-stats-jared-borgetti-body-2": {
        "en-us":
            "Atlas, Santos, Dorados, Pachuca, Bolton, Al-Ittihad, Cruz Azul, Monterrey, Chivas, Puebla, Morelia, León.",
        "es-mx":
            "Atlas, Santos, Dorados, Pachuca, Bolton, Al-Ittihad, Cruz Azul, Monterrey, Chivas, Puebla, Morelia, León.",
    },
    "crack-stats-jared-borgetti-title-3": {
        "en-us": "Titles",
        "es-mx": "Títulos",
    },
    "crack-stats-jared-borgetti-body-3": {
        "en-us": "2 Liga MX, 2 Interleague, 1 Gold Cup, 3 Times Top Scorer in Liga MX.",
        "es-mx": "2 Liga MX, 2 Interliga, 1 Copa Oro, 3 Veces Máximo goleador en la Liga MX.",
    },
    "crack-bio-gianluigi-buffon": {
        "en-us":
            "Considered by many the best goalkeeper in the world, “Gigi” is an example of leadership and loyalty, his security on the goal has led him to be world champion, multi-champion in Italy, and play more than 1000 games, a statistic that very few can show off.",
        "es-mx":
            "Considerado por muchos el mejor portero del mundo “Gigi” es ejemplo de liderazgo y lealtad, su seguridad bajo los 3 palos lo han llevado a ser campeón del mundo, multicampeón en Italia y jugar más de 1000 partidos, estadística que muy pocos pueden presumir.",
    },
    "crack-bio-gianluigi-buffon-desc": {
        "en-us": "Until the beginning of the year 2022",
        "es-mx": "Hasta el inicio del año 2022",
    },
    "crack-stats-gianluigi-buffon-title-1": {
        "en-us": "Performance",
        "es-mx": "Rendimiento",
    },
    "crack-stats-gianluigi-buffon-body-1": {
        "en-us": "948 club games, 422 clean sheets, 176 games with the senior team.",
        "es-mx": "948 partidos en clubes, 422 vallas invictas, 176 partidos con selección mayor.",
    },
    "crack-stats-gianluigi-buffon-title-2": {
        "en-us": "Teams",
        "es-mx": "Equipos",
    },
    "crack-stats-gianluigi-buffon-body-2": {
        "en-us": "Juventus, PSG, Parma.",
        "es-mx": "Juventus, PSG, Parma.",
    },
    "crack-stats-gianluigi-buffon-title-3": {
        "en-us": "Titles",
        "es-mx": "Títulos",
    },
    "crack-stats-gianluigi-buffon-body-3": {
        "en-us":
            "1 World Championship, 10 Italian Serie A leagues, 6 Italian Cups, 7 Italian Super Cups, 1 Ligue, 1 UEFA CUP, 1 Italian Serie B league, 1 French Cup. Best Soccer Player of the year in the Italian league in 2017.",
        "es-mx":
            "1 Campeonato del mundo, 10 ligas Serie A Italiana, 6 copas Italia, 7 supercopas italianas, 1 Liga, 1 UEFA CUP, 1 liga Serie B italiana, 1 copa de Francia. Mejor futbolista del año en la liga Italiana en el año 2017.",
    },
    "title-profile": {
        "en-us": "Profile",
        "es-mx": "Perfil",
    },
    "profile-progress": {
        "en-us": "Your Progress",
        "es-mx": "Tu Progreso",
    },
    "profile-minutes": {
        "en-us": "Total minutes",
        "es-mx": "Minutos totales",
    },
    "profile-workouts": {
        "en-us": "Total workouts",
        "es-mx": "Entrenamientos totales",
    },
    "profile-total": {
        "en-us": "Total %",
        "es-mx": "% totales",
    },
    "profile-delete-account-reason-title": {
        "en-us": "Delete account",
        "es-mx": "Borrar cuenta",
    },
    "profile-delete-account-reason-body": {
        "en-us": "Please, tell us why you're leaving us",
        "es-mx": "Por favor, dinos por qué te vas",
    },
    "profile-delete-account-reason-submit": {
        "en-us": "Continue",
        "es-mx": "Continuar",
    },
    "profile-delete-account-reason-reasons-price": {
        "en-us": "Too pricey",
        "es-mx": "Era muy costoso",
    },
    "profile-delete-account-reason-reasons-difficulty": {
        "en-us": "Too difficult",
        "es-mx": "Era muy difícil",
    },
    "profile-delete-account-reason-reasons-time": {
        "en-us": "I have no time",
        "es-mx": "No tengo tiempo",
    },
    "profile-delete-account-reason-reasons-like": {
        "en-us": "I didn't liked it",
        "es-mx": "No me ha gustado",
    },
    "profile-delete-account-reason-reasons-other": {
        "en-us": "Other",
        "es-mx": "Otros motivos",
    },
    "profile-delete-account-confirm-title": {
        "en-us": "Delete account",
        "es-mx": "Borrar cuenta",
    },
    "profile-delete-account-confirm-body": {
        "en-us": "Are you sure you want to permanently delete your account?",
        "es-mx": "¿Estás seguro que deseas eliminar definitivamente tu cuenta?",
    },
    "profile-delete-account-confirm-submit": {
        "en-us": "Delete account",
        "es-mx": "Borrar cuenta",
    },
    "profile-delete-account-confirm-sheet-title": {
        "en-us": "Deleted account",
        "es-mx": "Cuenta eliminada",
    },
    "profile-delete-account-confirm-sheet-body": {
        "en-us": "Your account and all your data have been deleted from Mastercracks",
        "es-mx": "Se eliminó con éxito tu cuenta de Mastercracks junto con toda tu información",
    },
    "subtitle-continue-watching": {
        "en-us": "Continue Watching",
        "es-mx": "Continuar Viendo",
    },
    "subtitle-completed": {
        "en-us": "Completed",
        "es-mx": "Completados",
    },
    "option-edit-info": {
        "en-us": "Edit Info",
        "es-mx": "Editar Perfil",
    },
    "option-sign-out": {
        "en-us": "Sign Out",
        "es-mx": "Cerrar Sesión",
    },
    "option-delete-account": {
        "en-us": "Delete account",
        "es-mx": "Borrar cuenta",
    },
    "option-payments": {
        "en-us": "Plans and Payments",
        "es-mx": "Planes y Pagos",
    },
    "option-payments-leave": {
        "en-us":
            "You are about to exit the app to our website to review your plan and payment details",
        "es-mx":
            "Estás a punto de salir de la app a nuestro sitio web para revisar los detalles de tu plan y pagos",
    },
    "option-payments-next": {
        "en-us": "Next",
        "es-mx": "Siguiente",
    },
    "title-cracks": {
        "en-us": "<h2 data-preset='cancun'>Meet the <span data-preset='torreon'>Cracks</span></h2>",
        "es-mx": "<h2 data-preset='cancun'>Nuestros <span data-preset='torreon'>Cracks</span></h2>",
    },
    "challenges-mixin-2": {
        "en-us":
            "<h3 data-preset='formosa'>Duet with the stars <span data-preset='puebla'>and earn their reactions!</span></h3>",
        "es-mx":
            "<h3 data-preset='formosa'>¡Haz un dúo con los Cracks, <span data-preset='puebla'>y gana sus reacciones!</span></h3>",
    },
    "challenges-mixin-1": {
        "en-us":
            "<h3 data-preset='puebla'>Let our MasterCracks <span data-preset='formosa'>challenge you, demonstrate your talents.</span></h3>",
        "es-mx":
            "<h3 data-preset='puebla'>Los cracks te retan, <span data-preset='formosa'>demuéstrales tus habilidades.</span></h3>",
    },
    "programs-coming-soon": {
        "en-us": "Coming Soon",
        "es-mx": "Próximamente",
    },
    "ending-title-program": {
        "en-us": "What did you think of the program?",
        "es-mx": "¿Qué te pareció el programa?",
    },
    "ending-subtitle-program": {
        "en-us":
            "Your comments are very important to us, as they will allow us to improve and create better content.",
        "es-mx":
            "Tus comentarios son muy importantes para nosotros, ya que nos permitirán mejorar y crear mejores contenidos.",
    },
    "ending-title-stars": {
        "en-us": "Rate the program",
        "es-mx": "Califica el programa",
    },
    "ending-button-program": {
        "en-us": "Send",
        "es-mx": "Enviar",
    },
    PHOTO: {
        "en-us": "PHOTO",
        "es-mx": "PHOTO",
    },
    "program-type-emom-subtitle": {
        "en-us": "EMOM Routine",
        "es-mx": "Rutina EMOM",
    },
    "program-type-emom-desc": {
        "en-us":
            "(Every Minute On The Minute) Do the amount of exercises that the module says within the indicated time, and if there is time to rest.",
        "es-mx":
            "(Every Minute On The Minute) Hacer la cantidad de ejercicios que dice el modulo dentro del tiempo indicado, y si sobra tiempo descansar.",
    },
    "program-type-tabata-subtitle": {
        "en-us": "TABATA Routine",
        "es-mx": "Rutina TABATA",
    },
    "program-type-tabata-desc": {
        "en-us":
            "Carry out 20 active seconds for each exercise and 10 seconds of rest between each one until completing the indicated time.",
        "es-mx":
            "Realizar 20 segundos activo por cada ejercicio y 10 segundos de descanso entre cada uno hasta completar el tiempo indicado.",
    },
    "program-type-task-subtitle": {
        "en-us": "TASK Routine",
        "es-mx": "Rutina TASK",
    },
    "program-type-task-desc": {
        "en-us":
            "Perform the listed moves in the shortest time possible for the number of rounds indicated.",
        "es-mx":
            "Realiza los movimientos enlistados en el menor tiempo posible por el número de rondas indicadas.",
    },
    "program-type-amrap-subtitle": {
        "en-us": "AMRAP Routine",
        "es-mx": "Rutina AMRAP",
    },
    "program-type-amrap-desc": {
        "en-us":
            "(As Many Rounds As Possible) Make all the rounds you can do of the module in the indicated time.",
        "es-mx":
            "(As Many Rounds As Possible) Realiza todas las rondas que puedas hacer del módulo en en tiempo indicado.",
    },
    "program-type-break-subtitle": {
        "en-us": "Take a break",
        "es-mx": "Toma un descanso",
    },
    "program-type-break-desc": {
        "en-us": "Take a break for the indicated amount of time.",
        "es-mx": "Toma un descanso por el timepo indicado.",
    },
    "programs-completed": {
        "en-us": "Completed",
        "es-mx": "Completados",
    },
    "program-coming-soon-1": {
        "en-us": "Power Legs",
        "es-mx": "Piernas de Acero",
    },
    "program-coming-soon-2": {
        "en-us": "Soccer Skills",
        "es-mx": "Técnica",
    },
    "program-coming-soon-3": {
        "en-us": "New Year's resolution",
        "es-mx": "Propósitos de año nuevo",
    },
    "program-coming-soon-4": {
        "en-us": "Stretching",
        "es-mx": "Estiramiento",
    },
    "program-jo-canales-14-days": {
        "en-us": "Coach Jo Canales ∙ 14 days",
        "es-mx": "Coach Jo Canales ∙ 14 días",
    },
    "program-jo-canales-8-weeks": {
        "en-us": "Coach Jo Canales ∙ 8 weeks",
        "es-mx": "Coach Jo Canales ∙ 8 semanas",
    },
    "program-jo-canales-7-days": {
        "en-us": "Coach Jo Canales ∙ 7 days",
        "es-mx": "Coach Jo Canales ∙ 7 días",
    },
    "program-jo-canales-6-days": {
        "en-us": "Coach Jo Canales ∙ 6 days",
        "es-mx": "Coach Jo Canales ∙ 6 días",
    },
    "program-jo-canales-5-days": {
        "en-us": "Coach Jo Canales ∙ 5 days",
        "es-mx": "Coach Jo Canales ∙ 5 días",
    },
    "program-mental-emocional-10-days": {
        "en-us": "Mental and emotional training ∙ 10 days",
        "es-mx": "Entrenamiento mental y emocional ∙ 10 días",
    },
    "program-mental-sleep-11-days": {
        "en-us": "Sleep ∙ 11 days",
        "es-mx": "Sueño ∙ 11 días",
    },
    "program-secret-training": {
        "en-us": "Daily training",
        "es-mx": "Entrenamiento diario",
    },
    "program-stats-secret": {
        "en-us": "Secret",
        "es-mx": "Secreto",
    },
    "program-stats-benefit": {
        "en-us": "Benefits",
        "es-mx": "Beneficios",
    },
    "programs-recommended": {
        "en-us": "Recommended",
        "es-mx": "Recomendados",
    },
    "programs-continue": {
        "en-us": "Continue program",
        "es-mx": "Continuar programa",
    },
    "programs-jo-canales-full-body-home-name": {
        "en-us": "Full body at home",
        "es-mx": "Full body en casa",
    },
    "programs-jo-canales-full-body-home-desc": {
        "en-us":
            "Train at home for 14 days, a full body program, workouts of between 30 and 60 minutes with basic material that you have at home.",
        "es-mx":
            "Entrena en casa 14 dias, un programa de full body, entrenamientos de entre 30 y 60 minutos con material básico que tienes en casa.",
    },
    "programs-jo-canales-full-body-home-equipment": {
        "en-us": "Weights or heavy object, box, chair or steps, mat (optional).",
        "es-mx": "Pesas u objeto pesado, cajón, silla o escalones, tapete (opcional).",
    },
    "programs-jo-canales-power-legs-name": {
        "en-us": "Power legs",
        "es-mx": "Piernas Poderosas",
    },
    "programs-jo-canales-power-legs-desc": {
        "en-us":
            "7-day job focused on generating strength and power in the legs aimed at soccer, you will also have technique tasks.",
        "es-mx":
            "Trabajo de 7 dias enfocado en generar fuerza y potencia en piernas dirigido al futbol, tendrás trabajos técnicos también.",
    },
    "programs-jo-canales-power-legs-equipment": {
        "en-us":
            "Soccer ball, box, weights or heavy object, mat, cones, space to run and drive ball.",
        "es-mx":
            "Balón de futbol, cajón, pesas u objeto pesado, tapete, conos, espacio para correr y conducir balón.",
    },
    "programs-jo-canales-workout-express-name": {
        "en-us": "Workout Express",
        "es-mx": "Entrenamiento Express",
    },
    "programs-jo-canales-workout-express-desc": {
        "en-us": "Trainings of between 15 and 20 minutes, intense and effective.",
        "es-mx": "Entrenamientos de entre 15 y 20 minutos, intensos y efectivos.",
    },
    "programs-jo-canales-workout-express-equipment": {
        "en-us": "Box, mat, ball or object for even push ups.",
        "es-mx": "Cajón, tapete, balón u objeto para uneven push ups.",
    },
    "programs-jo-canales-master-core-name": {
        "en-us": "Master Core",
        "es-mx": "Master Core",
    },
    "programs-jo-canales-master-core-desc": {
        "en-us": "5 days focused 100% on core work, 30 min daily work!!",
        "es-mx": "5 días enfocados 100% al trabajo de core, 30 min diarios de trabajo!!",
    },
    "programs-jo-canales-master-core-equipment": {
        "en-us": "Mat, dumbbell or heavy object or ball.",
        "es-mx": "Tapete, pesa u objeto pesado o balón.",
    },
    "programs-jo-canales-soccer-skills-name": {
        "en-us": "Soccer Skills",
        "es-mx": "Técnica",
    },
    "programs-jo-canales-soccer-skills-desc": {
        "en-us": "7-day training focused on becoming a better soccer player with the ball.",
        "es-mx": "Entrenamiento de 7 días enfocado en pulir tu técnica individual.",
    },
    "programs-jo-canales-soccer-skills-equipment": {
        "en-us": "Soccer ball, cones, soccer field.",
        "es-mx": "Balón, Conos, Cancha.",
    },
    "programs-jo-canales-full-body-name": {
        "en-us": "Full Body",
        "es-mx": "Full Body",
    },
    "programs-jo-canales-full-body-desc": {
        "en-us": "Train for 7 days focused on building strength.",
        "es-mx": "Entrena por 7 días enfocado a desarrollar fuerza en todo el cuerpo.",
    },
    "programs-jo-canales-full-body-equipment": {
        "en-us": "Dumbbelss, TRX, mat, Bar (pullups).",
        "es-mx": "Mancuernas, TRX, Tapete, Barra (pull ups).",
    },
    "programs-jo-canales-pre-season-name": {
        "en-us": "Pre-season",
        "es-mx": "Pretemporada",
    },
    "programs-jo-canales-pre-season-desc": {
        "en-us":
            "14-day training focused on improving your physical-technical capabilities of soccer.",
        "es-mx":
            "Entrenamiento de 14 días enfocado en mejorar tus capacidades físicas-técnicas del fútbol.",
    },
    "programs-jo-canales-pre-season-equipment": {
        "en-us": "Soccer ball, Dumbbells, TRX, Bar (pull ups), soccer field, cones, partner.",
        "es-mx": "Balón, Mancuernas, TRX, barra (pull ups), Cancha, conos y compañero.",
    },
    "programs-jo-canales-new-years-resolution-name": {
        "en-us": "Ney year´s resolution",
        "es-mx": "Propósito año nuevo ",
    },
    "programs-jo-canales-new-years-resolution-desc": {
        "en-us":
            "Train for 8 weeks as a national soccer player and take your game to the next level. Focusing on physical, aerobic, strength, skills and  tactical soccer development.",
        "es-mx":
            "Inicia el año de la mejor manera con este programa de acondicionamiento físico de 21 días que puedes hacer en cualquier lugar con solo un objeto pesado.",
    },
    "programs-jo-canales-new-years-resolution-equipment": {
        "en-us": "Backpack",
        "es-mx": "Mochila",
    },
    "programs-jo-canales-road-qatar-name": {
        "en-us": "Road to Qatar",
        "es-mx": "Vive Qatar",
    },
    "programs-jo-canales-road-qatar-desc": {
        "en-us":
            "Train for 8 weeks like a national team and take your game to the next level with physical, aerobic, strength, technical and tactical work.",
        "es-mx":
            "Entrena durante 8 semanas como un seleccionado y lleva tu juego al siguiente nivel con trabajos físicos, aeróbicos, de fuerza, técnicos y tácticos.",
    },
    "programs-jo-canales-road-qatar-equipment": {
        "en-us": "Soccer ball, Dumbbells, TRX, Bar (pull ups), soccer field, cones, partner.",
        "es-mx": "Balón, Mancuernas, TRX, barra (pull ups), Cancha, conos y compañero.",
    },
    "your-programs": {
        "en-us": "Programs",
        "es-mx": "Programas",
    },
    "program-completed": {
        "en-us": "Workout Completed",
        "es-mx": "Workout Completado",
    },
    "program-round-complete": {
        "en-us": "Round Completed",
        "es-mx": "Ronda Completada",
    },
    "programs-mixin": {
        "en-us":
            "<h3 data-preset='formosa'>Take part in our complete programs <span data-preset='puebla'>designed just for you</span></h3>",
        "es-mx":
            "<h3 data-preset='formosa'>Entrenamientos <span data-preset='puebla'>diseñados por cracks sólo para ti</span></h3>",
    },
    "scholarships-mixin": {
        "en-us":
            "<h3 data-preset='formosa'>With our large corporate partners we aim to our provide MasterCracks scholarships to the youth of Latin America. <span data-preset='puebla'>Want to join us?</span></h3>",
        "es-mx":
            "<h3 data-preset='formosa'>Con nuestros grandes socios corporativos, nuestro objetivo es dar becas MasterCracks a la juventud de América Latina. <span data-preset='puebla'>¿Quieres unirte?</span></h3>",
    },
    "tryit-mixin": {
        "en-us":
            "<h3 data-preset='formosa'>Up your game today, <span data-preset='puebla'>try MasterCracks now!</span></h3>",
        "es-mx":
            "<h3 data-preset='formosa'>Rómpela en la cancha y alcanza la grandeza en tu vida. <span data-preset='puebla'>¡Sé un MasterCrack!</span></h3>",
    },
    "admin-add": {
        "en-us": "Add",
        "es-mx": "Agregar",
    },
    "admin-language": {
        "en-us": "Language",
        "es-mx": "Language",
    },
    "admin-text": {
        "en-us": "Text",
        "es-mx": "Text",
    },
    "admin-config": {
        "en-us": "Configs",
        "es-mx": "Configs",
    },
    "signup-title": {
        "en-us": "Hold on, crack!",
        "es-mx": "Aguanta, crack!",
    },
    "signup-step1-info": {
        "en-us": "Looks like there's not an account associated with that email address.",
        "es-mx": "Parece que no hay ninguna cuenta asociada a ese correo electronico.",
    },
    "signup-step1-new": {
        "en-us": "Create a new account",
        "es-mx": "Crea una cuenta nueva",
    },
    "signup-step2-title": {
        "en-us": "Create an account",
        "es-mx": "Crea una cuenta",
    },
    "password-step2-info": {
        "en-us": "We sent a secret recovery code to:",
        "es-mx": "Enviamos un código de recuperacion secreto a:",
    },
    "password-step2-link": {
        "en-us": "Haven't received the code?",
        "es-mx": "¿No has recibido el código?",
    },
    "password-step1-title": {
        "en-us": "Reset password",
        "es-mx": "Restablecer contrasena",
    },
    "password-step1-info": {
        "en-us": "Submit your email address to receive your password recovery code.",
        "es-mx": "Proporciona tu correo electronico para recibir el código de recuperacion.",
    },
    "login-info-password": {
        "en-us": "Forgot your password?",
        "es-mx": "¿Olvidaste tu contrasena?",
    },
    "login-title": {
        "en-us": "Welcome back",
        "es-mx": "Bienvenido de vuelta",
    },
    "button-start-free": {
        "en-us": "KICK-OFF FOR FREE NOW",
        "es-mx": "¡EMPIEZA GRATIS!",
    },
    "cover-video": {
        "en-us": "Master your mind and body with guidance from soccer's elite players.",
        "es-mx": "Entrena mente y cuerpo con los cracks del fútbol.",
    },
    "title-categories": {
        "en-us": "Categories",
        "es-mx": "Categorías",
    },
    "categories-mindset": {
        "en-us": "Mindset",
        "es-mx": "Mindset",
    },
    "categories-mindset-preview": {
        "en-us": "Brush up on best advice and winning mentality from the Masters.",
        "es-mx": "¡El juego de la vida es mental! Ejercita tu mente con nuestros MasterCracks.",
    },
    "categories-mindset-subtitle": {
        "en-us": "Only Winning Mentality allowed",
        "es-mx": "Mentalidad de campeón",
    },
    "categories-mindset-description-1": {
        "en-us":
            "Be the captain of your own mind, become aware of your thoughts and emotions to decide clearly and act productively and assertively, and not let yourself be demotivated or act impulsively in the face of failure, stress or anxiety.",
        "es-mx":
            "Sé el capitán de tu propia mente, toma conciencia de tus pensamientos y emociones para decidir con claridad y actuar de forma productiva y asertiva, y no dejarte desmotivar o actuar de forma impulsiva ante los fracasos, el estrés o la ansiedad.",
    },
    "categories-mindset-description-2": {
        "en-us":
            "Our MasterCracks will help you put your thoughts into place when it comes to adopting a positive and healthy lifestyle. It's your turn.",
        "es-mx":
            "Consigue tus propias victorias: hacer ejercicio, ganar tu primer partido, correr un maratón, abrir el negocio de tus sueños. Ellos lo volvieron realidad. ¡Es tu turno!.",
    },
    "categories-mindset-featured-1": {
        "en-us": "Recognize the Crack inside you by becoming aware of your thoughts and emotions.",
        "es-mx":
            "Reconoce al Crack que llevas dentro tomando consciencia de tus pensamientos y emociones.",
    },
    "categories-mindset-featured-2": {
        "en-us": "Visualize your goals, put together your game plan to achieve them.",
        "es-mx": "Visualiza tus objetivos, arma tu plan de juego para alcanzarlos.",
    },
    "categories-mindset-featured-3": {
        "en-us": "Develop a growth mindset to learn from success and mistakes.",
        "es-mx":
            "Desarrolla una mentalidad de crecimiento para aprender del éxito y de los errores.",
    },
    "categories-fitness": {
        "en-us": "Fitness",
        "es-mx": "Fitness",
    },
    "categories-fitness-preview": {
        "en-us": "Boost your workouts with tips and tricks from the best.",
        "es-mx": "Entrena como un profesional con las rutinas que formaron campeones.",
    },
    "categories-fitness-subtitle": {
        "en-us": "Train to a Pro level",
        "es-mx": "Entrena como un profesional",
    },
    "categories-fitness-description-1": {
        "en-us":
            "Find all the material you need to get physically stronger in the best possible way, complementing the tactical and technical work of our cracks. We want you to be as good as your effort allows you to be on the court and we know that an important part of this is that you are physically in the best possible shape.",
        "es-mx":
            "Encuentra todo el material que necesitas para fortalecerse físicamente de la mejor manera posible complementando el trabajo táctico y técnico de nuestros cracks. Queremos que seas tan bueno como tu esfuerzo te lo permita en la cancha y sabemos que parte importante de esto es que físicamente estés en la mejor forma posible.",
    },
    "categories-fitness-description-2": {
        "en-us": "If you want training that is not 100% soccer, we also have programs for you.",
        "es-mx":
            "Si quieres entrenamientos que no son 100% futbolísticos, también tenemos programas para ti.",
    },
    "categories-fitness-featured-1": {
        "en-us": "Develop the best physical shape possible to be a Mastercrack on the court.",
        "es-mx": "Desarrolla la mejor forma física posible para ser un Mastercrack en la cancha.",
    },
    "categories-fitness-featured-2": {
        "en-us":
            "Take an effective and well-structured path to achieve the physical appearance you always wanted.",
        "es-mx":
            "Realiza camino efectivo y bien estructurado para lograr el aspecto físico que siempre quisiste.",
    },
    "categories-fitness-featured-3": {
        "en-us":
            "Get in the best physical shape possible to perform any activity you set out to do.",
        "es-mx":
            "Obtén la mejor forma física posible para desempeñar cualquier actividad que te propongas.",
    },
    "categories-stories": {
        "en-us": "Stories",
        "es-mx": "Stories",
    },
    "categories-stories-preview": {
        "en-us":
            "Be in the front line of powerful stories, get inspired, get energized and be influenced.",
        "es-mx": "Inspírate en las palabras de tus ídolos para triunfar en la cancha y en la vida.",
    },
    "categories-stories-subtitle": {
        "en-us": "Aspire to Inspire",
        "es-mx": "Inspírate en tus ídolos",
    },
    "categories-stories-description-1": {
        "en-us":
            "They conquered titles, they ended up as idols but they also suffered Bullying, they dealt with the duel, they suffered failures and all their secrets will be shared with you.",
        "es-mx":
            "Conquistaron títulos, se convirtieron en ídolos pero también sufrieron Bullying, lidiaron con el duelo sufrieron fracasos y todos sus secretos los compartirán contigo.",
    },
    "categories-stories-description-2": {
        "en-us":
            "Let our MastersCracks out their emotions with these unique and passionate stories.",
        "es-mx":
            "Deja que nuestros cracks te inspiren con su lado humano. Identifícate, libera a tu crack interno y alcanza la grandeza en cualquier parte de tu vida.",
    },
    "categories-stories-featured-1": {
        "en-us": "Get to know the human side of your Cracks.",
        "es-mx": "Conoce el lado humano de tus Cracks.",
    },
    "categories-stories-featured-2": {
        "en-us": "Get inspired by their stories to be the best version of yourself.",
        "es-mx": "Inspírate con sus historias para ser tu mejor versión.",
    },
    "categories-stories-featured-3": {
        "en-us": "Their stories could become your success story, find your purpose in them.",
        "es-mx":
            "Sus historias podrían convertirse en tu historia de éxito, encuentra tu propósito en ellas.",
    },
    "categories-skills": {
        "en-us": "Skills",
        "es-mx": "Skills",
    },
    "categories-skills-preview": {
        "en-us": "Upgrade your abilities and show them off.",
        "es-mx": "Un dardo envenenado, un gol a lo Panenka... ¡Aquí dominarás el juego!.",
    },
    "categories-skills-subtitle": {
        "en-us": "Discover the best version of yourself.",
        "es-mx": "Descubre la mejor versión de ti mismo.",
    },
    "categories-skills-description-1": {
        "en-us":
            "Are you ready to perform skills that seem impossible? Throwing poison darts, putting up a rabona pass or scoring a penalty as a boss will be part of your new repertoire of skills.",
        "es-mx":
            "¿Estás listo para realizar skills que parecen imposibles? Lanzar dardos envenenados, poner un pase de rabona o marcar un penal como jefe, serán parte de tu nuevo repertorio de habilidades.",
    },
    "categories-skills-description-2": {
        "en-us":
            "Access the training sessions that turned your idols into cracks and put your skills to the test with us to be the hero that gives your team the victory at 90'.",
        "es-mx":
            "Accede a los entrenamientos que convirtieron a tus ídolos en cracks y pon a prueba tus habilidades con nosotros para ser el héroe que le dé el triunfo a tu equipo al 90'.",
    },
    "categories-skills-featured-1": {
        "en-us": "Execute the plays that made your cracks legendary.",
        "es-mx": "Ejecuta las jugadas que hicieron legendarios a tus cracks.",
    },
    "categories-skills-featured-2": {
        "en-us": "Develop new skills for matches.",
        "es-mx": "Desarrolla nuevas habilidades para los partidos.",
    },
    "categories-skills-featured-3": {
        "en-us": "Train like the Cracks and put yourself to the test.",
        "es-mx": "Entrena como los Cracks y ponte a prueba.",
    },
    "title-challenges": {
        "en-us": "Challenges",
        "es-mx": "Challenges",
    },
    "challenges-subtitle": {
        "en-us": "The Cracks challenge you, show them your skills",
        "es-mx": "Los Cracks te retan, demuéstrales tus habilidades",
    },
    "challenges-preview": {
        "en-us":
            "Join us for our challenges, bepart of our social content and relate with  your idols.",
        "es-mx":
            "Únete a nuestros challenges, sé parte de la comunidad de generadores de contenido y gana la oportunidad de recibir consejos e interactuar con tus ídolos.",
    },
    "challenges-description-1": {
        "en-us":
            "Are you ready to show your skills to our cracks? Your time has come! Accept their challenges.",
        "es-mx":
            "¿Estás listo para demostrarle tus habilidades a nuestros cracks?, ¡llegó tu momento!, acepta sus retos.",
    },
    "challenges-description-2": {
        "en-us":
            "Dominated, hits with styles, tricks and much more, is what your soccer idols have prepared for you.",
        "es-mx":
            "Dominadas, golpes con estilos, trucos y mucho más, es lo que tus idolos del fútbol han preparado para tí.",
    },
    "challenges-description-3": {
        "en-us": "Accept the challenge and get the feedback of our cracks.",
        "es-mx": "Acepta el reto y consigue el consejo de nuestros cracks.",
    },
    "challenges-featured-1": {
        "en-us": "Observe the challenge, practice it and share it.",
        "es-mx": "Observa el reto, practicalo y compártelo.",
    },
    "challenges-featured-2": {
        "en-us": "Generate new skills and demonstrate them on the pitch.",
        "es-mx": "Genera nuevas habilidades y demuestralas en la cancha.",
    },
    "challenges-featured-3": {
        "en-us": "Improve your movements and become a crack.",
        "es-mx": "Mejora tus movimientos y conviértete en un crack.",
    },
    "challenges-medals": {
        "en-us": "Duet with the stars and earn their reactions!",
        "es-mx": "¡Haz un dúo con los Cracks y gana sus reacciones!",
    },
    "button-accept": {
        "en-us": "Challenge Accepted",
        "es-mx": "¡Acepto el reto!",
    },
    "programs-subtitle": {
        "en-us": "Designed by our Cracks especially for you.",
        "es-mx": "Diseñados por nuestros cracks especialmente para ti.",
    },
    "programs-preview": {
        "en-us":
            "Every program is developed to elevate your mindset, optimize your fitness agility and crush your soccer skills. We will get you on track to become a MasterCrack!.",
        "es-mx":
            "Entrena tu mente y cuerpo a nivel Rafa Márquez en el Barça o Pavel en el Stuttgart. Desafíate con programas creados por tus cracks especialmente para ti ¿Te atreves?.",
    },
    "programs-description": {
        "en-us":
            "You are in the right place with us. MasterCracks has specific programs to elevate your physical and mental abilities. Step up your game!.",
        "es-mx":
            "Descubre tu grandeza a través de las historias de tus grandes ídolos del fútbol. trabaja en tu salud mental y física con los grandes consejos de los coaches y cracks, deslumbra a todos en la cancha presumiendoles tus nuevas habilidades.",
    },
    "programs-featured-1": {
        "en-us": "Develops physical, emotional and social skills.",
        "es-mx": "Desarrolla habilidades físicas, emocionales y sociales.",
    },
    "programs-featured-2": {
        "en-us": "Be the best version of yourself every day by following the game plans.",
        "es-mx": "Sé tu mejor versión todos los días al seguir los planes de juego.",
    },
    "programs-featured-3": {
        "en-us": "Generate strategies and overcome the obstacles that come your way.",
        "es-mx": "Genera estrategias y vence los obstáculos que se te presentan.",
    },
    "programs-medals": {
        "en-us": "Complete the programs and show off all your medals!",
        "es-mx": "¡Completa los programas y presume todas tus medallas!",
    },
    "button-ready": {
        "en-us": "Game On",
        "es-mx": "¡Estoy listo!",
    },
    "title-reviews": {
        "en-us": "Testimonials",
        "es-mx": "Testimonios",
    },
    "title-submit": {
        "en-us": "Submit a Review",
        "es-mx": "Enviar una Reseña",
    },
    "title-favorites": {
        "en-us": "Favorites",
        "es-mx": "Favoritos",
    },
    "title-community": {
        "en-us": "Community",
        "es-mx": "Comunidad",
    },
    "title-secrets": {
        "en-us": "Secrets",
        "es-mx": "Secretos",
    },
    "title-home": {
        "en-us": "Home",
        "es-mx": "Inicio",
    },
    "title-programs": {
        "en-us": "Programs",
        "es-mx": "Programas",
    },
    "button-reviews": {
        "en-us": "View All",
        "es-mx": "Ver Más",
    },
    "button-submit": {
        "en-us": "Submit a Review",
        "es-mx": "Enviar una Reseña",
    },
    "title-scholarships": {
        "en-us": "BeCrack",
        "es-mx": "Becas",
    },
    "scholarships-preview": {
        "en-us":
            "Along with our large corporate partners We aim, we shoot, we score and no crystal sky detain us. The world needs more soccer. Get to know our MasterCracks Scholarships. Are you ready to join?",
        "es-mx":
            "Lanzamos el programa de becas MasterCracks, en equipo con aliados corporativos, para que ningún joven se quede sin la oportunidad de convertirse en la mejor versión de sí mismo.",
    },
    "button-join": {
        "en-us": "Join the team!",
        "es-mx": "¡Súmate al equipo!",
    },
    separator: {
        "en-us": "OR",
        "es-mx": "O",
    },
    "price-description": {
        "en-us": "Starting at $12.99/month",
        "es-mx": "A partir de $99/al mes",
    },
    copyright: {
        "en-us": "© Copyright MasterCracks",
        "es-mx": "© Copyright MasterCracks",
    },
    "select-language": {
        "en-us": "Select Language",
        "es-mx": "Selecciona el Idioma",
    },
    "language-choice-es": {
        "en-us": "ES",
        "es-mx": "ES",
    },
    "language-choice-en": {
        "en-us": "EN",
        "es-mx": "EN",
    },
    "options-privacy": {
        "en-us": "Privacy Policy",
        "es-mx": "Política de Privacidad",
    },
    "options-terms": {
        "en-us": "Terms of Use",
        "es-mx": "Términos de Uso",
    },
    "options-started": {
        "en-us": "Get Started",
        "es-mx": "Empezar",
    },
    "options-blog": {
        "en-us": "Blog",
        "es-mx": "Blog",
    },
    "options-about": {
        "en-us": "About",
        "es-mx": "Acerca de",
    },
    "options-help": {
        "en-us": "Help & Support",
        "es-mx": "Ayuda",
    },
    "options-language": {
        "en-us": "Language",
        "es-mx": "Idioma",
    },
    "options-faq": {
        "en-us": "FAQ",
        "es-mx": "Preguntas Frecuentes",
    },
    "options-contact-us": {
        "en-us": "Contact us",
        "es-mx": "Contáctanos",
    },
    "options-guidelines": {
        "en-us": "User Guidelines",
        "es-mx": "Guías de Usuario",
    },
    "options-subscription": {
        "en-us": "Payment & Subscription",
        "es-mx": "Métodos de Pago",
    },
    "about-description": {
        "en-us":
            "MasterCracks is an edu-tech platform that serves as a transformation journey to elevate mental and physical skills. Indulge and learn from world-known athletes to reinforce your sports knowledge.",
        "es-mx":
            "Nuestra misión es optimizar tus habilidades emocionales, sociales y físicas a través de lecciones de tus ídolos del fútbol para lograr tu máximo potencial en la cancha y en tu vida.",
    },
    "button-share": {
        "en-us": "Share",
        "es-mx": "Compartir",
    },
    "subtitle-crack-bio": {
        "en-us": "Player bio",
        "es-mx": "Biografía",
    },
    "subtitle-crack-stats": {
        "en-us": "Quick Stats",
        "es-mx": "Estadísticas Rápidas",
    },
    "subtitle-coaches-board": {
        "en-us": "Coach's Board",
        "es-mx": "La pizarra del DT",
    },
    "subtitle-what-need": {
        "en-us": "What I Need?",
        "es-mx": "¿Qué Necesito?",
    },
    "subtitle-mental-gym": {
        "en-us": "Mental Gym",
        "es-mx": "Entrenamiento Mental",
    },
    "crack-stats-name-1": {
        "en-us": "25 Championships",
        "es-mx": "25 Campeonatos",
    },
    "crack-stats-name-2": {
        "en-us": "Lorem Ipsum",
        "es-mx": "Lorem Ipsum",
    },
    "crack-stats-name-3": {
        "en-us": "Lorem Ipsum",
        "es-mx": "Lorem Ipsum",
    },
    "subtitle-other-categories": {
        "en-us": "Other categories",
        "es-mx": "Otras categorias",
    },
    "subtitle-other-players": {
        "en-us": "Other players",
        "es-mx": "Otros jugadores",
    },
    "subtitle-our-players": {
        "en-us": "Our Cracks",
        "es-mx": "Nuestros Cracks",
    },
    "subtitle-view-challenges": {
        "en-us": "View challenges",
        "es-mx": "Ver challenges",
    },
    "subtitle-view-programs": {
        "en-us": "View programs",
        "es-mx": "Ver programas",
    },
    "subtitle-sumary": {
        "en-us": "Sumary",
        "es-mx": "Resumen",
    },
    "subtitle-results": {
        "en-us": "Results",
        "es-mx": "Resultados",
    },
    "subtitle-objective": {
        "en-us": "Grat for",
        "es-mx": "Ideal para",
    },
    "subtitle-commitment": {
        "en-us": "Your commitment",
        "es-mx": "Tu compromiso",
    },
    "subtitle-program-preview": {
        "en-us": "Program preview",
        "es-mx": "Vista previa del programa",
    },
    "subtitle-compatibility": {
        "en-us": "Compatibility",
        "es-mx": "Compatibilidad",
    },
    "compatibility-tablet": {
        "en-us": "Tablet",
        "es-mx": "Tablet",
    },
    "compatibility-smartphone": {
        "en-us": "Smartphone",
        "es-mx": "Smartphone",
    },
    "compatibility-desktop": {
        "en-us": "Desktop",
        "es-mx": "Desktop",
    },
    "compatibility-tv": {
        "en-us": "T.V.",
        "es-mx": "T.V.",
    },
    "location-field": {
        "en-us": "Soccer Field",
        "es-mx": "Campo de Fútbol",
    },
    "location-court": {
        "en-us": "Street Court",
        "es-mx": "Cancha Callejera",
    },
    "location-home": {
        "en-us": "At Home",
        "es-mx": "En Casa",
    },
    "title-beta-launch": {
        "en-us": "Join us for our Beta launch",
        "es-mx": "¡Únete a MasterCracks!",
    },
    "popover-whatsapp-chat": {
        "en-us": "Chat with Pavelbot on WhatsApp",
        "es-mx": "Chatea con Pavelbot en WhatsApp",
    },
    "whatsapp-chat": {
        "en-us": "WhatsApp Chat",
        "es-mx": "WhatsApp Chat",
    },
    "options-chat": {
        "en-us": "PavelBot",
        "es-mx": "PavelBot",
    },
    "title-chat-pavelbot": {
        "es-mx": "¡Unete primero a nuestro newsletter!",
        "en-us": "Join our newsletter first!",
    },
    "description-chat-pavelbot": {
        "en-us": "Chat with PavelBot and get tips, promotions and news from MasterCracks.",
        "es-mx":
            "Recibe tips, promociones y noticias de MasterCracks. Al unirte podrás chatear con PavelBot.",
    },
    "beta-launch-description-terms-1": {
        "en-us": "With the account creation you are agree with our",
        "es-mx": "Al crear tu cuenta aceptas nuestros",
    },
    "beta-launch-description-terms-2": {
        "en-us": "Terms and Conditions",
        "es-mx": "Términos y Condiciones",
    },
    "beta-launch-description-terms-3": {
        "en-us": "and",
        "es-mx": "y",
    },
    "beta-launch-description-terms-4": {
        "en-us": "Privacy Policy",
        "es-mx": "Política de Privacidad",
    },
    "beta-launch-birthday": {
        "en-us": "What is your birth date?",
        "es-mx": "¿Cuál es tu fecha de nacimiento?",
    },
    "beta-launch-birthday-description": {
        "en-us": "Please select your date of birth.",
        "es-mx": "Por favor selecciona tu fecha de nacimiento.",
    },
    "beta-launch-gender": {
        "en-us": "What is your gender?",
        "es-mx": "¿Cuál es tu género?",
    },
    "input-gender-placeholder": {
        "en-us": "Choose an option",
        "es-mx": "Elige una opción",
    },
    "beta-launch-gender-option-male": {
        "en-us": "Male",
        "es-mx": "Masculino",
    },
    "beta-launch-gender-option-female": {
        "en-us": "Female",
        "es-mx": "Femenino",
    },
    "beta-launch-gender-option-other": {
        "en-us": "Other",
        "es-mx": "Otro",
    },
    "beta-launch-gender-option-unspecified": {
        "en-us": "I prefer not to say",
        "es-mx": "Prefiero no decir",
    },
    "beta-launch-description": {
        "en-us": "To our friends and family: join us through the process before shooting high.",
        "es-mx": "Sé parte, antes que nadie, de la nueva generación de líderes.",
    },
    "beta-launch-email": {
        "en-us": "Check your email inbox and follow the instructions.",
        "es-mx": "Revisa la bandeja de entrada de tu email y sigue las instrucciones.",
    },
    "beta-launch-code": {
        "en-us": "I have an invitation code",
        "es-mx": "Tengo un código de invitación",
    },
    "beta-launch-login": {
        "en-us": "Enter your email",
        "es-mx": "Ingresa tu correo electrónico",
    },
    "beta-launch-login-description": {
        "en-us":
            "Sign up now and try FREE for 7 days the short and simple exercises that will allow you to be inspired, motivated and acquire practical tools to improve your life in fundamental aspects such as self-esteem, nutrition, physical condition, resilience or sleep, so that your passion for soccer is the engine of your transformation.",
        "es-mx":
            "Inscríbete ahora y prueba GRATIS por 7 días los breves y simples ejercicios que te permitirán inspirarte, motivarte y adquirir herramientas prácticas para mejorar tu vida en aspectos fundamentales como autoestima, nutrición, condición física, resiliencia o sueño, para que tu pasión por el fútbol sea el motor de tu transformación.",
    },
    "beta-launch-active": {
        "en-us": "Activate my account",
        "es-mx": "Activar mi cuenta",
    },
    "beta-launch-title-active": {
        "en-us": "Activate account",
        "es-mx": "Activar cuenta",
    },
    "beta-launch-title-password": {
        "en-us": "Enter your password",
        "es-mx": "Ingresa tu contraseña",
    },
    "beta-launch-title-password-description": {
        "en-us": "Enter the password you previously created.",
        "es-mx": "Ingresa la contraseña que creaste previamente.",
    },
    "beta-launch-no-account": {
        "en-us": "I don't have an account",
        "es-mx": "No tengo cuenta",
    },
    "beta-launch-password": {
        "en-us": "Create Password",
        "es-mx": "Crear contraseña",
    },
    "beta-launch-password-description": {
        "en-us": "Your password must have a minimum of 6 characters, use only letters and numbers.",
        "es-mx": "Tu contraseña debe de tener mínimo 6 caracteres, usar solo letras y números.",
    },
    "beta-launch-phone": {
        "en-us": "Enter your phone number",
        "es-mx": "Ingresa tu número telefónico",
    },
    "beta-launch-phone-description": {
        "en-us": "Enter your mobile phone number.",
        "es-mx": "Por favor introduce tu número de celular a 10 dígitos.",
    },
    "beta-launch-avatar": {
        "en-us": "Choose an Avatar",
        "es-mx": "Elige un avatar",
    },
    "beta-launch-timer": {
        "en-us": "We'll automatically redirect you Home in: %",
        "es-mx": "Te llevaremos automáticamente al inicio en: %",
    },
    "beta-launch-thanks": {
        "en-us": "Thank You",
        "es-mx": "¡Gracias, Crack!",
    },
    "beta-launch-confirm": {
        "en-us": "E-mail confirmed",
        "es-mx": "Correo confirmado",
    },
    "beta-launch-confirm-label": {
        "en-us":
            "<p>Thank you for confirming your email!</p><p>You can now Download the App following the links below:</p>",
        "es-mx":
            "<p>Gracias por confirmar tu direccion de email</p><p>Descarga la App con los links de abajo e ingresa con tu correo electrónico</p>",
    },
    "beta-launch-thanks-description": {
        "en-us": "Personal link is available through email.",
        "es-mx":
            "Revisa tu correo electrónico y encuentra el enlace de acceso para unirte a nuestra comunidad y empezar a disfrutar de MasterCracks.",
    },
    "input-name": {
        "en-us": "Name",
        "es-mx": "Nombre",
    },
    "input-lastname": {
        "en-us": "Last Name",
        "es-mx": "Apellido",
    },
    "input-fullname": {
        "en-us": "Full Name",
        "es-mx": "Nombre Completo",
    },
    "input-email": {
        "en-us": "Email Address",
        "es-mx": "Correo Electrónico",
    },
    "input-os": {
        "en-us": "Operating System",
        "es-mx": "Sistema Operativo",
    },
    "input-os-description": {
        "en-us": "Choose an operating system to receive the proper download instructions.",
        "es-mx": "Elige un sistema operativo para recibir las instrucciones adecuadas de descarga.",
    },
    "input-password": {
        "en-us": "Password",
        "es-mx": "Contraseña",
    },
    "input-password-confirm": {
        "en-us": "Confirm password",
        "es-mx": "Confirma tu contraseña",
    },
    "input-ios": {
        "en-us": "iOS",
        "es-mx": "iOS",
    },
    "input-android": {
        "en-us": "Android",
        "es-mx": "Android",
    },
    "input-code": {
        "en-us": "Invitation Code",
        "es-mx": "Código de Invitación",
    },
    "input-location": {
        "en-us": "Location",
        "es-mx": "Ubicación",
    },
    "input-birth": {
        "en-us": "Birth Date",
        "es-mx": "Fecha de Nacimiento",
    },
    "input-review": {
        "en-us": "Write Review",
        "es-mx": "Escribe una Reseña",
    },
    "input-day-placeholder": {
        "en-us": "DD",
        "es-mx": "DD",
    },
    "input-month-placeholder": {
        "en-us": "MM",
        "es-mx": "MM",
    },
    "input-year-placeholder": {
        "en-us": "YYYY",
        "es-mx": "YYYY",
    },
    "input-rating": {
        "en-us": "Overall Rating",
        "es-mx": "Calificación General",
    },
    "error-generic": {
        "en-us": "Error.",
        "es-mx": "Error.",
    },
    "error-generic-long": {
        "en-us": "We encountered an error. Please, try again.",
        "es-mx": "Encontramos un error, Por favor, intenta de nuevo.",
    },
    "input-phone-number": {
        "en-us": "55 5555 5555",
        "es-mx": "55 5555 5555",
    },
    "input-phone-code": {
        "en-us": "Select country code",
        "es-mx": "Código del país",
    },
    "input-affiliation": {
        "en-us": "Affiliation",
        "es-mx": "Afiliación",
    },
    "error-required": {
        "en-us": "Required field.",
        "es-mx": "Campo obligatorio.",
    },
    "error-min-three-characters": {
        "en-us": "Ingresa al menos 3 caracteres.",
        "es-mx": "Enter at least 3 characters.",
    },
    "error-min-sixteen-characters": {
        "en-us": "error-min-sixteen-characters",
        "es-mx": "error-min-sixteen-characters",
    },
    "error-email": {
        "en-us": "Invalid email address.",
        "es-mx": "Correo electrónico no válido.",
    },
    "error-passwords-match": {
        "en-us": "Passwords do not match",
        "es-mx": "Las contraseñas no coinciden",
    },
    "error-passwords-minimum": {
        "en-us": "Password must be at least 6 characters.",
        "es-mx": "La contraseña debe tener al menos 6 caracteres.",
    },
    "error-code-invitation": {
        "en-us": "The invitation code doesn't match with our records.",
        "es-mx": "El código de invitación no coincide con nuestros registros.",
    },
    "error-password": {
        "en-us": "Invalid password.",
        "es-mx": "Contraseña inválida.",
    },
    "error-phone-number": {
        "en-us": "Invalid phone number",
        "es-mx": "Número telefónico no válido",
    },
    "error-contact-us": {
        "en-us": "Try again later.",
        "es-mx": "Intenta nuevamente.",
    },
    "button-enroll": {
        "en-us": "Join now",
        "es-mx": "Únete ahora",
    },
    "button-continue": {
        "en-us": "Continue",
        "es-mx": "Continuar",
    },
    "welcome-title-1": {
        "en-us": "Welcome Crack",
        "es-mx": "Bienvenido, Crack",
    },
    "welcome-description-1": {
        "en-us": "Here begins your training to become a MasterCrack.",
        "es-mx": "Aquí comienza tu entrenamiento para convertirte en un MasterCrack.",
    },
    "welcome-title-2": {
        "en-us": "Lesson Plan",
        "es-mx": "Clases de Primera",
    },
    "welcome-description-2": {
        "en-us":
            "Are you ready to sweat it off? Access our complete and curated lessons, any day, any time, anywhere. Elevate your game, here is how.",
        "es-mx":
            "Desarrolla tu mente y cuerpo con las mejores clases de Fitness, Mindset, Skills y Stories. Lleva tu pasión por el fútbol a otro nivel de la mano de tus ídolos.",
    },
    "welcome-title-3": {
        "en-us": "It's challenge time",
        "es-mx": "Desafía tus habilidades",
    },
    "welcome-description-3": {
        "en-us":
            "Get involved and discover  new experiences, get to know what we have to offer. Get feedback and reactions from our MasterCracks.",
        "es-mx":
            "Muestra de qué estás hecho en los challenges que te pondrán tus ídolos del fútbol. ¡Grábate, comparte y viralizate!",
    },
    "welcome-title-4": {
        "en-us": "Expert Programs",
        "es-mx": "Programas de los expertos",
    },
    "welcome-description-4": {
        "en-us":
            "MasterCracks has special and unique programs you can choose from to complement your transformation journey. Achieve greatness, master the results.",
        "es-mx":
            "Accede a los mejores planes de entrenamiento físicos y mentales desarrollados por nuestros MasterCracks para sacar lo mejor de ti.",
    },
    "welcome-title-5": {
        "en-us": "Top MasterCracks",
        "es-mx": "Nuestros MasterCracks",
    },
    "welcome-description-5": {
        "en-us":
            "Teamback, crack! Delight on the most successful stories there are in the world of soccer.You will learn from the best, no doubt.",
        "es-mx":
            "Conquistaron títulos, se convirtieron en ídolos del fútbol, encontraron el camino al éxito y todos sus secretos los compartirán contigo.",
    },
    "welcome-skip-tutorial": {
        "en-us": "Skip",
        "es-mx": "Omitir",
    },
    "hello-user": {
        "en-us": "Hello, %",
        "es-mx": "Hola, %",
    },
    "hello-user-phrase": {
        "en-us": "Let's Get Started",
        "es-mx": "¡Empecemos!",
    },
    "button-see": {
        "en-us": "See all",
        "es-mx": "Ver todos",
    },
    "button-show": {
        "en-us": "Show more",
        "es-mx": "Ver más",
    },
    "button-show-how": {
        "en-us": "Show me how",
        "es-mx": "Muéstrame cómo",
    },
    "button-letsgo": {
        "en-us": "Let's go!",
        "es-mx": "¡Vamos!",
    },
    "button-win": {
        "en-us": "For the win!",
        "es-mx": "¡Por la Victoria!",
    },
    "button-start": {
        "en-us": "Let's get started!",
        "es-mx": "¡Empezar!",
    },
    "button-finish": {
        "en-us": "Finish",
        "es-mx": "Finalizar",
    },
    "subtitle-recommended": {
        "en-us": "Recommended for you",
        "es-mx": "Recomendados para ti",
    },
    "subtitle-trending": {
        "en-us": "Trending content",
        "es-mx": "Contenido en tendencia",
    },
    "subtitle-featured-challenges": {
        "en-us": "Featured challenges",
        "es-mx": "Challenges destacados",
    },
    "subtitle-sugested-programs": {
        "en-us": "Suggested programs",
        "es-mx": "Programas sugeridos",
    },

    "leave-title-program": {
        "en-us": "Why did you leave the training?",
        "es-mx": "¿Por que  abandonas el entrenamiento?",
    },

    "leave-subtitle-program": {
        "en-us": "Knowing the reason will help us improve your experience",
        "es-mx": "Conocer el motivo nos ayudar a mejorar tu experiencia",
    },
    "leave-warning-program": {
        "en-us": "If you leave the training, the progress of the program will not be saved",
        "es-mx": "Si abandonas el entrenamiento no se guardará el progreso del programa",
    },
    "leave-button-program": {
        "en-us": "Leave Program",
        "es-mx": "Abandonar Programa",
    },

    "option-easy-program": {
        "en-us": "it was too easy",
        "es-mx": "Era demasiado facil",
    },
    "option-hard-program": {
        "en-us": "I have no time",
        "es-mx": "Era muy dificil",
    },
    "option-time-program": {
        "en-us": "Suggested programs",
        "es-mx": "No tengo tiempo",
    },
    "option-dontLike-program": {
        "en-us": "I didn't like it",
        "es-mx": "No me ha gustado",
    },
    "option-other-program": {
        "en-us": "Other motives",
        "es-mx": "Otros motivos",
    },

    "subtitle-achievements": {
        "en-us": "Achievements",
        "es-mx": "Logros",
    },
    "subtitle-coming-soon": {
        "en-us": "Comming soon",
        "es-mx": "Muy pronto",
    },
    "subtitle-recently": {
        "en-us": "Recently added",
        "es-mx": "Añadidos recientemente",
    },
    "subtitle-next": {
        "en-us": "Watch next",
        "es-mx": "Ver siguientes",
    },
    "subtitle-more-stories": {
        "en-us": "More stories",
        "es-mx": "Mas stories",
    },
    "subtitle-hits": {
        "en-us": "Crack's gratest hits",
        "es-mx": "Sus grandes éxitos",
    },
    "subtitle-content": {
        "en-us": "%'s content",
        "es-mx": "Contenido de %",
    },
    "subtitle-classics": {
        "en-us": "Crack's classics",
        "es-mx": "Clásicos del crack",
    },
    "subtitle-Lessons": {
        "en-us": "Lessons",
        "es-mx": "Lecciones",
    },
    "subtitle-about": {
        "en-us": "About MasterCracks",
        "es-mx": "Acerca de MasterCracks",
    },
    "filter-all": {
        "en-us": "All",
        "es-mx": "Todos",
    },
    "crack-position-1": {
        "en-us": "Forward",
        "es-mx": "Delantero",
    },
    "crack-position-2": {
        "en-us": "Defender",
        "es-mx": "Defensor",
    },
    "crack-position-3": {
        "en-us": "Goalkeeper",
        "es-mx": "Portero",
    },
    "crack-position-4": {
        "en-us": "Midfielder",
        "es-mx": "Medio",
    },
    "crack-position-5": {
        "en-us": "Center",
        "es-mx": "Centro",
    },
    connector: {
        "en-us": "with %",
        "es-mx": "con %",
    },
    "subtitle-challenge": {
        "en-us": "Challenge",
        "es-mx": "Challenge",
    },
    "subtitle-fitness": {
        "en-us": "Fitness",
        "es-mx": "Fitness",
    },
    "subtitle-fitness-desc": {
        "en-us": "with Jo Canales, Buffon Academy, and more.",
        "es-mx": "con Jo Canales, Buffon Academy y más.",
    },
    "subtitle-skills": {
        "en-us": "Skills",
        "es-mx": "Skills",
    },
    "subtitle-stories": {
        "en-us": "Stories",
        "es-mx": "Stories",
    },
    "subtitle-mindset": {
        "en-us": "Mindset",
        "es-mx": "Mindset",
    },
    "subtitle-stamina": {
        "en-us": "Stamina",
        "es-mx": "Stamina",
    },
    "test-lorem-short": {
        "en-us": "Lorem ipsum",
        "es-mx": "Lorem ipsum",
    },
    "test-lorem-ipsum": {
        "en-us": "Lorem ipsum dolor sit amet, consectetur adipiscing elit proin fermentum.",
        "es-mx": "Lorem ipsum dolor sit amet, consectetur adipiscing elit proin fermentum.",
    },
    "test-lorem-long": {
        "en-us":
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum mauris et nunc venenatis, sed dictum orci blandit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
        "es-mx":
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum mauris et nunc venenatis, sed dictum orci blandit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
    },
    "coming-soon": {
        "en-us": "Coming Soon",
        "es-mx": "Muy Pronto",
    },
    "wait-for": {
        "en-us": "Wait for it",
        "es-mx": "Espéralo",
    },
    "test-video-name-1": {
        "en-us": "Double Dribbles",
        "es-mx": "Regate doble",
    },
    "test-video-name-2": {
        "en-us": "Kick Ball Change",
        "es-mx": "Cambio de Kick Ball",
    },
    "test-video-name-3": {
        "en-us": "My Journey to Europe",
        "es-mx": "Mi camino hacia Europa",
    },
    "test-video-preview-1": {
        "en-us": "Master the game, dominate the field",
        "es-mx": "Domina el juego, domina el campo",
    },
    "test-video-preview-2": {
        "en-us": "Master the game, dominate the field",
        "es-mx": "Domina el juego, domina el campo",
    },
    "test-video-duration": {
        "en-us": "13 min",
        "es-mx": "13 min",
    },
    "test-crack-name-1": {
        "en-us": "Norma Palafox",
        "es-mx": "Norma Palafox",
    },
    "test-crack-name-2": {
        "en-us": "Pavel Pardo",
        "es-mx": "Pável Pardo",
    },
    "test-crack-name-3": {
        "en-us": "Coach Jo Canales",
        "es-mx": "Coach Jo Canales",
    },
    "test-crack-name-4": {
        "en-us": "Rafael Marquez",
        "es-mx": "Rafael Márquez",
    },
    "test-crack-name-5": {
        "en-us": "Carlos Salcido",
        "es-mx": "Carlos Salcido",
    },
    "test-crack-name-6": {
        "en-us": "Charlyn Corral",
        "es-mx": "Charlyn Corral",
    },
    "test-crack-name-7": {
        "en-us": "Jared Borgetti",
        "es-mx": "Jared Borgetti",
    },
    "test-crack-name-8": {
        "en-us": "Oswaldo Sanchez",
        "es-mx": "Oswaldo Sanchez",
    },
    "test-crack-name-9": {
        "en-us": "Buffon Academy",
        "es-mx": "Buffon Academy",
    },
    "test-crack-bio": {
        "en-us":
            "His winning mentality led him to be the first Mexican to win the Bundesliga, play two World Cups and more than 430 games at the professional level. He earned the respect of the Germans who called him El Comandante",
        "es-mx":
            "Su mentalidad ganadora lo guió a ser el primer mexicano en conquistar la Bundesliga, jugar dos mundiales y más de 430 partidos a nivel profesional. Se ganó el respeto de los alemanes que lo llamaron El Comandante",
    },
    "test-crack-nationality": {
        "en-us": "Mexico",
        "es-mx": "México",
    },
    "test-crack-nickname": {
        "en-us": '"The Comandant"',
        "es-mx": '"El Comandante"',
    },
    "test-review-title": {
        "en-us": "I loved Pavel's videos!",
        "es-mx": "¡Me encantaron los videos de Pável!",
    },
    "test-review-data": {
        "en-us": "Lapeer, Michigan, 27 years",
        "es-mx": "Lapeer, Michigan, 27 años",
    },
    "test-review-user": {
        "en-us": "Angela Roman",
        "es-mx": "Ángela Román",
    },
    "test-review-comment": {
        "en-us":
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur bibendum lacinia ante. Curabitur aliquam lectus nec mi ultrices luctus.",
        "es-mx":
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur bibendum lacinia ante. Curabitur aliquam lectus nec mi ultrices luctus.",
    },
    "review-user-1": {
        "en-us": "Jorge M",
        "es-mx": "Jorge M",
    },
    "review-title-1": {
        "en-us": "MasterCracks brought out my best version",
        "es-mx": "MasterCracks sacó mi mejor versión",
    },
    "review-comment-1": {
        "en-us":
            "MasterCracks has changed my way of seeing and learning about soccer, without a doubt they have been a great guide to become a better player and person.",
        "es-mx":
            "MasterCracks ha cambiado mi manera de ver y aprender sobre el fútbol, sin lugar a duda han sido una gran guía para volverme un mejor jugador y persona.",
    },
    "review-user-2": {
        "en-us": "Alejandro G",
        "es-mx": "Alejandro G",
    },
    "review-title-2": {
        "en-us": "I learn more about myself",
        "es-mx": "He aprendido mucho más sobre mí",
    },
    "review-comment-2": {
        "en-us":
            "With MasterCracks I have learned much more about myself, it has given me a perspective of who I am and how to face the matches of my life.",
        "es-mx":
            "Con MasterCracks he aprendido mucho mas sobre mi, me ha dado una perspectiva de quien soy y cómo afrontar los partidos de mi vida.",
    },
    "review-user-3": {
        "en-us": "Diana F",
        "es-mx": "Diana F",
    },
    "review-title-3": {
        "en-us": "MasterCracks has helped me meet my goals",
        "es-mx": "MasterCracks me ha ayudado a cumplir mis objetivos",
    },
    "review-comment-3": {
        "en-us":
            "Through the cracks I have learned the potential I have to be a better version of myself and give it all up every time a challenge comes my way.",
        "es-mx":
            "A través de los cracks he aprendido el potencial que tengo para ser una mejor versión de mí y dejarlo todo cada vez que algún reto se me ponga en frente.",
    },
    "review-user-4": {
        "en-us": "Moises Z",
        "es-mx": "Moises Z",
    },
    "review-title-4": {
        "en-us": "I learned the importance of learning from a group of professionals",
        "es-mx": "Aprendí la importancia de aprender de un grupo de profesionales",
    },
    "review-comment-4": {
        "en-us":
            'I have never had such a complete coaching staff, talking about my mentality, my physical health, about nutrition, sleep and finances, have made me understand the importance of "invisible training".',
        "es-mx":
            'Nunca había tenido un cuerpo técnico tan completo, el hablar de mi mentalidad, de mi salud física, sobre nutrición, sueño y finanzas, me han hecho entender la importancia del "entrenamiento invisible".',
    },
    "review-user-5": {
        "en-us": "Yael K",
        "es-mx": "Yael K",
    },
    "review-title-5": {
        "en-us": "Soccer players are a great example to follow",
        "es-mx": "Los jugadores de fútbol son un gran ejemplo a seguir",
    },
    "review-comment-5": {
        "en-us":
            "I am not a soccer player, but I love and am very inspired by the players and I have realized that what happens on the pitch is the result of physical and mental training that is opening my mind to understand that I can also be like them.",
        "es-mx":
            "Yo no soy jugadora de fútbol, pero me encanta y me inspiran mucho los jugadores y me he dado cuenta que lo que pasa en la cancha es el resultado de un entrenamiento físico y mental que me esta abriendo mi mente para entender que yo también puedo ser como ellos.",
    },
    "review-user-6": {
        "en-us": "Ximena",
        "es-mx": "Ximena",
    },
    "review-title-6": {
        "en-us": "I have improved my habits",
        "es-mx": "He mejorado mis hábitos",
    },
    "review-comment-6": {
        "en-us":
            "MasterCracks has made me do an introspection on myself, it made me realize my strengths and weaknesses to develop myself as a person, it has made me have new and improved habits.",
        "es-mx":
            "MasterCracks me ha hecho hacer una introspección en mí, me hizo darme cuenta de mis fortalezas y debilidades para desarrollarme como persona, me ha hecho tener nuevos y mejorados hábitos.",
    },
    "test-result-title-1": {
        "en-us": "Tone your body",
        "es-mx": "Tonifica tu cuerpo",
    },
    "test-result-description-1": {
        "en-us": "for just 15 minutes a day.",
        "es-mx": "por solo 15 minutos al día.",
    },
    "test-result-title-2": {
        "en-us": "Develop Discipline",
        "es-mx": "Desarrolla disciplina",
    },
    "test-result-description-2": {
        "en-us": "and basic fitness.",
        "es-mx": "y aptitud básica.",
    },
    "test-result-title-3": {
        "en-us": "Fit a healthy habit",
        "es-mx": "Ten un hábito saludable",
    },
    "test-result-description-3": {
        "en-us": "into a busy schedule.",
        "es-mx": "en una apretada agenda.",
    },
    "general-min": {
        "en-us": "min",
        "es-mx": "min",
    },
    "test-video-data": {
        "en-us": "% ∙ % mins",
        "es-mx": "% ∙ % mins",
    },
    "test-video-data-pavel": {
        "en-us": "Pável Pardo ∙ 3 mins",
        "es-mx": "Pável Pardo ∙ 3 mins",
    },
    "test-video-data-rafa": {
        "en-us": "Rafa Márquez ∙ 3 mins",
        "es-mx": "Rafa Márquez ∙ 3 mins",
    },
    "test-video-data-jo": {
        "en-us": "Coach Jo Canales ∙ 3 mins",
        "es-mx": "Coach Jo Canales ∙ 3 mins",
    },
    "test-video-data-carlos": {
        "en-us": "Carlos Salcido ∙ 3 mins",
        "es-mx": "Carlos Salcido ∙ 3 mins",
    },
    "test-video-data-norma": {
        "en-us": "Norma Palafox ∙ 3 mins",
        "es-mx": "Norma Palafox ∙ 3 mins",
    },
    "test-video-data-oswaldo": {
        "en-us": "Oswaldo Sánchez ∙ 3 mins",
        "es-mx": "Oswaldo Sánchez ∙ 3 mins",
    },
    "test-video-data-charlyn": {
        "en-us": "Charlyn Corral ∙ 3 mins",
        "es-mx": "Charlyn Corral ∙ 3 mins",
    },
    "test-video-data-jared": {
        "en-us": "Jared Borgetti ∙ 3 mins",
        "es-mx": "Jared Borgetti ∙ 3 mins",
    },
    "highlight-ideal-space-title": {
        "en-us": "Ideal Space",
        "es-mx": "Espacio Ideal",
    },
    "highlight-ideal-space-desc": {
        "en-us": "Wide space, ideally a court or some open place.",
        "es-mx": "Espacio amplio, idealmente una cancha o algún lugar abierto.",
    },
    "highlight-equipment": {
        "en-us": "Equipment",
        "es-mx": "Equipamiento",
    },
    "workout-express-highlight-equipment-skills-desc": {
        "en-us": "Box, mat, ball or object for even push ups",
        "es-mx": "Cajon, tapete, balon u objeto para uneven push ups",
    },
    "master-core-highlight-equipment-skills-desc": {
        "en-us": "Mat, dumbbell/heavy object/ball",
        "es-mx": "Tapete, pesa/objeto pesado/balon",
    },
    "highlight-equipment-skills-desc": {
        "en-us": "Soccer ball, sportswear and soccer shoes.",
        "es-mx": "Balón de fútbol, ropa deportiva y zapatos de fútbol.",
    },
    "highlight-equipment-fitness-desc": {
        "en-us": "Stopwatch and sportswear.",
        "es-mx": "Cronómetro y ropa deportiva.",
    },
    "highlight-equipment-fitness-sportswear-desc": {
        "en-us": "Sportswear.",
        "es-mx": "Ropa deportiva.",
    },
    "highlight-equipment-fitness-sportswear-hemisphere-desc": {
        "en-us": "Sportswear and Bosu ball.",
        "es-mx": "Ropa deportiva y semiesfera de balance.",
    },
    "highlight-equipment-fitness-sportswear-medicine-ball-desc": {
        "en-us": "Sportswear and medicine ball.",
        "es-mx": "Ropa deportiva y balón medicinal.",
    },
    "highlight-equipment-fitness-sportswear-medicine-ball-handle-desc": {
        "en-us": "Sportswear and a medicine ball with grip (4kg recommended).",
        "es-mx": "Ropa deportiva y 1 balón medicinal con manija (recomendado 4 kg).",
    },
    "highlight-equipment-fitness-bumbbells-desc": {
        "en-us": "2 dumbbells (2 to 4 kg).",
        "es-mx": "2 mancuernas de 2 a 4 kg.",
    },
    "highlight-equipment-fitness-bench-mattress-desc": {
        "en-us": "Bench or mat.",
        "es-mx": "Banco o colchoneta.",
    },
    "highlight-equipment-fitness-balance-balls-desc": {
        "en-us": "2 Bosu balls.",
        "es-mx": "2 semiesferas de balance o 2 pesas rusas (ketlebells).",
    },
    "highlight-equipment-fitness-fitball-desc": {
        "en-us": "1 fitball.",
        "es-mx": "1 fitball.",
    },
    "highlight-equipment-fitness-balance-hemisphere-fitball-desc": {
        "en-us": "1 Bosu ball + 1 fitball.",
        "es-mx": "1 semiesfera de balance y 1 fitball.",
    },
    "highlight-equipment-fitness-teammate-elastic-desc": {
        "en-us": "1 teammate + 1 strength band.",
        "es-mx": "1 compañero de trabajo y 1 resistencia elástica.",
    },
    "highlight-equipment-fitness-ketlebell-desc": {
        "en-us": "1 ketlebell.",
        "es-mx": "1 pesa rusa (ketlebell).",
    },
    "highlight-equipment-fitness-bench-desc": {
        "en-us": "1 bench.",
        "es-mx": "1 banco.",
    },
    "highlight-equipment-fitness-medicine-ball-fitball-balance-hemisphere-desc": {
        "en-us": "1 medicine ball with grip, 1 fitball, 1 bosu ball.",
        "es-mx": "1 balón medicinal con manija, 1 fitball, 1 semiesfera de balance.",
    },
    "highlight-equipment-fitness-soccer-balls-desc": {
        "en-us": "2 soccer balls.",
        "es-mx": "2 balones de fútbol.",
    },
    "highlight-equipment-fitness-trx-desc": {
        "en-us": "1 TRX exercise band.",
        "es-mx": "1 cuerda de entrenamiento TRX.",
    },
    "highlight-equipment-fitness-medicine-ball-ketlebell-desc": {
        "en-us": "1 medicine ball with grip.",
        "es-mx": "1 balón medicinal con manija o 1 pesa rusa (ketlebell).",
    },
    "highlight-equipment-fitness-medicine-bench-elastic-desc": {
        "en-us": "Medicine ball, bench, small goal, elastic mat.",
        "es-mx": "Balón medicinal, 1 banco, 1 portería de fútbol pequeña, 1 colchoneta elástica.",
    },
    "highlight-equipment-skills-goalkeeper-1-desc": {
        "en-us": "Ball and goalkeeper gloves.",
        "es-mx": "Balón y guantes de portero.",
    },
    "highlight-equipment-skills-goalkeeper-2-desc": {
        "en-us": "Ball, goalkeeper gloves, inflatable mannequin.",
        "es-mx": "Balón, guantes de portero, maniquí(es) inflable(s) y escudo de precisión.",
    },
    "secret-dream-title": {
        "en-us": "Dream",
        "es-mx": "Sueño",
    },
    "secret-team-title": {
        "en-us": "Team",
        "es-mx": "Equipo",
    },
    "secret-habits-title": {
        "en-us": "Habits",
        "es-mx": "Hábitos",
    },
    "secret-resignation-failure-title": {
        "en-us": "Resignation of failure",
        "es-mx": "Resignación del fracaso",
    },
    "secret-leadership-title": {
        "en-us": "Leadership",
        "es-mx": "Liderazgo",
    },
    "secret-financial-health-title": {
        "en-us": "Financial health",
        "es-mx": "Salud financiera",
    },
    "secret-movement-title": {
        "en-us": "Movement",
        "es-mx": "Movimiento",
    },
    "secret-purpose-title": {
        "en-us": "Purpose",
        "es-mx": "Propósito",
    },
    "secret-visualization-title": {
        "en-us": "Visualization",
        "es-mx": "Visualización",
    },
    "secret-physical-mental-training-title": {
        "en-us": "Mental and emotional training",
        "es-mx": "Entrenamiento mental y emocional",
    },
    "secret-nutrition-title": {
        "en-us": "Nutrition",
        "es-mx": "Alimentación",
    },
    "crack-pavel-pardo-mindset-visualization-title": {
        "en-us": "Visualization",
        "es-mx": "Visualización",
    },
    "crack-pavel-pardo-mindset-visualization-desc": {
        "en-us":
            "To achieve your goal, it is necessary to first visualize it, see it clearly, feel it. When you visualize what you want, everything can be achieved it.",
        "es-mx":
            "Visualizar te ayudará a concretar: Pável explica que “para alcanzar tu objetivo es necesario, primero, visualizarlo, verlo con claridad...sentirlo. Cuando visualizas las cosas, todo se puede lograr”.",
    },
    "crack-pavel-pardo-mindset-visualization-step-1": {
        "en-us": "Identify your goal: Observe, feel it, internalize it.",
        "es-mx": "Identifica tu objetivo: Obsérvalo, siéntelo, internalízalo.",
    },
    "crack-pavel-pardo-mindset-visualization-step-2": {
        "en-us": "Determine the actions that will help you reach your goal.",
        "es-mx": "¿Qué pasos te ayudarán a alcanzarlo, y qué cosas te bloquean?",
    },
    "crack-pavel-pardo-mindset-visualization-step-3": {
        "en-us":
            "Trace your route: what steps will help, and which ones will block you to reach your goal?",
        "es-mx": "Traza tu ruta: ¿qué pasos debes seguir para alcanzarlo?",
    },
    "crack-pavel-pardo-mindset-visualization-step-4": {
        "en-us":
            "Allow yourself to feel the emotion generated by reaching your goal: how does it feel?",
        "es-mx":
            "Siente la emoción que te genera llegar a la meta. ¿Cómo te sentirías al lograr tu objetivo?",
    },
    "crack-pavel-pardo-mindset-inner-speech-title": {
        "en-us": "Positive inner voice",
        "es-mx": "Habla interna positiva",
    },
    "crack-pavel-pardo-mindset-inner-speech-desc": {
        "en-us":
            "Regardless how tough is a challenge, you have to tell yourself that nothing is impossible.",
        "es-mx":
            "Hablarse bien, decirse cosas lindas y motivadoras es fundamental para lograr lo que te propongas. Descubre cómo lo hace Pável Pardo en este video.",
    },
    "crack-pavel-pardo-mindset-inner-speech-step-1": {
        "en-us": "Connect with your inner voice.",
        "es-mx": "Conecta con tu voz interior.",
    },
    "crack-pavel-pardo-mindset-inner-speech-step-2": {
        "en-us": "Make that voice a positive support in your life.",
        "es-mx": "Haz de esa voz un apoyo positivo en tu vida.",
    },
    "crack-pavel-pardo-mindset-inner-speech-step-3": {
        "en-us": "Be emphatic with yourself: speak to yourself with affection and respect.",
        "es-mx": "Sé empático contigo mismo: Háblate con afecto y respeto.",
    },
    "crack-pavel-pardo-mindset-inner-speech-step-4": {
        "en-us": "Avoid complaining or speaking contemptuously.",
        "es-mx": "Evita hablarte con recriminación o desprecio.",
    },
    "crack-pavel-pardo-mindset-self-motivation-title": {
        "en-us": "Self-motivation",
        "es-mx": "Automotivación",
    },
    "crack-pavel-pardo-mindset-self-motivation-desc": {
        "en-us":
            "“Being left out of the 2002 World Cup was a hard blow, but I decided to analyze the situation with self-criticism, get out of my comfort zone and prepared myself for the next challenge”.",
        "es-mx":
            "“Queda fuera del Mundial '02 fue un golpe duro. Pero la vida siguió: hice mi autocrítica, y me preparé para el siguiente reto, saliéndome de la zona de confort”.",
    },
    "crack-pavel-pardo-mindset-self-motivation-step-1": {
        "en-us": "Get uncomfortable in your comfort zone: what else can you accomplish?",
        "es-mx": "Ponte incómodo en tu zona de confort: ¿qué más puedes lograr?",
    },
    "crack-pavel-pardo-mindset-self-motivation-step-2": {
        "en-us": "Identify your pains: what is holding you back? Why are you blocked?",
        "es-mx": "Identifica tu apatía: ¿Qué te detiene? ¿Por qué te bloqueas?",
    },
    "crack-pavel-pardo-mindset-self-motivation-step-3": {
        "en-us": "Turn any difficulty into a challenge to overcome.",
        "es-mx": "Transforma las dificultades en retos a superar.",
    },
    "crack-pavel-pardo-mindset-self-motivation-step-4": {
        "en-us": "Leave inertia behind and renew your habits and routines.",
        "es-mx": "Vence la inercia: renueva tus hábitos y rutinas.",
    },
    "crack-pavel-pardo-mindset-mental-growth-title": {
        "en-us": "Growth Mindset",
        "es-mx": "Mentalidad de Crecimiento",
    },
    "crack-pavel-pardo-mindset-mental-growth-desc": {
        "en-us":
            "“There were always better players tan me. For instance, in the speed exercises I could be the last to arrive...but always arrived, and always finished things: that is the most important thing”.",
        "es-mx":
            "Para construir su memorable carrera, Pável tuvo algo siempre en claro: solo dejándolo todo en cada entrenamiento, y buscando formas constantes de mejorar, podría cumplir con sus sueños deportivos.",
    },
    "crack-pavel-pardo-mindset-mental-growth-step-1": {
        "en-us": "Recognize your opportunities for growth and improvement.",
        "es-mx": "Reconoce tus oportunidades de crecimiento y de mejora.",
    },
    "crack-pavel-pardo-mindset-mental-growth-step-2": {
        "en-us": "Cultivate a clear sense of purpose: what do you really want to do in your life?",
        "es-mx": "Cultiva un sentido de propósito claro: ¿qué te mueve a ser y hacer en tu vida?",
    },
    "crack-pavel-pardo-mindset-mental-growth-step-3": {
        "en-us": "Learn from the challenges and mistakes, and use them in your favor.",
        "es-mx": "Usa los errores a tu favor: siempre puedes aprender de ellos.",
    },
    "crack-pavel-pardo-mindset-mental-growth-step-4": {
        "en-us":
            "Work with full attention: the important thing is not to finish first, but to do things well.",
        "es-mx":
            "Trabaja con plena atención, lo importante es hacerlo bien, la velocidad se alcanza con la práctica.",
    },
    "crack-pavel-pardo-skills-bike-title": {
        "en-us": "Bike",
        "es-mx": "Bicicleta",
    },
    "crack-pavel-pardo-skills-bike-desc": {
        "en-us":
            "when driving the ball pass your leg over the ball and go in the opposite direction.",
        "es-mx":
            "Al conducir el balón pasar la pierna por encima del balón y sal en la dirección contraria.",
    },
    "crack-pavel-pardo-skills-outside-shot-title": {
        "en-us": "Hitting with external part",
        "es-mx": "Golpeo con parte externa",
    },
    "crack-pavel-pardo-skills-outside-shot-desc": {
        "en-us":
            "Take care of your body posture, the supporting foot and the striking zone are very important.",
        "es-mx":
            "Cuida la postura de tu cuerpo, el pie de apoyo y la zona de golpeo son muy importantes.",
    },
    "crack-pavel-pardo-skills-pass-control-int-title": {
        "en-us": "Control and pass",
        "es-mx": "Control y pase",
    },
    "crack-pavel-pardo-skills-pass-control-int-desc": {
        "en-us":
            "At the moment of the blow keep your body slightly inclined to the front, when receiving the ball direct it slightly in front of you.",
        "es-mx":
            "Al momento del golpe mantén el cuerpo ligeramente inclinado al frente, al recibir el balón dirígelo ligeramente delante de ti.",
    },
    "crack-pavel-pardo-skills-pass-control-tri-title": {
        "en-us": "Pass with control in triangle circuit",
        "es-mx": "Pase con control en circuito triangular",
    },
    "crack-pavel-pardo-skills-pass-control-tri-desc": {
        "en-us": "Receive the ball always on tiptoe, body upright, the second touch is the pass.",
        "es-mx":
            "Recibir la pelota siempre en punta de pies, cuerpo erguido, el segundo toque es el pase.",
    },
    "crack-pavel-pardo-skills-poison-dart-title": {
        "en-us": "Poison Dart",
        "es-mx": "Dardo Envenenado",
    },
    "crack-pavel-pardo-skills-poison-dart-desc": {
        "en-us":
            "The most important thing is that the supporting foot has to be correctly positioned to be able to hit the ball in the best way.",
        "es-mx":
            "Lo más importante es que el pie de apoyo tiene que estar correctamente posicionado para poder impactar el balón de la mejor manera.",
    },
    "crack-pavel-pardo-skills-zig-zag-title": {
        "en-us": "Zig Zag",
        "es-mx": "Zig Zag",
    },
    "crack-pavel-pardo-skills-zig-zag-desc": {
        "en-us": "Lower your center of gravity to have a better reaction.",
        "es-mx": "Baja tu centro de gravedad para tener mejor reacción.",
    },
    "crack-pavel-pardo-stories-1-title": {
        "en-us": "The dream of being a soccer player",
        "es-mx": "El sueño de ser futbolista",
    },
    "crack-pavel-pardo-stories-1-desc": {
        "en-us":
            "“When I was a kid, my dad use to take me to the games. One day, I told him that I'm gonna be playing here for all these people....and they will come just to see me”",
        "es-mx":
            "De chiquito, cuando su papá lo llevaba al estadio, Pável ya visualizaba su carrera: “pa, toda la gente que está aquí hoy, un día pagará la entrada para verme jugar a mí”, le anticipó a su padre...y cumplió.",
    },
    "crack-pavel-pardo-stories-2-title": {
        "en-us": "The opportunity",
        "es-mx": "La oportunidad",
    },
    "crack-pavel-pardo-stories-2-desc": {
        "en-us":
            "“I faced my parents and told them that I have a big opportunity, so I asked them permission to dedicate myself 100% to soccer for one year...and if it doesn't work, I'll come back to study”.",
        "es-mx":
            "Cuando se le presentó su primera oportunidad real en el fútbol, Pável no lo dudó: le planteó a sus padres tomarse un año para dedicarse 100% al fútbol, con la condición de que, si no funcionaba, se dedicaría de lleno a los estudios.",
    },
    "crack-pavel-pardo-stories-3-title": {
        "en-us": "From “baby” to “Commander”",
        "es-mx": "De “bebé” a “Comandante”",
    },
    "crack-pavel-pardo-stories-3-desc": {
        "en-us":
            "Pavel arrived to Germany with the utopian dream of becoming the Bundesliga winner and not return to Mexico until he get this done. Soon, he became one of Stuttgart's captains, and turned from “baby” to “Commander”.",
        "es-mx":
            "Cuando llegó a Alemania, Pável se prometió hacer historia: “mi objetivo es ser campeón. Si no logro triunfar, regresaré a México”. Con esa actitud, llevó al Stuttgart a hacer historia.",
    },
    "crack-pavel-pardo-stories-4-title": {
        "en-us": "The legacy",
        "es-mx": "El legado",
    },
    "crack-pavel-pardo-stories-4-desc": {
        "en-us":
            "“Stuttgart hadn't been the Bundesliga winner for 13 years, but I  came with the mentality of being champions, and showed them that Mexicans are capable of that...and more”.",
        "es-mx":
            "“Me hace muy feliz haber dejado un legado en Alemania: cuando llegué al equipo, el Stuttgart venía de 13 años sin salir campeón...y pude demostrar de lo que somos capaces los mexicanos”.",
    },
    "crack-rafa-marquez-mindset-determination-title": {
        "en-us": "Determination",
        "es-mx": "Determinación",
    },
    "crack-rafa-marquez-mindset-determination-desc": {
        "en-us":
            "Determination is transformative. “Innate talents can always create opportunites, but you have to work hard to make them come true”.",
        "es-mx":
            "Para Rafa, “la determinación es transformadora: los talentos innatos pueden crearse oportunidades, pero todos debemos esforzarnos para que esas oportunidades se hagan realidad”.",
    },
    "crack-rafa-marquez-mindset-determination-step-1": {
        "en-us": "Identify what you want to achieve and set short, medium and long term goals.",
        "es-mx":
            "Identifica aquello que quieres lograr y establece metas a corto, mediano y largo plazo.",
    },
    "crack-rafa-marquez-mindset-determination-step-2": {
        "en-us": "Know your strengths and limitations, and use them to your advantage.",
        "es-mx": "Conoce tus fortalezas y limitaciones, y úsalas a tu favor.",
    },
    "crack-rafa-marquez-mindset-determination-step-3": {
        "en-us": "Work with concentration and commitment, and value your effort.",
        "es-mx": "Valora el esfuerzo, trabaja con concentración y compromiso.",
    },
    "crack-rafa-marquez-mindset-determination-step-4": {
        "en-us": "Keep your expectations high, and act accordingly.",
        "es-mx": "Mantén tus expectativas altas, y actúa con responsabilidad.",
    },
    "crack-rafa-marquez-mindset-determination-step-5": {
        "en-us": "Determine how your actions can inspire others.",
        "es-mx": "Determina cómo tus acciones inspiran a los demás.",
    },
    "crack-rafa-marquez-mindset-anger-management-title": {
        "en-us": "Emotional regulation",
        "es-mx": "Regulación emocional",
    },
    "crack-rafa-marquez-mindset-anger-management-desc": {
        "en-us":
            "Mismanaged emotions can make you lose your way. Being aware of your emotions will allow you to manage them in your favor and stay focused.",
        "es-mx":
            "Manejar bien las emociones es clave para triunfar en cualquier rubro. Aprende los secretos de regulación emocional con el gran Rafa Márquez.",
    },
    "crack-rafa-marquez-mindset-anger-management-step-1": {
        "en-us": "Recognize your emotional triggers: what makes you angry or frustrated?",
        "es-mx": "Reconoce tus disparadores emocionales: ¿qué te genera enojo o frustración?",
    },
    "crack-rafa-marquez-mindset-anger-management-step-2": {
        "en-us": "Reflect on your actions: how you react when you get angry?",
        "es-mx": "Reflexiona sobre tu forma de reaccionar: ¿qué haces cuando te enojas?",
    },
    "crack-rafa-marquez-mindset-anger-management-step-3": {
        "en-us": "Learn to STOP: if you feel annoyed or irritated, take a breath before acting.",
        "es-mx": "cuando sientas enojo, detente y RESPIRA antes de actuar.",
    },
    "crack-rafa-marquez-mindset-anger-management-step-4": {
        "en-us": "Find moments to relax and turn off the unnecessary noise from your life.",
        "es-mx":
            "Durante el día, busca momentos para relajarte y apagar el ruido excesivo en tu mente.",
    },
    "crack-rafa-marquez-mindset-leadership-title": {
        "en-us": "Leadership",
        "es-mx": "Liderazgo",
    },
    "crack-rafa-marquez-mindset-leadership-desc": {
        "en-us":
            "“Leadership is being encouraged to take the first step, and inspiring others to achieve what they considered impossible”.",
        "es-mx":
            "Hablar de liderazgo es hablar de Rafa: “liderar es atreverse a dar el primer paso y, con él, inspirar a otros a hacer cosas que nunca creyeron que podrían lograr”, explica el ex Capitán del Tri.",
    },
    "crack-rafa-marquez-mindset-leadership-step-1": {
        "en-us": "Communicate clearly and listen carefully.",
        "es-mx": "Comunícate con claridad y escucha con atención.",
    },
    "crack-rafa-marquez-mindset-leadership-step-2": {
        "en-us": "Make firm decisions and be consistent.",
        "es-mx": "Toma decisiones firmes y sé consistente.",
    },
    "crack-rafa-marquez-mindset-leadership-step-3": {
        "en-us":
            "Face disagreements with your team with an open mind; if you are wrong, admit it and rectify.",
        "es-mx":
            "Enfrenta los desacuerdos con tu equipo con mentalidad abierta. Reconoce cuando te equivoques y rectifícate.",
    },
    "crack-rafa-marquez-mindset-leadership-step-4": {
        "en-us": "Inspire and motivate others by example.",
        "es-mx": "Inspira y motiva a otros con el ejemplo.",
    },
    "crack-rafa-marquez-mindset-discipline-title": {
        "en-us": "Discipline",
        "es-mx": "Disciplina",
    },
    "crack-rafa-marquez-mindset-discipline-desc": {
        "en-us":
            "Maintaining discipline is a habit and a mental matter in which you have to train your mentality, try to think that this will always lead you to achieve everything you set out to do.",
        "es-mx":
            "La disciplina es la mejor amiga del éxito en cualquier carrera. Descubre, en este video exclusivo, cómo generar el hábito de la disciplina entrenando a tu mente.",
    },
    "crack-rafa-marquez-mindset-discipline-step-1": {
        "en-us": "Assign a specific time to work on your goals.",
        "es-mx": "Asigna un tiempo específico para trabajar sobre tus objetivos.",
    },
    "crack-rafa-marquez-mindset-discipline-step-2": {
        "en-us": "Break big goals down into smaller, more tangible goals.",
        "es-mx": "Divide las metas grandes en metas más pequeñas y tangibles.",
    },
    "crack-rafa-marquez-mindset-discipline-step-3": {
        "en-us": "Create habits and routines that help you achieve your goals.",
        "es-mx": "Crea hábitos y rutinas que contribuyan a alcanzar tus objetivos.",
    },
    "crack-rafa-marquez-mindset-discipline-step-4": {
        "en-us":
            "Be flexible and take perspective, a bad day does not mean that everything failed.",
        "es-mx": "Sé flexible y autocompasivo: un mal día no implica que todo sea un fracaso.",
    },
    "crack-rafa-marquez-skills-headshot-title": {
        "en-us": "Headshot",
        "es-mx": "Cabeceo",
    },
    "crack-rafa-marquez-skills-headshot-desc": {
        "en-us":
            "Make a bow movement keeping your mouth closed, profile to the side, raise your arms and contact the ball with the horn of the forehead with a small twist.",
        "es-mx":
            "Hacer movimiento de reverencia manteniendo la boca cerrada, perfilarse de lado, levantar los brazos y contactar la pelota con el cuerno de la frente con un pequeño giro.",
    },
    "crack-rafa-marquez-skills-long-shot-title": {
        "en-us": "Long Shot",
        "es-mx": "Trazo Largo",
    },
    "crack-rafa-marquez-skills-long-shot-desc": {
        "en-us":
            "The most important aspects to make a long stroke is the hit, profile, support foot and the force and impact that will be given to the ball.",
        "es-mx":
            "Los aspectos más importantes para hacer un trazo largo es el golpeo,perfil, pie de apoyo y la fuerza e impacto que se le dará al balón.",
    },
    "crack-rafa-marquez-skills-free-kick-title": {
        "en-us": "Free Kick",
        "es-mx": "Tiro Libre",
    },
    "crack-rafa-marquez-skills-free-kick-desc": {
        "en-us":
            "To execute a good free kick, hitting is very important, knowing where you want to hit, if it is above the barrier, below, the distance and the most general thing is to hit with the inside of the foot.",
        "es-mx":
            "Para ejecutar un buen tiro libre es muy importante el golpeo, saber hacia dónde se quiere golpear, si es por arriba de la barrera, por abajo, la distancia y lo más general es golpear con la parte interna del pie.",
    },
    "crack-rafa-marquez-skills-art-defending-1-title": {
        "en-us": "The art of defending - Chapter 1",
        "es-mx": "El arte de defender - Capítulo 1",
    },
    "crack-rafa-marquez-skills-art-defending-1-desc": {
        "en-us":
            "The most important aspects of how to defend without the ball, how to maintain defensive order, how to anticipate the mark and how to execute effective defensive work will be seen.",
        "es-mx":
            "Se verán los aspectos más importantes sobre como defender sin balón, como mantener el orden defensivo, como anticiparse a la marca y como ejecutar un trabajo defensivo efectivo.",
    },
    "crack-rafa-marquez-skills-art-defending-2-title": {
        "en-us": "The art of defending - Chapter 2",
        "es-mx": "El arte de defender - Capítulo 2",
    },
    "crack-rafa-marquez-skills-art-defending-2-desc": {
        "en-us":
            "The most important aspects of defensive order and being a great central defender will be seen.",
        "es-mx":
            "Se verán los aspectos más importantes sobre el orden defensivo y ser un gran defensa central.",
    },
    "crack-rafa-marquez-stories-1-title": {
        "en-us": "Soccer was everything for me",
        "es-mx": "Todo era fútbol para mí",
    },
    "crack-rafa-marquez-stories-1-desc": {
        "en-us":
            "“In my childhood, soccer was all what I wanted, to the point that I created a field with furniture at home”.",
        "es-mx":
            "“Todo era fútbol para mí: en la escuela, en los recreos, o en mi casa, donde hacía mi cancha con los muebles”.",
    },
    "crack-rafa-marquez-stories-2-title": {
        "en-us": "Dad's pride",
        "es-mx": "El orgullo de papá",
    },
    "crack-rafa-marquez-stories-2-desc": {
        "en-us":
            "“Being appointed as Mexico's captain at such a young age made my father, a very demanding person, the proudest dad on earth”.",
        "es-mx":
            "“Mi padre siempre fue muy exigente conmigo: siempre me ponía nuevos retos para que me supere. Por eso, cuando fui designado capitán de la selección tan joven, lo convertí en el papá más orgulloso del mundo”.",
    },
    "crack-rafa-marquez-stories-3-title": {
        "en-us": "Playing with the best",
        "es-mx": "Jugando con los mejores",
    },
    "crack-rafa-marquez-stories-3-desc": {
        "en-us":
            "After signing with Barcelona, Rafa joined a squad full of world-class stars: “living, training and playing with them it was a dream come true”.",
        "es-mx":
            "A veces, los sueños se vuelven realidad. Rafa lo sabe: cuando llegó al Barcelona, se encontró con un vestuario lleno de estrellas que parecían inalcanzables: “convivir, entrenar y jugar con ellos fue un sueño hecho realidad”.",
    },
    "crack-rafa-marquez-stories-4-title": {
        "en-us": "The end of my career",
        "es-mx": "El final de mi carrera",
    },
    "crack-rafa-marquez-stories-4-desc": {
        "en-us":
            "“I left Europe thinking of finishing my career playing with more joy and less pressure... and I found myself with everything but that!”.",
        "es-mx":
            "Cuando se terminó su tiempo en el Barsa, Rafa eligió a la MLS como siguiente paso en su carrera. Aunque pensó que en esa liga menos competitiva podría “disfrutar” más del juego...nada salió como lo tenía planeado.",
    },
    "crack-charlyn-corral-mindset-courage-title": {
        "en-us": "Courage",
        "es-mx": "Valentía",
    },
    "crack-charlyn-corral-mindset-courage-desc": {
        "en-us":
            "“It takes courage to become what you really want to be: a few years ago a woman in football was unthinkable!” confesses Charlyn, who at all times, despite comments and criticism, followed her heart to continue fully immersed in her greatest passion.",
        "es-mx":
            "“Se requiere valentía para convertirse en lo que realmente quieres ser: ¡hace unos años era impensable una mujer en el fútbol!”, confiesa Charlyn, que en todo momento, a pesar de los comentarios y las críticas, siguió a su corazón para continuar metida de lleno en su gran pasión.",
    },
    "crack-charlyn-corral-mindset-courage-step-1": {
        "en-us":
            "Take perspective on your life, ask yourself who do you want to be?, What do you base your decisions on?.",
        "es-mx":
            "Toma perspectiva sobre tu vida, pregúntate ¿quién quieres ser?, ¿en qué basas tus decisiones?.",
    },
    "crack-charlyn-corral-mindset-courage-step-2": {
        "en-us": "Do not settle, go in search of new challenges.",
        "es-mx": "No te conformes, ve en búsqueda de nuevos retos.",
    },
    "crack-charlyn-corral-mindset-courage-step-3": {
        "en-us": "Take on the big challenges one day at a time.",
        "es-mx": "Enfrenta los grandes retos un día a la vez.",
    },
    "crack-charlyn-corral-mindset-courage-step-4": {
        "en-us": "Control fear, do not let it invade your thoughts.",
        "es-mx": "Controla el miedo, no dejes que invada tus pensamientos.",
    },
    "crack-charlyn-corral-mindset-modesty-title": {
        "en-us": "Modesty",
        "es-mx": "Humildad",
    },
    "crack-charlyn-corral-mindset-modesty-desc": {
        "en-us":
            "Working with humility, as Charlyn did, will allow you to discover your best version, feed your growth mindset and be an invaluable contribution to your team... in any field of life.",
        "es-mx":
            "Trabajar con humildad, como lo hizo Charlyn, te permitirá descubrir tu mejor versión, alimentar tu mentalidad de crecimiento y ser una contribución invaluable para tu equipo..en cualquier ámbito de la vida.",
    },
    "crack-charlyn-corral-mindset-modesty-step-1": {
        "en-us": "Accept when you're wrong.",
        "es-mx": "Reconoce cuando te equivocas.",
    },
    "crack-charlyn-corral-mindset-modesty-step-2": {
        "en-us": "Learn from your mistakes.",
        "es-mx": "Aprende de tus errores.",
    },
    "crack-charlyn-corral-mindset-modesty-step-3": {
        "en-us": "Recognize that you can't always be in control.",
        "es-mx": "Acepta que no siempre puedes tener el control de las cosas.",
    },
    "crack-charlyn-corral-mindset-modesty-step-4": {
        "en-us": "Share your experience with others.",
        "es-mx": "Comparte tu experiencia con los demás.",
    },
    "crack-charlyn-corral-mindset-sense-agency-title": {
        "en-us": "Sense of Agency",
        "es-mx": "Sentido de Agencia",
    },
    "crack-charlyn-corral-mindset-sense-agency-desc": {
        "en-us":
            "This crack explains that fear brings with it two alternatives: give up everything and run away... or face it, fight and grow.",
        "es-mx":
            "Explica esta Crack que el miedo trae consigo dos alternativas: renunciar a todo y huir...o enfrentarlo, ponerle la cara y crecer.",
    },
    "crack-charlyn-corral-mindset-sense-agency-step-1": {
        "en-us": "Identify your talents and exercise them.",
        "es-mx": "Identifica tus talentos y ejercítalos.",
    },
    "crack-charlyn-corral-mindset-sense-agency-step-2": {
        "en-us": "Be clear in your decisions and act responsibly.",
        "es-mx": "Sé claro en tus decisiones y actúa con responsabilidad.",
    },
    "crack-charlyn-corral-mindset-sense-agency-step-3": {
        "en-us": "Avoid comparing yourself to other people.",
        "es-mx": "Evita compararte con otras personas.",
    },
    "crack-charlyn-corral-mindset-sense-agency-step-4": {
        "en-us": "Challenge yourself, test your abilities.",
        "es-mx": "Desafíate: poner a prueba tus capacidades te hará crecer, siempre.",
    },
    "crack-charlyn-corral-mindset-flow-title": {
        "en-us": "To Flow",
        "es-mx": "Fluir",
    },
    "crack-charlyn-corral-mindset-flow-desc": {
        "en-us":
            "“Flow is the nature of life. It allows you to be in the present without getting stuck in the past or getting lost in a non-existent future,” says Charlyn, for whom being able to flow at all times allowed her to build a memorable career.",
        "es-mx":
            "“Fluir es la naturaleza de la vida. Te permite estar consciente en el presente sin atorarte en el pasado o perderte en un futuro inexistente”, confiesa Charlyn, a quien poder fluir a cada momento le permitió construir una carrera memorable.",
    },
    "crack-charlyn-corral-mindset-flow-step-1": {
        "en-us": "Focus your attention on what is important to achieve your goals.",
        "es-mx": "Focaliza tu atención en lo que es importante para lograr de tus metas.",
    },
    "crack-charlyn-corral-mindset-flow-step-2": {
        "en-us": "Enjoy life without reservations, but without falling into excesses.",
        "es-mx": "Disfruta la vida sin reservas, pero sin caer en excesos. ",
    },
    "crack-charlyn-corral-mindset-flow-step-3": {
        "en-us": "Cultivate a sense of humor.",
        "es-mx": "Cultiva el sentido del humor.",
    },
    "crack-charlyn-corral-mindset-flow-step-4": {
        "en-us": "Find moments of stillness to reconnect with yourself.",
        "es-mx": "Busca momentos de quietud para reconectar contigo misma(o).",
    },
    "crack-charlyn-corral-skills-cutting-hitting-ball-title": {
        "en-us": "Cutting and hitting ball",
        "es-mx": "Recorte y golpeo de balón",
    },
    "crack-charlyn-corral-skills-cutting-hitting-ball-desc": {
        "en-us":
            "You will learn how to improve your hitting technique and outwit your opponent by improving your ability to make a cut.",
        "es-mx":
            "Aprenderás como mejorar tu técnica de golpeo y burlar a tu contrincante mejorando tu habilidad para hacer un recorte.",
    },
    "crack-charlyn-corral-skills-little-hat-title": {
        "en-us": "Little hat",
        "es-mx": "Sombrerito",
    },
    "crack-charlyn-corral-skills-little-hat-desc": {
        "en-us":
            "Do you remember the little hat goal with which Char won recognition as the best goal in the 2019 Spanish league? You will learn how to imitate it.",
        "es-mx":
            "¿Recuerdas el gol de sombrerito con el cual Char se llevó el reconocimiento como el mejor gol en el 2019 de la liga española?, aprenderás cómo imitarlo.",
    },
    "crack-charlyn-corral-stories-1-title": {
        "en-us": "“They will know me when I have the ball”",
        "es-mx": "Cuando tenga el balón es cuando me van a conocer",
    },
    "crack-charlyn-corral-stories-1-desc": {
        "en-us":
            "At a very young age, Charlyn realized that she was really good, and then things stated to flow naturally towards a professional career.",
        "es-mx":
            "¿Qué pasa cuando un hobby se convierte en una profesión? Charlyn experimentó este salto de muy joven, cuando se dio cuenta de que realmente podría dedicarse al fútbol de manera profesional.",
    },
    "crack-charlyn-corral-stories-2-title": {
        "en-us": "The “Quarantine”",
        "es-mx": "La “Cuarentena”",
    },
    "crack-charlyn-corral-stories-2-desc": {
        "en-us":
            "Bad things can happen in the less expected moment, and Charlyn knows it: she got chickenpox when traveling to the Pan American games!",
        "es-mx":
            "En el fútbol de alto rendimiento, la plenitud física es clave para mantenerse. Cuando Charlyn estaba 100% lista para jugar los Panamericanos, una noticia sobre su salud la descolocó por completo.",
    },
    "crack-charlyn-corral-stories-3-title": {
        "en-us": "The third time was the charm",
        "es-mx": "La tercera fue la vencida",
    },
    "crack-charlyn-corral-stories-3-desc": {
        "en-us":
            "After 2 brilliant seasons in the Spanish team Levante, Charlyn still had another goal to accomplish: become the Liga's “Pichichi”.",
        "es-mx":
            "Las grandes Cracks siempre tienen nuevas metas por delante: en el caso de Charlyn, su objetivo en España era claro: convertirse en “Pichichi” de la liga femenil.",
    },
    "crack-charlyn-corral-stories-4-title": {
        "en-us": "“My ultimate goal was to be champion”",
        "es-mx": "Mi objetivo de venir aquí es ser campeona",
    },
    "crack-charlyn-corral-stories-4-desc": {
        "en-us":
            "“After the COVID break and my recovery from a serious injury, I arrived to Pachucha with a simple and clear objective: become the champion of Mexico”.",
        "es-mx":
            "Ganar, ganar y ganar: las Cracks más ganadoras como Charlyn Corral siempre aspiran a lo más alto, y por eso, en su vuelta al fútbol mexicano, solo pensó en ser campeona con Pachuca.",
    },
    "crack-jared-borgetti-mindset-empathy-title": {
        "en-us": "Empathy",
        "es-mx": "Empatía",
    },
    "crack-jared-borgetti-mindset-empathy-desc": {
        "en-us":
            "“You only really understand other people if you can put yourself in their shoes”.",
        "es-mx":
            "A Jared, vivir con empatía le permitió ser una mejor persona: “solo consigues entender realmente a los otros cuando lograr ponerte en su lugar”, confiesa.",
    },
    "crack-jared-borgetti-mindset-empathy-step-1": {
        "en-us": "Learn to understand others, their ideas and their feelings.",
        "es-mx": "Aprende a conocer a los demás, sus ideas y sus sentimientos.",
    },
    "crack-jared-borgetti-mindset-empathy-step-2": {
        "en-us":
            "Examine your own beliefs and prejudices before giving an opinion about another person.",
        "es-mx": "Examina tus creencias y prejuicios antes de opinar sobre otra persona.",
    },
    "crack-jared-borgetti-mindset-empathy-step-3": {
        "en-us": "Faced with a disagreement, try to put yourself in the other's place.",
        "es-mx": "Ante un desencuentro, intenta ponerte en el lugar del otro.",
    },
    "crack-jared-borgetti-mindset-empathy-step-4": {
        "en-us": "Respect and appreciate diversity.",
        "es-mx": "Respeta y aprecia la diversidad.",
    },
    "crack-jared-borgetti-mindset-gratitude-title": {
        "en-us": "Gratitude",
        "es-mx": "Gratitud",
    },
    "crack-jared-borgetti-mindset-gratitude-desc": {
        "en-us": "“Gratitude makes what you have enough and even abundant”.",
        "es-mx":
            "Vivir con gratitud es vivir con felicidad: “la gratitud convierte lo que tienes en suficiente, y hasta en abundante”, explica el crack que creció jugando en canchas de tierra.",
    },
    "crack-jared-borgetti-mindset-gratitude-step-1": {
        "en-us":
            "Take a few minutes each day to be grateful for what you have, the everyday and the extraordinary.",
        "es-mx":
            "Toma unos minutos cada día para agradecer lo que tienes, lo cotidiano y lo extraordinario.",
    },
    "crack-jared-borgetti-mindset-gratitude-step-2": {
        "en-us": "Look for the positive side of each adverse situation.",
        "es-mx": "Busca el lado positivo de cada situación adversa.",
    },
    "crack-jared-borgetti-mindset-gratitude-step-3": {
        "en-us": "Cultivate humility.",
        "es-mx": "Cultiva la humildad.",
    },
    "crack-jared-borgetti-mindset-gratitude-step-4": {
        "en-us": "Be generous, with yourself and with others.",
        "es-mx": "Sé generoso, contigo y con los demás.",
    },
    "crack-jared-borgetti-mindset-overcoming-title": {
        "en-us": "Overcoming",
        "es-mx": "Superación",
    },
    "crack-jared-borgetti-mindset-overcoming-desc": {
        "en-us": "“My advice: strive not only to be successful, but to be an admirable person”.",
        "es-mx":
            "Sus goles quedarán en nuestros recuerdos para siempre, pero Jared prefiere ser recordado por sus dones de gente: “me esforcé no solo para ser exitoso, sino que también para ser una persona admirable”.",
    },
    "crack-jared-borgetti-mindset-overcoming-step-1": {
        "en-us": "Face your weaknesses, so they don't stop you from growing and progressing.",
        "es-mx": "Enfrenta tus debilidades: no dejes que te impidan progresar.",
    },
    "crack-jared-borgetti-mindset-overcoming-step-2": {
        "en-us": "Stand out with your special qualities, those that makes you unique.",
        "es-mx": "Destaca desde tus cualidades, a partir de aquello que te hace único.",
    },
    "crack-jared-borgetti-mindset-overcoming-step-3": {
        "en-us": "Learn from your successes, but mostly from your failures.",
        "es-mx": "Aprende de tus éxitos y tus fracasos.",
    },
    "crack-jared-borgetti-mindset-overcoming-step-4": {
        "en-us": "Seek feedback from the experts.",
        "es-mx": "Busca retroalimentación de los expertos.",
    },
    "crack-jared-borgetti-mindset-persistence-title": {
        "en-us": "Persistence",
        "es-mx": "Persistencia",
    },
    "crack-jared-borgetti-mindset-persistence-desc": {
        "en-us": "“Don't be afraid of failure: be afraid of not trying”.",
        "es-mx":
            "Para Borgetti, el peor fracaso no es perder, o no tener éxito... sino no intentarlo: “no le tengas miedo al fracaso, sino a no intentarlo”, recomienda el goleador.",
    },
    "crack-jared-borgetti-mindset-persistence-step-1": {
        "en-us": "Value your goals, but even more the process to achieve them.",
        "es-mx": "Valora tus metas, pero aún más el proceso para alcanzarlas.",
    },
    "crack-jared-borgetti-mindset-persistence-step-2": {
        "en-us": "Keep an optimistic attitude: the effort is part of the reward.",
        "es-mx": "Mantén una actitud optimista: el esfuerzo es parte de la recompensa.",
    },
    "crack-jared-borgetti-mindset-persistence-step-3": {
        "en-us":
            "Evaluate your strategy: it's not enough to repeat others formula's to obtain results.",
        "es-mx": "Evalúa tu estrategia, no basta con repetir para obtener resultados.",
    },
    "crack-jared-borgetti-mindset-persistence-step-4": {
        "en-us": "Don't give up! If you are tired, rest and try again",
        "es-mx": "No te rindas, descansa e inténtalo de nuevo.",
    },
    "crack-jared-borgetti-skills-header-title": {
        "en-us": "Header",
        "es-mx": "Cabezazo",
    },
    "crack-jared-borgetti-skills-header-desc": {
        "en-us":
            "You will learn how to head the goal in the best possible way and you will receive advice on the different types of heading.and you will remember one of the best goals in World Cups.",
        "es-mx":
            "Aprenderás como rematar de cabeza a portería de la mejor manera posible y  recibirás consejos sobre los distintos tipos de cabeceo y recordarás uno de los mejores goles en mundiales.",
    },
    "crack-jared-borgetti-skills-scorer-title": {
        "en-us": "Scorer",
        "es-mx": "Olfato Goleador",
    },
    "crack-jared-borgetti-skills-scorer-desc": {
        "en-us": "A player must know himself, what are his qualities and his best virtues.",
        "es-mx": "Un jugador debe de conocerse, cuales son sus cualidades y sus mejores virtudes.",
    },
    "crack-jared-borgetti-skills-art-forward-title": {
        "en-us": "The art of being forward",
        "es-mx": "El arte de ser delantero",
    },
    "crack-jared-borgetti-skills-art-forward-desc": {
        "en-us": "To be a great striker you have to see, analyze and practice all your moves.",
        "es-mx":
            "Para ser un gran delantero tienes que ver,analizar y practicar todas tus jugadas.",
    },
    "crack-jared-borgetti-stories-1-title": {
        "en-us": "I entered the field to do what I knew",
        "es-mx": "Entré a la cancha a hacer lo que sabía",
    },
    "crack-jared-borgetti-stories-1-desc": {
        "en-us":
            "“When the first opportunity to become a professional player was there, I entered the field with the simple objective of do what I always did”.",
        "es-mx":
            "“Cuando llegó la oportunidad de hacer una prueba para ser jugador profesional, entré a la cancha a hacer lo que hacía siempre: jugar al fútbol con mi estilo”.",
    },
    "crack-jared-borgetti-stories-2-title": {
        "en-us": "You don't have to be ashamed because you couldn't reach it",
        "es-mx": "No te tiene que dar vergüenza porque no te alcanzó",
    },
    "crack-jared-borgetti-stories-2-desc": {
        "en-us":
            "Jared's beginnings in football were not easy. With few minutes left and outside of the coach's plans, he understood that the only option was to give his best...so he couldn't blame himself for anything.",
        "es-mx":
            "Jugando en Atlas, llegó un momento en el que ya no era tomado en cuenta por el DT. Lejos de resignarse, Borgetti entendió que tenía que encontrar un diferenciador... y seguir dejándolo todo en cada entrenamiento.",
    },
    "crack-jared-borgetti-stories-3-title": {
        "en-us": "A striker has to do which they least expect",
        "es-mx": "Un delantero tiene que hacer lo que menos esperan",
    },
    "crack-jared-borgetti-stories-3-desc": {
        "en-us":
            "Borgetti is sure that the differential of a good striker is the surprise factor... try the unexpected. Why? Find out in this video.",
        "es-mx":
            "¿Cómo ser letal en el área, y en la vida? “Haz algo diferente a lo que los otros harían”, recomienda Borgetti.",
    },
    "crack-jared-borgetti-stories-4-title": {
        "en-us": "The end of my career",
        "es-mx": "El final de mi carrera como futbolista",
    },
    "crack-jared-borgetti-stories-4-desc": {
        "en-us":
            "Jared got his first opportunity in Europe when he least expected it. A perfect closure for his career? Not at all: he returned to Mexico to break records before retiring.",
        "es-mx":
            "Cuando menos se lo esperaba, Borgetti recibe un llamado para jugar en Europa. Su paso por la Premier sería el inicio del fin de su inolvidable carrera.",
    },
    "crack-norma-palafox-mindset-tenacity-title": {
        "en-us": "Tenacity",
        "es-mx": "Tenacidad",
    },
    "crack-norma-palafox-mindset-tenacity-desc": {
        "en-us":
            "“I was ready to play mi first final, in a professional stadium...but I made a mistake in the previous game, and the coach sat me down on the bench and I missed my dreamed game. But time passes and always gives revenge”",
        "es-mx":
            "Cuando le llegó la hora de jugar su primera gran final, a Norma la “percharon” en la banca. Lejos de rendirse, siguió trabajando con decisión y tenacidad....y terminó jugando, 4 años después, su final soñada.",
    },
    "crack-norma-palafox-mindset-tenacity-step-1": {
        "en-us": "Don't live with the fear of failure! And if you fail...learn from it.",
        "es-mx": "No tengas miedo al fracaso, aprende de él.",
    },
    "crack-norma-palafox-mindset-tenacity-step-2": {
        "en-us": "Use each day as an opportunity to improve.",
        "es-mx": "Usa cada día como una oportunidad para mejorar.",
    },
    "crack-norma-palafox-mindset-tenacity-step-3": {
        "en-us": "Keep your goals clear and don't give up.",
        "es-mx": "Mantén tus objetivos claros.",
    },
    "crack-norma-palafox-mindset-tenacity-step-4": {
        "en-us": "Be patient and persistent.",
        "es-mx": "Sé paciente y persistente.",
    },
    "crack-norma-palafox-mindset-confidence-title": {
        "en-us": "Confidence",
        "es-mx": "Confianza",
    },
    "crack-norma-palafox-mindset-confidence-desc": {
        "en-us":
            "My mom never doubted me. I recognized that I gave extra and that she trusted me... there were times that I wanted to return home because I couldn't handle the stress and pressure. I didn't understand soccer because I came from my ranch and didn't know anything about soccer. To my bags to go back to my house. But it came to mind: I have someone who believes in me, who hasn't doubted for a second in life that his daughter can become something... that pushed me to be better.",
        "es-mx":
            "“Mi mamá nunca dudó de mí. Aunque a veces quería que regresara por el estrés y la presión, nunca dejó de confiar en mi....y eso me impulsó a mejorar”.",
    },
    "crack-norma-palafox-mindset-confidence-step-1": {
        "en-us": "Do not lose sight of your dreams.",
        "es-mx": "No pierdas de vista tus sueños.",
    },
    "crack-norma-palafox-mindset-confidence-step-2": {
        "en-us": "Act with commitment and dedication.",
        "es-mx": "Actúa con compromiso y dedicación.",
    },
    "crack-norma-palafox-mindset-confidence-step-3": {
        "en-us": "Remember your achievements when undertaking new challenges.",
        "es-mx": "Recuerda tus logros al emprender nuevos retos.",
    },
    "crack-norma-palafox-mindset-confidence-step-4": {
        "en-us": "Look for inspiration in everything that motivates you.",
        "es-mx": "Busca inspiración en todo aquello que te motiva.",
    },
    "crack-norma-palafox-mindset-hope-title": {
        "en-us": "Hope",
        "es-mx": "Esperanza",
    },
    "crack-norma-palafox-mindset-hope-desc": {
        "en-us":
            "Hope is the only thing more powerful than fear… My life changed from one day to the next when I became champion.",
        "es-mx":
            "El miedo es inherente a la condición humana, y puede bloquearnos. Para derrotarlo, Norma encontró una fórmula infalible: la esperanza.",
    },
    "crack-norma-palafox-mindset-hope-step-1": {
        "en-us": "Look for the positive side of a challenging situation: What can you learn?.",
        "es-mx":
            "Busca el lado positivo de la próxima situación que te rete: ¿qué podrías aprender de ella?",
    },
    "crack-norma-palafox-mindset-hope-step-2": {
        "en-us": "Trust your abilities, talents and resources.",
        "es-mx": "Confía en tus capacidades, talentos y recursos.",
    },
    "crack-norma-palafox-mindset-hope-step-3": {
        "en-us": "If something fails, identify the next alternative.",
        "es-mx": "Si algo falla, identifica la siguiente alternativa.",
    },
    "crack-norma-palafox-mindset-hope-step-4": {
        "en-us": "Learn to ask for help.",
        "es-mx": "Aprende a pedir ayuda.",
    },
    "crack-norma-palafox-mindset-decision-taking-title": {
        "en-us": "Decision making",
        "es-mx": "Toma de decisiones",
    },
    "crack-norma-palafox-mindset-decision-taking-desc": {
        "en-us":
            "Life is an adventure that challenges you. Some harder, but life puts them on you to see how you get up, how you continue, what you learn. Life is so.",
        "es-mx":
            "La vida es una aventura que te pone retos y obliga a tomar decisiones de forma constante. Tomar decisiones puede ser difícil y dar mucho miedo… pero siempre lleva al crecimiento y deja aprendizajes.",
    },
    "crack-norma-palafox-mindset-decision-taking-step-1": {
        "en-us": "Pay full attention to your context.",
        "es-mx": "Presta atención plena a tu contexto.",
    },
    "crack-norma-palafox-mindset-decision-taking-step-2": {
        "en-us": "Evaluate the consequences before acting.",
        "es-mx": "Evalúa las consecuencias antes de actuar.",
    },
    "crack-norma-palafox-mindset-decision-taking-step-3": {
        "en-us": "Take risks responsibly.",
        "es-mx": "Toma riesgos con responsabilidad.",
    },
    "crack-norma-palafox-mindset-decision-taking-step-4": {
        "en-us": "Don't make important decisions if you're angry, sad, or emotional.",
        "es-mx": "No tomes decisiones importantes si estás furioso, triste o exaltado.",
    },
    "crack-norma-palafox-skills-speed-title": {
        "en-us": "Speed",
        "es-mx": "Velocidad",
    },
    "crack-norma-palafox-skills-speed-desc": {
        "en-us": "Speed is a very important resource because it can help you in several aspects.",
        "es-mx":
            "La velocidad es un recurso muy importante porque te puede ayudar en varios aspectos.",
    },
    "crack-norma-palafox-skills-dribbling-title": {
        "en-us": "Dribbling",
        "es-mx": "Regate",
    },
    "crack-norma-palafox-skills-dribbling-desc": {
        "en-us": "You will learn how to get the most out of your dribbling.",
        "es-mx": "Aprenderás como sacarle el mayor provecho a tu regate.",
    },
    "crack-norma-palafox-charlyn-corral-skills-walls-title": {
        "en-us": "Walls with Charlyn Corral",
        "es-mx": "Paredes con Charlyn Corral",
    },
    "crack-norma-palafox-charlyn-corral-skills-walls-desc": {
        "en-us": "You will learn to make a wall to leave your opponents behind.",
        "es-mx": "Aprenderás a hacer una pared para dejar atrás a tus contrincantes.",
    },
    "crack-norma-palafox-stories-1-title": {
        "en-us": "What am I doing here?",
        "es-mx": "¿Qué estoy haciendo aquí?",
    },
    "crack-norma-palafox-stories-1-desc": {
        "en-us":
            "When you start realizing that being a professional player implies starting a new life and getting out of your comfort zone, the first thing you ask yourself is: is it really worth it?",
        "es-mx":
            "“Cuando te empieza a caer el 20 de que ser jugadora profesional implica iniciar una nueva vida, empiezas a cuestionarte si todo ese esfuerzo vale la pena...y la respuesta es que sí”.",
    },
    "crack-norma-palafox-stories-2-title": {
        "en-us": "Are you a Chiva...or a TV Star?",
        "es-mx": "¿Eres Chiva... o estrella de TV?",
    },
    "crack-norma-palafox-stories-2-desc": {
        "en-us":
            "After becoming Mexican champion with Chivas, Norma accepts an invitation to participate in a popular reality show. Although her exposure grows, critics and judgements does not take long to appear...",
        "es-mx":
            "Tras ser campeona con las Chivas, Norma acepta una invitación para participar en un popular reality. Aunque su exposición crece, las críticas no tardan en aparecer...",
    },
    "crack-carlos-salcido-mindset-inspiration-title": {
        "en-us": "Inspiration",
        "es-mx": "Inspiración",
    },
    "crack-carlos-salcido-mindset-inspiration-desc": {
        "en-us": "“My mental strength has always been to work to support my family”.",
        "es-mx": "“Mi fortaleza mental siempre fue el deseo de sacar adelante a mi familia”.",
    },
    "crack-carlos-salcido-mindset-inspiration-step-1": {
        "en-us": "Recognize what motivates you and guide your actions.",
        "es-mx": "Reconoce aquello que te motiva y guía tus acciones.",
    },
    "crack-carlos-salcido-mindset-inspiration-step-2": {
        "en-us": "Value your emotional environment, the people with whom you share your life.",
        "es-mx": "Valora tu entorno afectivo, a las personas con quienes compartes tu vida.",
    },
    "crack-carlos-salcido-mindset-inspiration-step-3": {
        "en-us": "Look for new experiences that give meaning and purpose to your life.",
        "es-mx": "Busca nuevas experiencias que den sentido y propósito a tu vida.",
    },
    "crack-carlos-salcido-mindset-inspiration-step-4": {
        "en-us": "Avoid unnecessary noise in your life and cultivate mindfulness.",
        "es-mx": "Evita el ruido innecesario y cultiva la atención plena, todos los días.",
    },
    "crack-carlos-salcido-mindset-self-esteem-title": {
        "en-us": "Self-esteem",
        "es-mx": "Autoestima",
    },
    "crack-carlos-salcido-mindset-self-esteem-desc": {
        "en-us":
            "“There is no age to start playing football: I only started playing again when I was 16, after 5 years of not touching a ball”.",
        "es-mx":
            "“Es mentira eso de que si no juegas al fútbol desde los 4 años ya no será futbolista: yo recién volví a jugar a los 16, tras 5 años sin tocar un balón”.",
    },
    "crack-carlos-salcido-mindset-self-esteem-step-1": {
        "en-us": "Know yourself: what things do you like about yourself?",
        "es-mx": "Conócete a ti mismo: ¿Qué te gusta de ti?",
    },
    "crack-carlos-salcido-mindset-self-esteem-step-2": {
        "en-us": "Recognize and value your qualities.",
        "es-mx": "Reconoce y valora tus cualidades.",
    },
    "crack-carlos-salcido-mindset-self-esteem-step-3": {
        "en-us": "Trust in your abilities: no one knows your virtues better than you.",
        "es-mx": "Confía en tus capacidades, en aquello que sabes hacer muy bien.",
    },
    "crack-carlos-salcido-mindset-self-esteem-step-4": {
        "en-us": "Don't try to be someone else, and instead be the best version of yourself.",
        "es-mx": "No aspires a ser alguien más, sé la mejor versión de ti mismo.",
    },
    "crack-carlos-salcido-mindset-resilience-title": {
        "en-us": "Resilience",
        "es-mx": "Resiliencia",
    },
    "crack-carlos-salcido-mindset-resilience-desc": {
        "en-us":
            "“Something I've learned is that words can be very hurtful: they can drive a young a person or finish him off. When they told me you are not good for soccer, I made everything to prove they were wrong”.",
        "es-mx":
            "“Algo que he aprendido es que las palabras pueden ser muy hirientes, pueden impulsar a un jóven o acabarlo”. Cuando te dicen que no sirves para algo, ¿cómo reaccionas? Salcido eligió usar esas palabras como motor de trabajo y motivación para demostrarles que estaban equivocados.",
    },
    "crack-carlos-salcido-mindset-resilience-step-1": {
        "en-us": "Do not let fear dominate you, and try to control it.",
        "es-mx": "No dejes que te domine el miedo, aprende a controlarlo.",
    },
    "crack-carlos-salcido-mindset-resilience-step-2": {
        "en-us": "Make every challenge a learning opportunity.",
        "es-mx": "Haz de cada reto una oportunidad de aprendizaje.",
    },
    "crack-carlos-salcido-mindset-resilience-step-3": {
        "en-us": "Don't make important decisions if you're angry, sad, or emotional.",
        "es-mx": "No pierdas de vista que todo pasa, mantén la esperanza.",
    },
    "crack-carlos-salcido-mindset-resilience-step-4": {
        "en-us": "Cultivate supportive and reciprocal relationships.",
        "es-mx": "Cultiva relaciones de apoyo recíroco.",
    },
    "crack-carlos-salcido-mindset-perseverance-title": {
        "en-us": "Perseverance",
        "es-mx": "Perseverancia",
    },
    "crack-carlos-salcido-mindset-perseverance-desc": {
        "en-us":
            "“I enjoyed every tournament I won, but the day after I was ready to resume training, because I always knew I can achieve even more”.",
        "es-mx":
            "“Si quedaba campeón, claro que lo disfrutaba...pero al otro día ya estaba entrenando, porque sabía que podría lograr aún más”.",
    },
    "crack-carlos-salcido-mindset-perseverance-step-1": {
        "en-us": "Honor and enjoy the effort.",
        "es-mx": "Honra y disfruta del esfuerzo.",
    },
    "crack-carlos-salcido-mindset-perseverance-step-2": {
        "en-us": "Accept failures and learn from them.",
        "es-mx": "Acepta los fracasos y aprende de ellos.",
    },
    "crack-carlos-salcido-mindset-perseverance-step-3": {
        "en-us": "Do not lose sight of your goal.",
        "es-mx": "No pierdas de vista tu objetivo.",
    },
    "crack-carlos-salcido-mindset-perseverance-step-4": {
        "en-us": "Appreciate and celebrate your achievements: every step counts!",
        "es-mx": "Agradece y celebra tus logros, pero sigue mirando hacia adelante, siempre.",
    },
    "crack-carlos-salcido-skills-outline-title": {
        "en-us": "Leg coordination and game reading",
        "es-mx": "Perfiles: Coordinación de piernas y lectura de juego",
    },
    "crack-carlos-salcido-skills-outline-desc": {
        "en-us":
            "A defender must be quick in thinking and in reading the game as this gives him agility and an advantage over his opponents.",
        "es-mx":
            "Un defensa tiene que ser veloz en el pensamiento y en la lectura del juego ya que esto le da agilidad y ventaja sobre sus adversarios.",
    },
    "crack-carlos-salcido-skills-approach-speed-title": {
        "en-us": "Approach speed",
        "es-mx": "Velocidad de aproximación",
    },
    "crack-carlos-salcido-skills-approach-speed-desc": {
        "en-us":
            "You will learn the best tips and techniques to prevent your opponent from passing you and creating danger.",
        "es-mx":
            "Aprenderás los mejores consejos y técnicas para evitar que tu contrincante te rebase y genere peligro.",
    },
    "crack-carlos-salcido-skills-blocking-title": {
        "en-us": "Blocking",
        "es-mx": "Bloqueo",
    },
    "crack-carlos-salcido-skills-blocking-desc": {
        "en-us":
            "You will learn how to block the attacks of the rival such as centers and dribbles.",
        "es-mx": "Aprenderás como bloquear los embates del rival como lo son centros y regates.",
    },
    "crack-carlos-salcido-stories-1-title": {
        "en-us": "I tried 3 times...and always failed",
        "es-mx": "Lo intenté 3 veces...y no lo logré ninguna",
    },
    "crack-carlos-salcido-stories-1-desc": {
        "en-us":
            "After overcoming a difficult childhood, Carlos tried to follow his brothers and crossed into the United States. All 3 times he failed... not knowing that this would end up being good news.",
        "es-mx":
            "Una difícil realidad económica llevó a Carlos -como a muchos hermanos y amigos- a intentar cruzar tres veces a los EE.UU. Las tres veces fracasó en el intento… sin saber que, al cerrarse esa puerta, el destino le estaría por ofrecer la oportunidad de su vida.",
    },
    "crack-carlos-salcido-stories-2-title": {
        "en-us": "The last chance",
        "es-mx": "Volver a Primera a los 23",
    },
    "crack-carlos-salcido-stories-2-desc": {
        "en-us":
            "“From one day to another, I started sharing the dressing room with guys that I was used to see on the games. After playing only 20 minutes, they didn't call me up again, and I realized on how many things I have to improve. I only started playing again at the age of 23, but it worth the wait”.",
        "es-mx":
            "Pasó del sueño de compartir vestuario con sus ídolos y debutar en primera división a ser relegado por completo. Lejos de rendirse, Carlos se mentalizó para que, cuando le tocara jugar, no vuelvan a sacarlo nunca más.",
    },
    "crack-carlos-salcido-stories-3-title": {
        "en-us": "The National Team call",
        "es-mx": "Seleccionado Nacional",
    },
    "crack-carlos-salcido-stories-3-desc": {
        "en-us":
            "“When they called me from Chivas and said 'tomorrow you have to join the National Team training'...I just couldn't believe it”.",
        "es-mx":
            "El llamado más importante de tu vida puede llegar en el momento menos pensado. Carlos Salcido recuerda su reacción y las sensaciones de ese imborrable momento.",
    },
    "crack-carlos-salcido-stories-4-title": {
        "en-us": "I left soccer, instead of football leaving me",
        "es-mx": "Yo fui el que dejó al fútbol, el fútbol no me dejó a mí",
    },
    "crack-carlos-salcido-stories-4-desc": {
        "en-us":
            "After a memorable 18-years career, in which he had the chance to play in the main fields and tournaments in the world, Carlos decided to quite soccer before soccer quite to him.",
        "es-mx":
            "Tras 18 temporadas como profesional y cientos de partidos en el más alto nivel del fútbol Mundial, Salcido elige retirarse por la puerta grande.",
    },
    "crack-oswaldo-sanchez-mindset-will-title": {
        "en-us": "Will",
        "es-mx": "Voluntad",
    },
    "crack-oswaldo-sanchez-mindset-will-desc": {
        "en-us":
            "They key to make the most of your will is to get “not the will to win, but the will to prepare to win”, explain one of the best Mexican goalkeepers ever.",
        "es-mx":
            "Trabajar tu voluntad es trabajar tu potencial de hacer grandes cosas: “no es la voluntad de ganar, sino la voluntad de prepararse para ganar lo que marca la diferencia”, sentencia Oswaldo.",
    },
    "crack-oswaldo-sanchez-mindset-will-step-1": {
        "en-us": "Set clear goals.",
        "es-mx": "Ponte objetivos claros.",
    },
    "crack-oswaldo-sanchez-mindset-will-step-2": {
        "en-us": "Prioritize your commitments and responsibilities.",
        "es-mx": "Prioriza tus compromisos y responsabilidades.",
    },
    "crack-oswaldo-sanchez-mindset-will-step-3": {
        "en-us": "Divide your big goals into phases, steps or small goals.",
        "es-mx": "Divide tus grandes objetivos en fases, pasos o metas pequeñas.",
    },
    "crack-oswaldo-sanchez-mindset-will-step-4": {
        "en-us": "Appreciate and recognize every achievement.",
        "es-mx": "Aprecia y reconoce cada logro.",
    },
    "crack-oswaldo-sanchez-mindset-flexibility-title": {
        "en-us": "Flexibility",
        "es-mx": "Flexibilidad",
    },
    "crack-oswaldo-sanchez-mindset-flexibility-desc": {
        "en-us": "Flexibility is the basis of stability.",
        "es-mx":
            "Aunque suene paradójico, ser flexible es la clave para mantenerte estable. Aprende de “San Oswaldo” cómo surfear las olas que traen cambios... en lugar de dejar que te ahoguen.",
    },
    "crack-oswaldo-sanchez-mindset-flexibility-step-1": {
        "en-us": "ccept that you can't control everything.",
        "es-mx": "Acepta que no todo lo puedes controlar.",
    },
    "crack-oswaldo-sanchez-mindset-flexibility-step-2": {
        "en-us": "Open your mind to the new, to the unknown.",
        "es-mx": "Abre tu mente a lo nuevo, a lo desconocido.",
    },
    "crack-oswaldo-sanchez-mindset-flexibility-step-3": {
        "en-us": "Take perspective, go beyond the obvious.",
        "es-mx": "Toma perspectiva, ve más allá de lo evidente.",
    },
    "crack-oswaldo-sanchez-mindset-flexibility-step-4": {
        "en-us": "Take risks.",
        "es-mx": "Toma riesgos con responsabilidad.",
    },
    "crack-oswaldo-sanchez-mindset-loss-management-title": {
        "en-us": "Loss management",
        "es-mx": "Gestión de pérdida",
    },
    "crack-oswaldo-sanchez-mindset-loss-management-desc": {
        "en-us":
            "Knowing how to manage losses will allow you to better handle duels, look at the future with optimism, and learn about the true value of things.",
        "es-mx":
            "Saber gestionar las pérdidas te permitirá manejar mejor los duelos, mirar el futuro con optimismo y aprender sobre el verdadero valor de las cosas.",
    },
    "crack-oswaldo-sanchez-mindset-loss-management-step-1": {
        "en-us": "Accept without judgment your emotions in the face of loss.",
        "es-mx": "Acepta sin juzgar tus emociones ante la pérdida.",
    },
    "crack-oswaldo-sanchez-mindset-loss-management-step-2": {
        "en-us": "Learn to release sadness through crying, physical activation or dialogue.",
        "es-mx":
            "Aprende a liberar la tristeza a través del llanto, la activación física o el diálogo.",
    },
    "crack-oswaldo-sanchez-mindset-loss-management-step-3": {
        "en-us": "Focus your attention on the present, do not get carried away by the past.",
        "es-mx": "Concentra tu atención en el presente, no te dejes llevar por el pasado.",
    },
    "crack-oswaldo-sanchez-mindset-loss-management-step-4": {
        "en-us": "Transform losses into learning for the future.",
        "es-mx": "Transforma las pérdidas en aprendizajes para el futuro.",
    },
    "crack-oswaldo-sanchez-mindset-concentration-title": {
        "en-us": "Concentration",
        "es-mx": "Concentración",
    },
    "crack-oswaldo-sanchez-mindset-concentration-desc": {
        "en-us":
            "The success of our actions depends on our level of concentration. Learn how to put your 100% focus on the key moments with “San Oswaldo”.",
        "es-mx":
            "La concentración es clave para cualquier ámbito de la vida, e imprescindible para el éxito en la portería. Oswaldo Sánchez nos comparte sus secretos para lograr la máxima concentración en cada partido.",
    },
    "crack-oswaldo-sanchez-mindset-concentration-step-1": {
        "en-us": "Exercise focused and mindful attention.",
        "es-mx": "Ejercita la atención focalizada y consciente.",
    },
    "crack-oswaldo-sanchez-mindset-concentration-step-2": {
        "en-us": "Prioritize your tasks and distribute the effort.",
        "es-mx": "Prioriza tus tareas y distribuye el esfuerzo.",
    },
    "crack-oswaldo-sanchez-mindset-concentration-step-3": {
        "en-us": "Give yourself time to work, but also to rest.",
        "es-mx": "Date tiempo para trabajar, pero también para descansar.",
    },
    "crack-oswaldo-sanchez-mindset-concentration-step-4": {
        "en-us": "Eliminate distractions, limit your time on the cell phone.",
        "es-mx":
            "Elimina las distracciones, limita tu tiempo en el celular y enfócate en lo que realmente importa.",
    },
    "crack-oswaldo-sanchez-stories-1-title": {
        "en-us": "My dream of become a professional player",
        "es-mx": "“Solo soñaba con ser futbolista profesional”",
    },
    "crack-oswaldo-sanchez-stories-1-desc": {
        "en-us":
            "“I know I love soccer since I was on my mom's belly: she told me that I kicked her a lot...and I never stop playing since the first time I kicked a ball”.",
        "es-mx":
            "“Sé que mi primer contacto con el fútbol fue ya en la panza de mi madre: me cuenta que la vivía pateando, molestando....creo que siempre quise ser futbolista”.",
    },
    "crack-oswaldo-sanchez-stories-2-title": {
        "en-us": "“I like to dream...and dreams can always come true”",
        "es-mx": "Me gustaba soñar...y los sueños se vuelven realidad",
    },
    "crack-oswaldo-sanchez-stories-2-desc": {
        "en-us":
            "“Once, playing soccer at home, I broke a vase. My mother looked at me seriously and I told her don't worry...when I'm a professional I'll buy you a whole house”.",
        "es-mx":
            "Jugando al fútbol de chiquito le rompió un jarrón a su madre: “La miré y le dije tranquila, mamá...cuando sea grande no te voy a comprar un jarrón, sino una casa entera”, cuenta San Oswaldo, que no tardó en cumplir su promesa.",
    },
    "crack-oswaldo-sanchez-stories-3-title": {
        "en-us": "“Don't let anything take away your dream of playing in a world cup”",
        "es-mx": "“No dejes que nada te quite tu sueño de jugar un mundial”",
    },
    "crack-oswaldo-sanchez-stories-3-desc": {
        "en-us":
            "“Don't let anything or anyone take away your dream of playing in a World Cup”, Oswaldo's dad told him once. With this support, Oswaldo kept growing personally and professionally, until his biggest dream of playing in the WC came true.",
        "es-mx":
            "Cuando un padre apoya y empodera a sus hijos, les hace la vida más fácil. Oswaldo Sánchez creció con la fortuna de tener un padre que lo apoyó y estimuló para perseguir sus sueños.",
    },
    "crack-oswaldo-sanchez-stories-4-title": {
        "en-us": "“I'd liked to achieve something big with the National Team”",
        "es-mx": "“Me hubiera gustado hacer algo grande con la selección”",
    },
    "crack-oswaldo-sanchez-stories-4-desc": {
        "en-us":
            "“There was not a single training session, not a single minute, in which I did not give everything for my career. I always gave my 100%... and so I have nothing left to reproach myself”.",
        "es-mx":
            "Oswaldo se quedó con la espina de no haber logrado más con el Tri, aunque duerme tranquilo porque siempre lo dejó todo: “no hubo un solo  día, un solo entrenamiento o un solo minuto en el que no lo haya dejado todo por mi profesión”, confiesa.",
    },
    "crack-jo-canales-fitness-break-title": {
        "en-us": "Break",
        "es-mx": "Descanso",
    },
    "crack-jo-canales-fitness-pre-game-activation-title": {
        "en-us": "Pre game activation",
        "es-mx": "Activación pre cancha",
    },
    "crack-jo-canales-fitness-pre-game-activation-desc": {
        "en-us":
            "These exercises are very useful when it comes to warming up before any training session or game.",
        "es-mx":
            "Estos ejercicios son muy útiles para entrar en calor antes de cualquier entrenamiento o partido.",
    },
    "crack-jo-canales-fitness-cool-down-title": {
        "en-us": "Cool down",
        "es-mx": "Enfriamiento",
    },
    "crack-jo-canales-fitness-cool-down-desc": {
        "en-us":
            "When you finish training, recover and relax your body with these simple exercises!",
        "es-mx": "Cuando termines de entrenar, recúperate y relaja el cuerpo con estos ejercicios.",
    },
    "crack-jo-canales-fitness-warm-up-title": {
        "en-us": "Warm Up",
        "es-mx": "Calentamiento",
    },
    "crack-jo-canales-fitness-warm-up-desc": {
        "en-us":
            "These exercises will help you as a general warm-up for any workout you want to do.",
        "es-mx":
            "Estos ejercicios te ayudarán como un calentamiento general para cualquier entrenamiento que quieras realizar.",
    },
    "crack-jo-canales-fitness-warm-up-material": {
        "en-us": "Yoga mat (optional).",
        "es-mx": "Mat de yoga (opcional).",
    },
    "crack-jo-canales-fitness-3x3-shuttle-run-title": {
        "en-us": "3x3 Shuttle Run",
        "es-mx": "3x3 Shuttle Run",
    },
    "crack-jo-canales-fitness-3x3-shuttle-run-desc": {
        "en-us":
            "Mark a 3-meter line, do a sprint and when you reach the end, brake with the leg that is inside to make a support, change direction with the leg that is outside, make sure to change your profile to the other side of the line.",
        "es-mx":
            "Marca una línea de 3 metros, haz un sprint y al llegar al final frena con la pierna que queda dentro para hacer un apoyo, cambia de dirección con la pierna que queda afuera, asegúrate de cambiar de perfil al otro lado de la línea.",
    },
    "crack-jo-canales-fitness-ball-taps-title": {
        "en-us": "Ball Taps",
        "es-mx": "Ball Taps",
    },
    "crack-jo-canales-fitness-ball-taps-desc": {
        "en-us":
            "Brace in a coordinated way with the movement of the legs, stay on your toes alternating the support between the ball and the floor.",
        "es-mx":
            "Bracea de manera coordinada con el movimiento de las piernas, mantente en puntas de pies alternando el apoyo entre el balón y el piso.",
    },
    "crack-jo-canales-fitness-ball-twist-title": {
        "en-us": "Ball Twist",
        "es-mx": "Ball Twist",
    },
    "crack-jo-canales-fitness-ball-twist-desc": {
        "en-us":
            "Mantén las piernas semiflexionadas en el aire, toma el balón y toca el piso a tus costados con él, asegurate de rotar el torso cuando toques el piso y que las piernas no se muevan en ningún momento.",
        "es-mx":
            "Keep your legs semi-flexed in the air, take the ball and touch the floor to your sides with it, make sure to rotate your torso when you touch the floor and that your legs do not move at any time.",
    },
    "crack-jo-canales-fitness-banded-pulls-title": {
        "en-us": "Banded Pull",
        "es-mx": "Banded Pull",
    },
    "crack-jo-canales-fitness-banded-pulls-desc": {
        "en-us":
            "Extend arms fully at end of movement, pull back with elbows bent at 45 degrees, keep back straight.",
        "es-mx":
            "Extiende los brazos por completo al final del movimiento, jala hacia atrás con los codos inclinados a 45 grados, mantén la espalda recta.",
    },
    "crack-jo-canales-fitness-banded-pulls-material": {
        "en-us": "TRX or similar.",
        "es-mx": "TRX o similares.",
    },
    "crack-jo-canales-fitness-bicep-curls-db-title": {
        "en-us": "DB bicep curls",
        "es-mx": "DB bicep curls",
    },
    "crack-jo-canales-fitness-bicep-curls-db-desc": {
        "en-us":
            "This exercise will allow you to tone the most striking muscles of the arm. Pay special attention to your posture to avoid discomfort and possible injuries... and enjoy your train!",
        "es-mx":
            "Este ejercicio te permitrá tonificar los músculos más llamativos del brazo. Préstale especial atención a tu postura para evitar molestias y posibles lesiones...¡y a entrenar!",
    },
    "crack-jo-canales-fitness-bicep-curls-db-material": {
        "en-us": "Dumbbells.",
        "es-mx": "Mancuernas.",
    },
    "crack-jo-canales-fitness-bicep-curls-object-title": {
        "en-us": "Object Bicep Curls",
        "es-mx": "Object Bicep Curls",
    },
    "crack-jo-canales-fitness-bicep-curls-object-desc": {
        "en-us":
            "Enjoy this creative variation to train your biceps, for which you only need any object with little weight.",
        "es-mx":
            "Disfruta de esta creativa variante para entrenar tus bíceps, para la que solo precisas cualquier objeto con poco peso.",
    },
    "crack-jo-canales-fitness-bicep-curls-object-material": {
        "en-us":
            "Lightweight object (backpack with things inside, dumbbell or whatever you have available)",
        "es-mx":
            "Objeto con poco peso (mochila con cosas adentro, mancuerna o lo que tengas disponible)",
    },
    "crack-jo-canales-fitness-bicycle-abs-title": {
        "en-us": "Bicycle Abs",
        "es-mx": "Bicycle Abs",
    },
    "crack-jo-canales-fitness-bicycle-abs-desc": {
        "en-us":
            "This exercise is essential to strengthen the abdomen, and it is also an excellent way to exercise and develop different areas of the body. Since its level of difficulty is high, pay special attention to the execution technique.",
        "es-mx":
            "Este ejercicio es fundamental para fortalecer el abdomen, y también es una excelente forma de ejercitar y desarrollar distintas zonas del cuerpo. Ya que su nivel de dificultad es elevado, préstale especial atención a la técnica de ejecución.",
    },
    "crack-jo-canales-fitness-box-dips-title": {
        "en-us": "Box Dips",
        "es-mx": "Box Dips",
    },
    "crack-jo-canales-fitness-box-dips-desc": {
        "en-us":
            "The “Dips” or triceps funds are a very complete exercise, with which you will also work the abdominal and lower back parts to stabilize the trunk. Triceps dips or dips are one of the most effective exercises to increase triceps muscle mass.",
        "es-mx":
            "Los “Dips” o fondos de tríceps son un ejercicio muy completo, con el que además trabajarás la parte abdominal y las lumbares para estabilizar el tronco. Los dips o fondos de tríceps son uno de los ejercicios más efectivos para aumentar masa muscular del trícpes.",
    },
    "crack-jo-canales-fitness-box-dips-material": {
        "en-us": "Wooden box or similar.",
        "es-mx": "Cajón de madera o similares.",
    },
    "crack-jo-canales-fitness-broad-jump-title": {
        "en-us": "Broad Jump",
        "es-mx": "Broad Jump",
    },
    "crack-jo-canales-fitness-broad-jump-desc": {
        "en-us":
            "This is an exercise for people with an intermediate level of physical fitness. Start doing it to develop power, strength, and speed in your lower body muscles.",
        "es-mx":
            "El salto largo o “broad jump” es un ejercicio para personas con un nivel de aptitud física intermedio. Su objetivo es desarrollar potencia, fuerza y velocidad en los músculos del tren inferior del cuerpo.",
    },
    "crack-jo-canales-fitness-burpees-title": {
        "en-us": "Full Burpee",
        "es-mx": "Full Burpee",
    },
    "crack-jo-canales-fitness-burpees-desc": {
        "en-us":
            "Discover this complete exercise to work on your strength, endurance, and coordination, and strengthen the pectorals, triceps, shoulders, quadriceps, hamstrings, calves, abdominals, and gluteus.",
        "es-mx":
            "Descubre este completo ejercicio para trabajar en tu fuerza, resistencia y coordinación, que te hará usar la mayoría de tus músculos y fortalecer pectorales, tríceps, hombros, cuádriceps, femorales, gemelos, abdominales y glúteo.",
    },
    "crack-jo-canales-fitness-c-ups-title": {
        "en-us": "C-ups",
        "es-mx": "C-ups",
    },
    "crack-jo-canales-fitness-c-ups-desc": {
        "en-us":
            "You only need 30 seconds to perform a full set of this intense crunch variant. You dare?",
        "es-mx":
            "Solo necesitas 30 segundos para realizar una serie completa de esta intensa variante de abdominales. ¿Te animas?",
    },
    "crack-jo-canales-fitness-deadlift-title": {
        "en-us": "Deadlift",
        "es-mx": "Deadlift",
    },
    "crack-jo-canales-fitness-deadlift-desc": {
        "en-us":
            "The deadlift exercise it should be in the core of your training routine: start it today to give strength and muscle to your glutes and hamstrings, and strengthen your lower back.",
        "es-mx":
            "El ejercicio de peso muerto no puede faltar en tu rutina de entrenamiento: empiézalo hoy mismo para darle fuerza y músculo a tus glúteos y femorales y fortalecer tu espalda baja.",
    },
    "crack-jo-canales-fitness-deadlift-material": {
        "en-us": "Dumbbells",
        "es-mx": "Mancuernas",
    },
    "crack-jo-canales-fitness-floor-bench-db-title": {
        "en-us": "DB Floor Bench Press",
        "es-mx": "DB Floor Bench Press",
    },
    "crack-jo-canales-fitness-floor-bench-db-desc": {
        "en-us":
            "Use a bench, a mat, or directly the floor of your house to do this challenging exercise to work your shoulders and pectorals.",
        "es-mx":
            "Utiliza un banco, o una colchoneta o directamente el piso de tu casa para hacer este desafiante ejercicio para trabajar tus hombros y pectorales.",
    },
    "crack-jo-canales-fitness-floor-bench-db-material": {
        "en-us": "Dumbbells. Optional: Bench or similar.",
        "es-mx": "Mancuernas. Opcional: Banco o similares.",
    },
    "crack-jo-canales-fitness-floor-press-object-title": {
        "en-us": "Object Floor Press",
        "es-mx": "Object Floor Press",
    },
    "crack-jo-canales-fitness-floor-press-object-desc": {
        "en-us":
            "Use any light weight object to do this pressing movement which is used to increase upper body size, strength and performance.",
        "es-mx":
            "Utiliza cualquier objeto con poco peso para hacer este movimiento de presión que se usa para aumentar el tamaño, la fuerza y ​​el rendimiento del tren superior.",
    },
    "crack-jo-canales-fitness-floor-press-object-material": {
        "en-us":
            "Light object (backpack with things inside, dumbbell or whatever you have available), you can do the exercise on a similar bench.",
        "es-mx":
            "Objeto con poco peso (mochila con cosas adentro, mancuerna o lo que tengas disponible), puedes hacer el ejercicio sobre un banco o algo similar.",
    },
    "crack-jo-canales-fitness-flutter-kicks-title": {
        "en-us": "Flutter Kicks",
        "es-mx": "Flutter Kicks",
    },
    "crack-jo-canales-fitness-flutter-kicks-desc": {
        "en-us":
            "Train the rectus abdominis, external and internal oblique abdominals and -to a lesser extent- quadriceps and lower back with this complete exercise.",
        "es-mx":
            "Entrena el recto mayor del abdomen, abdominales oblicuos externos e internos y -en menor medida- cuádriceps y lumbares con este completo ejercicio.",
    },
    "crack-jo-canales-fitness-flutter-kicks-material": {
        "en-us": "Ball or similar.",
        "es-mx": "Balón o similares.",
    },
    "crack-jo-canales-fitness-glute-bridge-title": {
        "en-us": "Glute Bridge",
        "es-mx": "Glute Bridge",
    },
    "crack-jo-canales-fitness-glute-bridge-desc": {
        "en-us":
            "Do you want to tone your legs and buttocks? If the answer is yes, you must include the glute bridges in your training routine!",
        "es-mx":
            "¿Quieres tonificar las piernas y los glúteos? Entonces, los puentes de glúteos tienen que estar en tu rutina de entrenamientos... ¡sí o sí!",
    },
    "crack-jo-canales-fitness-glute-bridge-material": {
        "en-us": "Ball or similar.",
        "es-mx": "Balón o similares.",
    },
    "crack-jo-canales-fitness-good-mornings-title": {
        "en-us": "Good Mornings",
        "es-mx": "Good Mornings",
    },
    "crack-jo-canales-fitness-good-mornings-desc": {
        "en-us":
            "Start your days with full energy by performing this simple exercise, which works a wide variety of muscles such as gluteals, lower lumbar and femoral, core and quadriceps.",
        "es-mx":
            "Empieza tus días con toda la energía realizando este simple ejercicio, que trabaja una gran variedad de músculos como glúteos, lumbar bajo y femoral,  core y cuádriceps.",
    },
    "crack-jo-canales-fitness-half-burpees-title": {
        "en-us": "Half Burpees",
        "es-mx": "Half Burpees",
    },
    "crack-jo-canales-fitness-half-burpees-desc": {
        "en-us":
            "This exercise, essential in crossfit training, is a demanding workout that measures anaerobic resistance. Start doing it today to develop your strengthen in the abdomen, back, chest, arms and legs.",
        "es-mx":
            "El “burpee”, fundamental en el entrenamiento de crossfit, es un exigente ejercicio que mide la resistencia anaeróbica. Empieza a hacerlo hoy mismo y trabaja el abdomen, la espalda, el pecho, los brazos y las piernas.",
    },
    "crack-jo-canales-fitness-high-knees-displacement-title": {
        "en-us": "High Knees with Displacement",
        "es-mx": "High Knees con Desplazamiento",
    },
    "crack-jo-canales-fitness-high-knees-displacement-desc": {
        "en-us":
            "Braise in a coordinated way, advance by placing your feet in the middle of the cones and raising your knees as high as you can at all times.",
        "es-mx":
            "Brasear de manera coordinada, avanza apoyando los pies por en medio de los conos y elevando las rodillas lo más alto que puedas en todo momento.",
    },
    "crack-jo-canales-fitness-high-knees-displacement-material": {
        "en-us": "Cones or similar.",
        "es-mx": "Conos o similares.",
    },
    "crack-jo-canales-fitness-high-knees-title": {
        "en-us": "High Knees",
        "es-mx": "High Knees",
    },
    "crack-jo-canales-fitness-high-knees-desc": {
        "en-us":
            "Run in place exaggerating the movement of the legs to bring the knees as high as possible, coordinating the swing of the movement.",
        "es-mx":
            "Corre en tu lugar exagerando el movimiento de las piernas para llevar las rodillas lo más alto que puedas, coordina el braceo del movimiento.",
    },
    "crack-jo-canales-fitness-high-pulls-object-title": {
        "en-us": "Object High Pulls",
        "es-mx": "Object High Pulls",
    },
    "crack-jo-canales-fitness-high-pulls-object-desc": {
        "en-us":
            "This exercise is nothing more than a variant of the traditional deadlift, widely used in Crossfit. To do it, separate your legs beyond the width of your hips, and point your toes out.",
        "es-mx":
            "Este ejercicio no es más que una variante del tradicional peso muerto, muy utilizado en Crossfit. Para realizarlo, separa las piernas más allá del ancho de las caderas, y dirige las puntas de los pies hacia afuera.",
    },
    "crack-jo-canales-fitness-high-pulls-object-material": {
        "en-us":
            "Lightweight object (backpack with things inside, dumbbell or whatever you have available)",
        "es-mx":
            "Objeto con poco peso (mochila con cosas adentro, mancuerna o lo que tengas disponible)",
    },
    "crack-jo-canales-fitness-inchworm-title": {
        "en-us": "Inchworm",
        "es-mx": "Inchworm",
    },
    "crack-jo-canales-fitness-inchworm-desc": {
        "en-us":
            "Keep in mind placing your hands on the floor as close to your feet as you can and work your way up to a plank position - if you manage to execute this move, you'll have the best foundation for this challenging exercise.",
        "es-mx":
            "Ten en cuenta apoyar las manos en el piso lo más cerca que puedas de los pies y avanza hasta llegar a la posición de plancha: si logras ejecutar este movimiento, tendrás la mejor base para este retador ejercicio.",
    },
    "crack-jo-canales-fitness-inchworm-push-up-title": {
        "en-us": "Inchworm to Push Up",
        "es-mx": "Inchworm to Push Up",
    },
    "crack-jo-canales-fitness-inchworm-push-up-desc": {
        "en-us":
            "Have fun while you exercise your body with this simple and demanding exercise, for which you only need your willpower. Ready to sweat?",
        "es-mx":
            "Diviértete mientras ejercitas tu cuerpo con con este sencillo y exigente ejercicio, para el que solamente necesitas de tu fuerza de voluntad. ¿Listo para transpirar?",
    },
    "crack-jo-canales-fitness-in-out-title": {
        "en-us": "In and Out",
        "es-mx": "In and Out",
    },
    "crack-jo-canales-fitness-in-out-desc": {
        "en-us":
            "Place hands below shoulders and elbows fully straight, keep core slightly tight, round back slightly, draw navel in toward spine, push hips toward floor, hop feet together to finish as close as possible. can of the hands, jump back to the starting position.",
        "es-mx":
            "Apoya las manos abajo de los hombros y estira por completo los codos, mantén el abdomen ligeramente apretado, encorva ligeramente la espalda, lleva el ombligo hacia la columna, empuja la cadera hacia el piso, brinca con pies juntos para acabar tan cerca como puedas de las mano, brinca de regreso a la posición inicial.",
    },
    "crack-jo-canales-fitness-jumping-jacks-title": {
        "en-us": "Jumping Jacks",
        "es-mx": "Jumping Jacks",
    },
    "crack-jo-canales-fitness-jumping-jacks-desc": {
        "en-us":
            "Start with your feet together and hands close to your legs, jump separating your feet beyond hip height, at the same time join your hands above your head, with a jump return to the starting position.",
        "es-mx":
            "Inicia con los pies juntos y manos pegadas a las piernas, brinca separando los pies más allá de la altura de la cadera, al mismo tiempo junta las manos por arriba de la cabeza, con un brinco regresa a la posición inicial.",
    },
    "crack-jo-canales-fitness-jumping-lunges-title": {
        "en-us": "Jumping Lunges",
        "es-mx": "Jumping Lunges",
    },
    "crack-jo-canales-fitness-jumping-lunges-desc": {
        "en-us":
            "The front knee is always kept on the ankle, the heel of the front foot is always supported, when in a lunge position jump until fully extended and then land in the same position but with the feet alternating place, shoulders, hip and knee of the back leg form a straight vertical line.",
        "es-mx":
            "La rodilla del frente siempre se mantiene sobre el tobillo, el talón del pie de enfrente siempre está apoyado, al estar en posición de desplante brinca hasta extender por completo y luego aterriza en la misma posición pero con los pies alternando lugar, hombros, cadera y rodilla de la pierna de atrás forman una línea recta vertical.",
    },
    "crack-jo-canales-fitness-jump-squat-title": {
        "en-us": "Jump Squat",
        "es-mx": "Jump Squat",
    },
    "crack-jo-canales-fitness-jump-squat-desc": {
        "en-us":
            "Rest your heels on the floor when going down, fully extend your hips when you jump, keep your back straight at all times, when landing, support your toes first and then your heels.",
        "es-mx":
            "Apoya los talones en el piso al bajar, extiende la cadera por completo cuando brinques, mantén la espalda recta en todo momento, al caer apoya primero las puntas y luego los talones.",
    },
    "crack-jo-canales-fitness-lateral-jumps-title": {
        "en-us": "Lateral Jumps",
        "es-mx": "Saltos Laterales",
    },
    "crack-jo-canales-fitness-lateral-jumps-desc": {
        "en-us":
            "Arm your arms the same way you would when running, do a lateral jump to stand on one foot, the other leg is semi-extended backwards for balance.",
        "es-mx":
            "Bracea de la misma manera que lo harías al correr, haz un salto lateral para quedar apoyado en un solo pie, la otra pierna está semiextendida hacia atrás para hacer equilibrio.",
    },
    "crack-jo-canales-fitness-lunges-db-title": {
        "en-us": "DB Lunges",
        "es-mx": "DB Lunges",
    },
    "crack-jo-canales-fitness-lunges-db-desc": {
        "en-us":
            "Keep the dumbbells in your hands with your arms stretched out, the front knee always stays over the ankle, the heel of the front foot is always supported, shoulders, hip and back knee form a straight vertical line, the foot that stays behind, maintains position at all times.",
        "es-mx":
            "Mantén las mancuernas en las manos con los brazos estirados , la rodilla del frente siempre se mantiene sobre el tobillo, el talón del pie de enfrente siempre está apoyado, hombros, cadera y rodilla de atrás forman una línea recta vertical, el pie que se queda atrás, mantiene su posición en todo momento.",
    },
    "crack-jo-canales-fitness-lunges-db-material": {
        "en-us": "Dumbbells",
        "es-mx": "Mancuernas",
    },
    "crack-jo-canales-fitness-lunges-title": {
        "en-us": "Lunges",
        "es-mx": "Lunges",
    },
    "crack-jo-canales-fitness-lunges-desc": {
        "en-us":
            "This compound exercise activates multiple joints and muscle groups. To execute it, balance, coordination and good posture are required. When repeated correctly, lunges are a surefire way to strengthen your lower body.",
        "es-mx":
            "Este ejercicio compuesto activa múltiples articulaciones y grupos musculares. Para ejecutarlo, se requiere equilibrio, coordinación y una buena postura. Cuando se repiten correctamente, los Lunges son una forma segura de fortalecer la zona inferior del cuerpo.",
    },
    "crack-jo-canales-fitness-lunges-object-title": {
        "en-us": "Object Lunges",
        "es-mx": "Object Lunges",
    },
    "crack-jo-canales-fitness-lunges-object-desc": {
        "en-us":
            "Keep object close to body at chest height, front knee always stays over ankle, heel of front foot is always supported, back foot maintains position at all times, shoulders, hip and knee of the back leg form a vertical straight line.",
        "es-mx":
            "Mantén el objeto pegado al cuerpo a la altura del pecho, la rodilla del frente siempre se mantiene sobre el tobillo, el talón del pie de enfrente siempre está apoyado, el pie que se queda atrás mantiene su posición en todo momento,hombros, cadera y rodilla de la pierna de atrás forman una línea recta vertical.",
    },
    "crack-jo-canales-fitness-lunges-object-material": {
        "en-us":
            "Lightweight object (backpack with things inside, dumbbell or whatever you have available).",
        "es-mx":
            "Objeto con poco peso (mochila con cosas adentro, mancuerna o lo que tengas disponible).",
    },
    "crack-jo-canales-fitness-mountain-climbers-title": {
        "en-us": "Mountain Climbers",
        "es-mx": "Mountain Climbers",
    },
    "crack-jo-canales-fitness-mountain-climbers-desc": {
        "en-us":
            "This complete exercise requires the work of numerous muscles. Also known as climbers, mountain climbers are one of the most popular exercises in crossfit. Watch your movements to avoid injuries and get the most out of it.",
        "es-mx":
            "Este completo ejercicio requiere del trabajo de numerosos músculos. También conocidos como escaladores, los mountain climbers, son uno de los ejercicios más populares del crossfit. Cuida tus movimientos para evitar lesiones y obtener el máximo provecho.",
    },
    "crack-jo-canales-fitness-pendlay-row-db-title": {
        "en-us": "DB Pendlay Row",
        "es-mx": "DB Pendlay Row",
    },
    "crack-jo-canales-fitness-pendlay-row-db-desc": {
        "en-us":
            "In addition to increasing strength and hypertrophy in the lats, rhomboids, and transverse (middle) portion of the trapezius, this exercise also increases the strength of the stabilizing muscles around the shoulder joint, and helps keep the humeral head centered.",
        "es-mx":
            "Además de aumentar la fuerza y la hipertrofia en los dorsales, romboides y la porción transversa (media) del trapecio, este ejercicio también incrementa la fuerza de los músculos estabilizadores alrededor de la articulación del hombro, y ayuda a mantener centrada la cabeza del húmero.",
    },
    "crack-jo-canales-fitness-pendlay-row-db-material": {
        "en-us": "Dumbbells",
        "es-mx": "Mancuernas",
    },
    "crack-jo-canales-fitness-pendlay-row-object-title": {
        "en-us": "Object Pendlay Row",
        "es-mx": "Object Pendlay Row",
    },
    "crack-jo-canales-fitness-pendlay-row-object-desc": {
        "en-us":
            "The pendlay row is a great exercise to strengthen the back muscles. Discover the technique to do it correctly and avoid injuries.",
        "es-mx":
            "El pendlay row (remo pendlay) es un gran ejercicio para fortalecer la musculatura de la espalda. Descubre la técnica para hacerlo correctamente y evitar lesiones.",
    },
    "crack-jo-canales-fitness-pendlay-row-object-material": {
        "en-us":
            "Lightweight object (backpack with things inside, dumbbell or whatever you have available.",
        "es-mx":
            "Objeto con poco peso (mochila con cosas adentro, mancuerna o lo que tengas disponible).",
    },
    "crack-jo-canales-fitness-plank-dynamic-title": {
        "en-us": "Plank Dynamic",
        "es-mx": "Plank Dynamic",
    },
    "crack-jo-canales-fitness-plank-dynamic-desc": {
        "en-us":
            "Place your hands below your shoulders and fully stretch your elbows, keep your abdomen slightly tight, rest your forearm on one side on the floor and then the other, return to the starting position by stretching the second arm that you lowered, return to start but starting with the opposite arm, the hands always rest in the same place, slightly round the back, bring the navel towards the spine. Push your hips toward the floor.",
        "es-mx":
            "Apoya las manos abajo de los hombros y estira por completo los codos, mantén el abdomen ligeramente apretado, apoya el antebrazo de un lado en el piso y luego el otro, regresa a la posición inicial estirando el segundo brazo que bajo, vuelve a empezar pero iniciando con el brazo contrario, las manos siempre se apoyan en el mismo lugar, encorva ligeramente la espalda, lleva el ombligo hacia la columna. Empuja la cadera hacia el piso.",
    },
    "crack-jo-canales-fitness-plank-jacks-title": {
        "en-us": "Plank Jacks",
        "es-mx": "Plank Jacks",
    },
    "crack-jo-canales-fitness-plank-jacks-desc": {
        "en-us":
            "Place your hands below your shoulders and fully straighten your elbows, round your back slightly, bring your navel in towards your spine, push your hips towards the floor, keep your abdomen slightly tight, open and close your legs jumping from one position to another.",
        "es-mx":
            "Apoya las manos abajo de los hombros y estira por completo los codos, encorva ligeramente la espalda, lleva el ombligo hacia la columna, empuja la cadera hacia el piso, mantén el abdomen ligeramente apretado, abre y cierra las piernas brincando de una posición a otra.",
    },
    "crack-jo-canales-fitness-plank-knee-elbow-title": {
        "en-us": "Plank Knee to Elbow",
        "es-mx": "Plank Knee to Elbow",
    },
    "crack-jo-canales-fitness-plank-knee-elbow-desc": {
        "en-us":
            "Place your hands below your shoulders and fully straighten your elbows, keep your abdomen slightly tight, bring one elbow toward your abdomen at the same time as the opposite knee so they make contact, round your back slightly, bring your navel in the spine, push the hips towards the floor, return to the starting position and repeat with the opposite arm and leg.",
        "es-mx":
            "Apoya las manos abajo de los hombros y estira por completo los codos, mantén el abdomen ligeramente apretado,,lleva un codo hacia tu abdomen al mismo tiempo que la rodilla opuesta para que hagan contacto, encorva ligeramente la espalda, lleva el ombligo hacia la columna, empuja la cadera hacia el piso, regresa a la posición inicial y repite con el brazo y pierna opuestos.",
    },
    "crack-jo-canales-fitness-plank-title": {
        "en-us": "Plank",
        "es-mx": "Plank",
    },
    "crack-jo-canales-fitness-plank-desc": {
        "en-us":
            "Place your hands below your shoulders and fully extend your elbows, keep your abdomen slightly tight, slightly round your back, bring your navel in toward your spine. Push your hips toward the floor.",
        "es-mx":
            "Apoya las manos abajo de los hombros y estira por completo los codos, mantén el abdomen ligeramente apretado,  encorva ligeramente la espalda, lleva el ombligo hacia la columna. Empuja la cadera hacia el piso.",
    },
    "crack-jo-canales-fitness-plank-toe-touches-title": {
        "en-us": "Plank Toe Touches",
        "es-mx": "Plank Toe Touches",
    },
    "crack-jo-canales-fitness-plank-toe-touches-desc": {
        "en-us":
            "Place your hands below your shoulders and fully extend your elbows, keep your abdomen slightly tight, round your back slightly, bring your navel in towards your spine, push your hips towards the floor, bring your hips up and your chest down. feet to touch an ankle with the opposite hand, return to plank position and repeat the movement with the other hand.",
        "es-mx":
            "Apoya las manos abajo de los hombros y estira por completo los codos, mantén el abdomen ligeramente apretado, encorva ligeramente la espalda, lleva el ombligo hacia la columna, empuja la cadera hacia el piso, lleva la cadera hacia arriba y el pecho hacia los pies para tocar un tobillo con la mano contraria, regresa a posición de plancha y repite el movimiento con la otra mano.",
    },
    "crack-jo-canales-fitness-plank-tricep-extension-title": {
        "en-us": "Plank Tricep Extension",
        "es-mx": "Plank Tricep Extension",
    },
    "crack-jo-canales-fitness-plank-tricep-extension-desc": {
        "en-us":
            "Rest your forearms below your shoulders, keep your core slightly tight, round your back slightly, draw your navel in towards your spine, push your hips toward the floor, fully extend your elbows until you are resting on the floor with your hands only , return to the starting position.",
        "es-mx":
            "Apoya los antebrazos abajo de los hombros,  mantén el abdomen ligeramente apretado, encorva ligeramente la espalda, lleva el ombligo hacia la columna, empuja la cadera hacia el piso, extiende los codos por completo hasta quedar apoyado en el piso solamente con las mano, regresa a la posición inicial.",
    },
    "crack-jo-canales-fitness-pull-ups-title": {
        "en-us": "Pull Ups",
        "es-mx": "Pull Ups",
    },
    "crack-jo-canales-fitness-pull-ups-desc": {
        "en-us":
            "Hang with palms facing forward and hands open slightly wider than shoulder width, pull elbows slightly out until beard clears bar, return down until arms are fully extended.",
        "es-mx":
            "Cuélgate con las palmas de las manos en dirección frontal y las manos abiertas un poco más que el ancho de tus hombros, jala llevando los codos ligeramente hacia afuera hasta que la barba pase la barra, regresa abajo hasta que los brazos queden completamente estirados.",
    },
    "crack-jo-canales-fitness-pull-ups-material": {
        "en-us": "Goal or bar.",
        "es-mx": "Portería o barra.",
    },
    "crack-jo-canales-fitness-push-ups-title": {
        "en-us": "Push Ups",
        "es-mx": "Push Ups",
    },
    "crack-jo-canales-fitness-push-ups-desc": {
        "en-us":
            "Place your hands on the floor at chest level with your arms outstretched, bend your arms at a 45-degree angle as you lower until your chest touches the floor, return to the starting position, keep your hips at all times at chest height.",
        "es-mx":
            "Apoya las manos en el piso a la altura del pecho con los brazos estirados, flexiona los brazos en un ángulo de 45 grados mientras bajas hasta que el pecho toque el piso, regresa a la posición inicial, la cadera se mantiene en todo momento a la altura del pecho.",
    },
    "crack-jo-canales-fitness-run-title": {
        "en-us": "Run",
        "es-mx": "Correr",
    },
    "crack-jo-canales-fitness-run-desc": {
        "en-us":
            "Keep your body slightly bent forward and your chest open. Both the movement of your arms (braceo) and that of your legs (stride) should be in a straight line, avoid crossing them or making lateral movements.",
        "es-mx":
            "Mantén el cuerpo ligeramente inclinado al frente y el pecho abierto, Tanto el movimiento de tus brazos (braceo) como el de tus piernas (zancada) deberán ser en línea recta, evita cruzarlos o hacer movimientos laterales.",
    },
    "crack-jo-canales-fitness-russian-twist-title": {
        "en-us": "Russian Twist",
        "es-mx": "Russian Twist",
    },
    "crack-jo-canales-fitness-russian-twist-desc": {
        "en-us":
            "Keep your legs semi flexed, with your hands together touch the floor at your sides, make sure you rotate your torso when you touch the floor and that your legs do not move at any time.",
        "es-mx":
            "Mantén las piernas semi flexionadas, con las manos juntas toca el piso a tus costados, asegúrate de rotar el torso cuando toques el piso y que las piernas no se muevan en ningún momento.",
    },
    "crack-jo-canales-fitness-scissors-title": {
        "en-us": "Scissors",
        "es-mx": "Scissors",
    },
    "crack-jo-canales-fitness-scissors-desc": {
        "en-us":
            "Lying on the floor, place your hands under your buttocks, lift your head and upper back off the floor, keep your legs stretched out in the air and cross them, alternating one leg on top of the other.",
        "es-mx":
            "Acostado en el piso coloca las manos bajo las pompas, levanta la cabeza y espalda alta del piso, mantén las piernas estiradas en el aire y crúzalas alternando una pierna arriba de la otra.",
    },
    "crack-jo-canales-fitness-shoulder-db-title": {
        "en-us": "DB Shoulder Press",
        "es-mx": "DB Shoulder Press",
    },
    "crack-jo-canales-fitness-shoulder-db-desc": {
        "en-us":
            "Keep your abdomen tight to avoid sticking out your ribs, arms end up fully stretched next to your ears, dumbbells start at ear height.",
        "es-mx":
            "Mantén el abdomen apretado para evitar sacar las costillas, los brazos acaban completamente estirados al lado de las orejas, las mancuernas inician a la altura de las orejas.",
    },
    "crack-jo-canales-fitness-shoulder-db-material": {
        "en-us": "Dumbbells",
        "es-mx": "Mancuernas",
    },
    "crack-jo-canales-fitness-shoulder-object-title": {
        "en-us": "Object Shoulder Press",
        "es-mx": "Object Shoulder Press",
    },
    "crack-jo-canales-fitness-shoulder-object-desc": {
        "en-us":
            "Keep your core tight to avoid sticking your ribs out just above your head with your shoulders straight, the object starts above your collarbones.",
        "es-mx":
            "Mantén el abdomen apretado para evitar sacar las costillas justo arriba de la cabeza con los hombros estirados, el objeto empieza sobre las clavículas.",
    },
    "crack-jo-canales-fitness-shoulder-object-material": {
        "en-us":
            "Lightweight object (backpack with things inside, dumbbell or whatever you have available).",
        "es-mx":
            "Objeto con poco peso (mochila con cosas adentro, mancuerna o lo que tengas disponible).",
    },
    "crack-jo-canales-fitness-shoulder-taps-title": {
        "en-us": "Shoulder Tap",
        "es-mx": "Shoulder Tap",
    },
    "crack-jo-canales-fitness-shoulder-taps-desc": {
        "en-us":
            "Place your hands below your shoulders and fully extend your elbows, round your back slightly, bring your navel in toward your spine, push your hips toward the floor, keep your abdomen slightly tight. Touch a shoulder with the opposite hand, return to the starting position and repeat the movement with the other hand, avoid moving the hip or any other part of the body, only the arms move.",
        "es-mx":
            "Apoya las manos abajo de los hombros y estira por completo los codos, encorva ligeramente la espalda, lleva el ombligo hacia la columna, empuja la cadera hacia el piso, mantén el abdomen ligeramente apretado. Toca un hombro con la mano contraria, regresa a la posición inicial y repite el movimiento con la otra mano, evita mover la cadera o cualquier otra parte del cuerpo, solamente se mueven los brazos.",
    },
    "crack-jo-canales-fitness-single-leg-bridge-title": {
        "en-us": "Single Leg Bridge",
        "es-mx": "Single Leg Bridge",
    },
    "crack-jo-canales-fitness-single-leg-bridge-desc": {
        "en-us":
            "separate your feet at hip height and extend one leg in the air, it will always remain at the same height as the thigh of the other leg, raise your hips as much as you can explosively and squeeze your glutes and posterior , go down until you are lying on the floor again.",
        "es-mx":
            "separa los pies a la altura de la cadera y extiende una pierna en el aire, va a permanecer siempre a la misma altura que el muslo de la otra pierna, eleva la cadera lo mas que puedas de manera explosiva y aprieta glúteos y posteriores, baja hasta quedar nuevamente recostado en el piso.",
    },
    "crack-jo-canales-fitness-single-leg-deadlift-title": {
        "en-us": "Single Leg Deadlift",
        "es-mx": "Single Leg Deadlift",
    },
    "crack-jo-canales-fitness-single-leg-deadlift-desc": {
        "en-us":
            "Keep your back straight by sticking your chest out, slightly bend your knee without going forward, bring your hips back with your weight on one heel and keep the other leg stretched back in line with your back, keep your head neutral in-line with torso, lower until dumbbell touches floor and return to starting position.",
        "es-mx":
            "Mantén la espalda recta sacando el pecho,  haz una ligera flexión de la rodilla sin ir al frente, lleva la cadera hacia atrás con el peso en un talón y la otra pierna mantenla estirada hacia atrás en línea con la espalda, mantén la cabeza neutral alineada con el torso, baja hasta que la mancuerna toque el piso y regresa a la posición inicial.",
    },
    "crack-jo-canales-fitness-single-leg-deadlift-material": {
        "en-us": "Dumbbells",
        "es-mx": "Mancuernas",
    },
    "crack-jo-canales-fitness-single-unders-title": {
        "en-us": "Single Unders",
        "es-mx": "Single Unders",
    },
    "crack-jo-canales-fitness-single-unders-desc": {
        "en-us":
            "Keep the rope in front of your body with your arms semiflexed, the movement of the rope is done with the turn of the wrists, the elbows remain close to the body, jump with the feet together, driving with the ankles and supporting only the tip the foot on the floor, the knees maintain a very slight bend throughout, the torso in an upright position in the same line as the ankles and hips.",
        "es-mx":
            "Mantén la cuerda frente a tu cuerpo con los brazos semiflexionados, el movimiento de la cuerda se hace con el giro de las muñecas, los codos permanecen pegados al cuerpo, brinca con los pies juntos, impulsándote con los tobillos y apoyando solo la punta del pie en el piso, las rodillas mantienen una flexión muy ligera todo el tiempo, el torso en posición vertical en la misma línea que los tobillos y cadera.",
    },
    "crack-jo-canales-fitness-single-unders-material": {
        "en-us": "Jump rope.",
        "es-mx": "Cuerda para saltar.",
    },
    "crack-jo-canales-fitness-sit-crunches-title": {
        "en-us": "Sit Crunches",
        "es-mx": "Sit Crunches",
    },
    "crack-jo-canales-fitness-sit-crunches-desc": {
        "en-us":
            "Lie on your back on the floor with your legs straight perpendicular to the floor, hold the ball between your ankles, raise your torso until you take the ball with your hands and bring it to the floor behind your head, on the next repetition leave the ball between ankles again.",
        "es-mx":
            "Acuéstate boca arriba en el piso con las piernas estiradas perpendiculares al piso, sosten el balón entre los tobillos, levanta el torso hasta tomar el balón con las manos y llévalo al piso detrás de la cabeza, en la siguiente repetición deja el balón entre los tobillos nuevamente.",
    },
    "crack-jo-canales-fitness-sit-crunches-material": {
        "en-us": "Ball or similar.",
        "es-mx": "Balón o similares.",
    },
    "crack-jo-canales-fitness-split-squat-title": {
        "en-us": "Split Squat",
        "es-mx": "Split Squat",
    },
    "crack-jo-canales-fitness-split-squat-desc": {
        "en-us":
            "The heel of the front foot is always supported, the front knee is always kept on the ankle, shoulders, hip and knee of the back leg form a straight vertical line.",
        "es-mx":
            "El talón del pie de enfrente siempre está apoyado, la rodilla del frente siempre se mantiene sobre el tobillo, hombros, cadera y rodilla de la pierna de atrás forman una línea recta vertical.",
    },
    "crack-jo-canales-fitness-squats-db-title": {
        "en-us": "DB Squat",
        "es-mx": "DB Squat",
    },
    "crack-jo-canales-fitness-squats-db-desc": {
        "en-us":
            "Rest the dumbbells on your shoulders and keep your elbows as high as you can, the heel of your foot is flat on the floor at all times. Lower your hips as far as you can without lifting your heels off the floor, hip movement starts pushed back, knees push slightly out to be in line with feet or slightly more open, keep torso straight and chest open, if you keep your eyes straight ahead and up, you make it easier to maintain your position.",
        "es-mx":
            "Apoya las mancuernas en los hombros y mantén los codos lo más arriba que puedas, el talón del pie está apoyado en el piso todo el tiempo. Baja la cadera tanto como puedas sin despegar los talones del piso, el movimiento de la cadera inicia empujado hacia atrás, las rodillas empujan ligeramente hacia afuera para estar en línea con los pies o ligeramente más abierta, mantén el torso recto y el pecho abierto, si mantienes la vista al frente y  arriba  facilitas mantener la posición.",
    },
    "crack-jo-canales-fitness-squats-db-material": {
        "en-us": "Dumbbells",
        "es-mx": "Mancuernas",
    },
    "crack-jo-canales-fitness-squats-title": {
        "en-us": "Squats",
        "es-mx": "Squats",
    },
    "crack-jo-canales-fitness-squats-desc": {
        "en-us":
            "Knees push out slightly to be in line with feet or slightly more open, heel of foot flat on floor at all times, lower hips as far as possible without lifting heels off floor, movement of hips start pushed back, keep your torso straight and your chest open, if you keep your eyes straight ahead and up you make it easier to maintain the position.",
        "es-mx":
            "Las rodillas empujan ligeramente hacia afuera para estar en línea con los pies o ligeramente más abierta, el talón del pie está apoyado en el piso en todo momento, baja la cadera tanto como puedas sin despegar los talones del piso, el movimiento de la cadera inicia empujado hacia atrás,  mantén el torso recto y el pecho abierto, si mantienes la vista al frente y  arriba facilitas mantener la posición.",
    },
    "crack-jo-canales-fitness-squats-object-title": {
        "en-us": "Object Squats",
        "es-mx": "Object Squats",
    },
    "crack-jo-canales-fitness-squats-object-desc": {
        "en-us":
            "Hold the object with both hands close to your body at neck height, keep your torso straight and your chest open, lower your hips as much as you can without taking your heels off the floor, knees slightly push out to be in line with the feet or slightly more open, the movement of the hip starts pushed backwards, the heel of the foot is flat on the floor at all times.",
        "es-mx":
            "Sostén el objeto con las dos manos pegado al cuerpo a la altura del cuello, mantén el torso recto y el pecho abierto, baja la cadera tanto como puedas sin despegar los talones del piso, las rodillas empujan ligeramente hacia afuera para estar en línea con los pies o ligeramente más abiertas, el movimiento de la cadera inicia empujado hacia atrás, el talón del pie está apoyado en el piso en todo momento.",
    },
    "crack-jo-canales-fitness-squats-object-material": {
        "en-us":
            "Lightweight object (backpack with things inside, dumbbell or whatever you have available).",
        "es-mx":
            "Objeto con poco peso (mochila con cosas adentro, mancuerna o lo que tengas disponible).",
    },
    "crack-jo-canales-fitness-step-ups-title": {
        "en-us": "Step Ups",
        "es-mx": "Step Ups",
    },
    "crack-jo-canales-fitness-step-ups-desc": {
        "en-us":
            "Support the entire sole of the foot on the surface to which you are going to climb, tighten your abdomen and push off with the foot from the floor to climb, once the upper leg has been fully extended you can place the second foot on the surface you climbed on, on the way back control the descent with the foot that is still resting on the surface you climbed on.",
        "es-mx":
            "Apoya toda la planta del pie sobre la superficie a la cual vas a subir, aprieta el abdomen e impúlsate con el pie del piso para subir, una vez que la pierna de arriba se haya extendido por completo podrás apoyar el segundo pie sobre la superficie a la que subiste, en el regreso controla la bajada con el pie que sigue apoyado en la superficie a la cual subiste.",
    },
    "crack-jo-canales-fitness-step-ups-material": {
        "en-us": "Wooden box or similar.",
        "es-mx": "Cajón de madera o similares.",
    },
    "crack-jo-canales-fitness-superman-title": {
        "en-us": "Superman",
        "es-mx": "Superman",
    },
    "crack-jo-canales-fitness-superman-desc": {
        "en-us":
            "Lie on your stomach on the floor keeping your arms and legs in the air fully stretched, lift your chest and legs at the same time as high as you can, return to the starting position.",
        "es-mx":
            "Recuéstate boca bajo en el piso manteniendo brazos y piernas en el aire completamente estirados,  levanta el pecho y piernas al mismo tiempo tanto como puedas, regresa a la posición inicial.",
    },
    "crack-jo-canales-fitness-trx-rows-title": {
        "en-us": "TRX Rows",
        "es-mx": "TRX Rows",
    },
    "crack-jo-canales-fitness-trx-rows-desc": {
        "en-us":
            "Place your heels on the floor to lean back, leaving your body in a diagonal position, make sure that your ankles, hips and shoulders are always in the same line, to do this push your hips up, pull your elbows slightly outwards until the chest reaches the height of the hands, returns down until the arms are fully stretched.",
        "es-mx":
            "Apoya los talones en el piso para recostarte hacia atrás dejando el cuerpo en posición diagonal, asegúrate que tobillos cadera y hombros siempre estén en la misma línea, para hacer esto la cadera empuja hacia arriba, jala llevando los codos ligeramente hacia afuera hasta que el pecho llegue a la altura de las mano, regresa hacia abajo hasta estirar los brazos por completo.",
    },
    "crack-jo-canales-fitness-trx-rows-material": {
        "en-us": "TRX or similar.",
        "es-mx": "TRX o similares.",
    },
    "crack-jo-canales-fitness-uneven-push-ups-title": {
        "en-us": "Uneven Push Ups",
        "es-mx": "Uneven Push Ups",
    },
    "crack-jo-canales-fitness-uneven-push-ups-desc": {
        "en-us":
            "Place your hands at chest height, one on the floor and the other on the ball, bend your arms at a 45-degree angle as you lower until your chest touches the floor, return to the starting position, hips maintains at all times at chest height, at the end changes the ball to the side.",
        "es-mx":
            "Apoya las manos a la altura del pecho, una en el piso y la otra en el balón, flexiona los brazos en un ángulo de 45 grados mientras bajas hasta que el pecho toque el piso, regresa a la posición inicial, la cadera se mantiene en todo momento a la altura del pecho, al finalizar cambia el balón de lado.",
    },
    "crack-jo-canales-fitness-uneven-push-ups-material": {
        "en-us": "Ball or similar.",
        "es-mx": "Balón o similares.",
    },
    "crack-jo-canales-fitness-walking-lunges-title": {
        "en-us": "Walking Lunges",
        "es-mx": "Walking Lunges",
    },
    "crack-jo-canales-fitness-walking-lunges-desc": {
        "en-us":
            "Shoulders, hip and knee of the back leg form a straight vertical line, the heel of the front foot is always supported, the front knee is always kept on the ankle, when you lift the back foot reaches the front foot, and then start the next lunge.",
        "es-mx":
            "Hombros, cadera y rodilla de la pierna de atrás forman una línea recta vertical,  el talón del pie de enfrente siempre está apoyado,  la rodilla del frente siempre se mantiene sobre el tobillo,al levantarte el pie de atrás alcanza al de adelante, para después iniciar el siguiente desplante.",
    },
    "crack-jo-canales-fitness-wall-sit-title": {
        "en-us": "Wall Sit",
        "es-mx": "Wall Sit",
    },
    "crack-jo-canales-fitness-wall-sit-desc": {
        "en-us":
            "Hit the back against a wall, lower until the hip is horizontally aligned with the knee and the knee with the ankle aligned vertically, the back pushes against the wall and the feet against the floor, the arms do not rest on the legs.",
        "es-mx":
            "Pega la espalda una pared, baja hasta que la cadera quede alineada horizontalmente con la rodilla y la rodilla con el tobillo alineado verticalmente, la espalda empuja contra la pared y los pies contra el piso, los brazos no se apoyan en las piernas.",
    },
    "crack-jo-canales-skills-displacement-title": {
        "en-us": "Lateral movements with hip movements",
        "es-mx": "Desplazamientos laterales con movimientos de cadera",
    },
    "crack-jo-canales-skills-displacement-desc": {
        "en-us":
            "Gira los brazos y el torso de manera opuesta a las piernas, avanza de manera lateral alternando el apoyo de los pies adelante y atrás.",
        "es-mx":
            "Turn your arms and torso opposite your legs, move sideways, alternating foot support forwards and backwards",
    },
    "crack-jo-canales-skills-pull-ups-instep-title": {
        "en-us": "Pull-ups alternating insteps",
        "es-mx": "Dominadas alternando empeines",
    },
    "crack-jo-canales-skills-pull-ups-instep-desc": {
        "en-us": "Hit the ball with the instep alternating feet.",
        "es-mx": "Golpea el balón con el empeine alternando pies.",
    },
    "crack-jo-canales-skills-pull-ups-inner-title": {
        "en-us": "Pull-ups with the inside of the feet",
        "es-mx": "Dominio de balón con parte interna de pies",
    },
    "crack-jo-canales-skills-pull-ups-inner-desc": {
        "en-us":
            "Hit the ball at the height of the knee of the supporting leg and with the inside of the foot parallel to the ground.",
        "es-mx":
            "Golpea el balón a la altura de la rodilla de la pierna de apoyo y con la parte interna del pie paralela al piso.",
    },
    "crack-jo-canales-skills-pull-ups-inner-instep-title": {
        "en-us": "Pull-ups alternating instep and internal part",
        "es-mx": "Dominadas alternando empeine y parte interna",
    },
    "crack-jo-canales-skills-pull-ups-inner-instep-desc": {
        "en-us": "Alternate 2 left and right instep strikes with 2 inner strikes.",
        "es-mx": "Alterna 2 golpeos de empeine derecha e izquierda con 2 golpeos de parte interna.",
    },
    "crack-jo-canales-skills-pull-ups-thigh-title": {
        "en-us": "Alternating thigh pull-ups",
        "es-mx": "Dominadas alternando muslos",
    },
    "crack-jo-canales-skills-pull-ups-thigh-desc": {
        "en-us":
            "focus on hitting the ball with the thigh parallel to the ground, hit the ball with the thigh alternating legs.",
        "es-mx":
            "Enfócate en que el golpeo del balón sea con el muslo paralelo al piso, golpea el balón con el muslo alternando piernas.",
    },
    "crack-jo-canales-skills-control-insteps-thighs-title": {
        "en-us": "Ball control alternating insteps and thighs",
        "es-mx": "Dominio de balón alternando empeines y muslos",
    },
    "crack-jo-canales-skills-control-insteps-thighs-desc": {
        "en-us":
            "Hit the ball with both insteps, make the second hit high so you can continue with the thighs.",
        "es-mx":
            "Golpea el balón con los dos empeines, haz el segundo golpeo alto para poder continuar con los muslos.",
    },
    "crack-jo-canales-skills-driving-lateral-title": {
        "en-us": "Lateral driving soles of the foot",
        "es-mx": "Conducción lateral plantas del pie",
    },
    "crack-jo-canales-skills-driving-lateral-desc": {
        "en-us":
            "While moving laterally step the ball in front of you in the same direction you are moving.",
        "es-mx":
            "Mientras avanzas de manera lateral pisa el balón frente a ti en la misma dirección en la que estás moviéndote.",
    },
    "crack-jo-canales-skills-driving-free-title": {
        "en-us": "Free driving",
        "es-mx": "Conducción plantas del pie libre",
    },
    "crack-jo-canales-skills-driving-free-desc": {
        "en-us":
            "Direct the ball with the sole of your foot in different directions making sure you have control of the ball at all times.",
        "es-mx":
            "Dirige el balón con la planta del pie en distintas direcciones asegurandote de tener el control del balón en todo momento.",
    },
    "crack-jo-canales-skills-control-targeted-title": {
        "en-us": "Targeted control",
        "es-mx": "Control dirigido",
    },
    "crack-jo-canales-skills-control-targeted-desc": {
        "en-us":
            "Take a step towards where the ball is coming from, direct it to a profile with the inside part letting it go in completely to pass with that same foot.",
        "es-mx":
            "Da un paso hacia donde viene el balón, dirigelo hacia un perfil con la parte interna dejándolo entrar por completo para dar el pase con ese mismo pie.",
    },
    "crack-jo-canales-skills-roulette-zidane-title": {
        "en-us": "Roulette “Zidane way”",
        "es-mx": "Ruleta “Giro a la Zidane”",
    },
    "crack-jo-canales-skills-roulette-zidane-desc": {
        "en-us":
            "Drive the ball facing the opponent, pretend to go to one side and then step on the ball to the other, place your foot next to the ball to turn with the other,",
        "es-mx":
            "Conduce el balón enfrentando al adversario, amaga que irás hacia un lado y luego pisa el balón hacia el otro, apoya el pie al lado del balón para girar con el otro.",
    },
    "crack-jo-canales-skills-pass-title": {
        "en-us": "Pass",
        "es-mx": "Pase",
    },
    "crack-jo-canales-skills-pass-desc": {
        "en-us":
            "At the moment of the hit keep the body slightly leaning forward, hit the ball mid-height with the inside of the foot.",
        "es-mx":
            "Al momento del golpeo mantén el cuerpo ligeramente inclinado al frente, golpea el balón a media altura con la parte interna del pie.",
    },
    "crack-jo-canales-skills-pass-alternate-title": {
        "en-us": "Pass alternating legs",
        "es-mx": "Pase alternando piernas",
    },
    "crack-jo-canales-skills-pass-alternate-desc": {
        "en-us":
            "At the moment of the hit keep the body slightly tilted forward, and alternate the legs.",
        "es-mx":
            "Al momento del golpeo mantén el cuerpo ligeramente inclinado al frente, y alterna las piernas.",
    },
    "crack-jo-canales-skills-reception-chest-title": {
        "en-us": "Reception with the chest",
        "es-mx": "Recepción con el pecho",
    },
    "crack-jo-canales-skills-reception-chest-desc": {
        "en-us":
            "Catch the ball with your chest leaning back slightly to slow the ball down, turn your shoulders to the side you want to catch when the ball lands.",
        "es-mx":
            "Recibe el balón con el pecho inclinándote ligeramente hacia atrás para bajar la velocidad del balón,gira los hombros hacia el lado al que quieras hacer la recepción cuando el balón caiga.",
    },
    "crack-jo-canales-skills-shot-outside-box-title": {
        "en-us": "Shot outside the box",
        "es-mx": "Tiro fuera del área",
    },
    "crack-jo-canales-skills-shot-outside-box-desc": {
        "en-us":
            "Drive facing the opponent, fake and stretch the ball to the side, focus on leaving the support foot next to the ball.",
        "es-mx":
            "Conduce encarando al adversario, haz un amague y alarga el balón hacía un lado, enfócate en dejar el pie de apoyo junto al balón.",
    },
    "crack-jo-canales-skills-volley-hitting-title": {
        "en-us": "Volley hitting",
        "es-mx": "Golpeo de volea",
    },
    "crack-jo-canales-skills-volley-hitting-desc": {
        "en-us": "Stand in profile to the goal and tilt your body in the same direction.",
        "es-mx": "Párate de perfil a la portería e inclina el cuerpo en esa misma dirección.",
    },
    "crack-jo-canales-skills-zig-zag-title": {
        "en-us": "Zig Zag",
        "es-mx": "Zig Zag",
    },
    "crack-jo-canales-skills-zig-zag-desc": {
        "en-us": "Legs semi-bent, move sideways and lower your hips.",
        "es-mx": "Piernas semiflexionadas, desplázate de manera lateral y baja la cadera.",
    },
    "crack-gigi-buffon-stories-1-title": {
        "en-us": "A pure love story with Soccer",
        "es-mx": "Una Historia de Amor con el fútbol",
    },
    "crack-gigi-buffon-stories-1-desc": {
        "en-us":
            "Gigi always played soccer, although he was a midfielder until he was 12. But when he discovered the magic of goalkeeping, Buffon never left the gloves again...",
        "es-mx":
            "Gigi siempre jugó al fútbol, aunque hasta los 12 años jugaba de centrocampista. Cuando descubrió la magia de la portería, se calzó los guantes y no los abandonó nunca más... ",
    },
    "crack-gigi-buffon-stories-2-title": {
        "en-us": "Unforgettable memories",
        "es-mx": "Momentos inolvidables",
    },
    "crack-gigi-buffon-stories-2-desc": {
        "en-us":
            "Buffon made his debut in the first division with just 17 years old, and then, only at 19, he was already making his debut in the powerful Italian National Team. Later, thanks to his extensive and brilliant career at Juve and with the “Azzurra”, he would become the most important goalkeeper of his time.",
        "es-mx":
            "Debutó en primera división con solo 17 años, y a los 19 ya se estrenaba en la poderosa selección de Italia. Luego, gracias a su extensa y brillante trayectoria en la Juve y con la selección “Azzurra”, se convertiría en el portero más importante de su época.",
    },
    "crack-gigi-buffon-stories-3-title": {
        "en-us": "The postponed retirment",
        "es-mx": "El retiro postergado",
    },
    "crack-gigi-buffon-stories-3-desc": {
        "en-us":
            "After 18 unforgettable seasons with Juventus, Gigi felt that, perhaps, it was time to retire…but a tempting offer gave an unexpected turn to his career, which has kept him active to this day.",
        "es-mx":
            "Tras 18 temporadas inolvidables con Juventus, Buffon sintió que, quizás, era la hora de retirarse. Pero una tentadora oferta le daría un giro inesperado a su carrera, que lo mantuvo activo hasta el día de hoy. ",
    },
    "crack-gigi-buffon-stories-4-title": {
        "en-us": "From goalkeeping to teaching",
        "es-mx": "De portero a educador",
    },
    "crack-gigi-buffon-stories-4-desc": {
        "en-us":
            "In the last chapters of his sporting life, Gigi understands that it is time to start passing on his enormous legacy in “calcio”, and inaugurates the academy that bears his name to train the new generations of goalkeepers.",
        "es-mx":
            "En los últimos capítulos de su vida deportiva, Gigi entiende que es hora de empezar a transmitir su enorme legado en el fútbol, e inaugura la academia que lleva su nombre para formar a las nuevas generaciones de porteros.",
    },
    "crack-gigi-buffon-mindset-teamwork-title": {
        "en-us": "Teamwork",
        "es-mx": "Trabajo en equipo",
    },
    "crack-gigi-buffon-mindset-teamwork-desc": {
        "en-us":
            "“Teamwork is the hardest thing to achieve, but it's the most rewarding one”, confesses Buffon, a natural-born leader who has shaped and strengthened dozens of winning teams.",
        "es-mx":
            "“El trabajo en equipo es la cosa más difícil de poder lograr, pero es lo más gratificante”, confiesa Buffon, un líder nato que ha moldeado y fortalecido decenas de equipos ganadores.",
    },
    "crack-gigi-buffon-mindset-perseverance-value-title": {
        "en-us": "The power of perseverance",
        "es-mx": "El valor de la perseverancia",
    },
    "crack-gigi-buffon-mindset-perseverance-value-desc": {
        "en-us":
            "If there is a person who knows about perseverance, that's Gigi Buffon. In his 40s, the Italian Crack continues to defy the mold and perseveres to succeed in his profession.",
        "es-mx":
            "Si alguien sabe de perseverancia es Gigi Buffon. A sus cuarenta y tantos años, el Crack italiano sigue desafiando los moldes...y manteniéndose perseverante para triunfar en su profesión.",
    },
    "crack-gigi-buffon-mindset-mental-growth-title": {
        "en-us": "Growth mentality",
        "es-mx": "Mentalidad de crecimiento",
    },
    "crack-gigi-buffon-mindset-mental-growth-desc": {
        "en-us":
            "To stay at the top of the world soccer scene for more than 20 years, it is essential “never to settle for what has been achieved”. Learn how to stay hungry for glory with this legend.",
        "es-mx":
            "Para mantenerse más de 20 años en el top del fútbol mundial, es imprescindible “no conformarse nunca con lo que se ha conseguido”. Aprende cómo mantenerte hambriento de gloria con esta leyenda.",
    },
    "crack-gigi-buffon-mindset-leadership-title": {
        "en-us": "Leadership",
        "es-mx": "Liderazgo",
    },
    "crack-gigi-buffon-mindset-leadership-desc": {
        "en-us":
            "Natural and positive leadership will make a difference in any soccer team or workgroup. Get inspired by the leadership vision of the Great Gigi Buffon.",
        "es-mx":
            "Un liderazgo natural y positivo hará la diferencia en cualquier equipo de fútbol o grupo de trabajo. Inspírate con la visión de liderazgo del Gran Gigi Buffon.",
    },
    "crack-gigi-buffon-skills-stop-grab-title": {
        "en-us": "Catching technique",
        "es-mx": "Técnica de parada y agarre",
    },
    "crack-gigi-buffon-skills-stop-grab-desc": {
        "en-us":
            "The catching technique is the most important technical gesture for the goalkeeper. Learn to contain and control the ball with this dynamic training.",
        "es-mx":
            "La técnica de parada es el gesto técnico más importante para el portero. Aprende a contener y controlar los balones con este dinámico entrenamiento.",
    },
    "crack-gigi-buffon-skills-side-falls-title": {
        "en-us": "Side falls",
        "es-mx": "Caídas laterales",
    },
    "crack-gigi-buffon-skills-side-falls-desc": {
        "en-us":
            "Learn the movements you must do to fall without hurting yourself when flying to the sides. It's time to get a little muddy!",
        "es-mx":
            "Aprende los movimientos que debes hacer para caer sin lastimarte cuando te tires a tus lados. ¡Es hora de embarrarse un poco!",
    },
    "crack-gigi-buffon-skills-goal-orientation-title": {
        "en-us": "Goal orientation",
        "es-mx": "Orientación en la portería",
    },
    "crack-gigi-buffon-skills-goal-orientation-desc": {
        "en-us":
            "The goalkeeper is in constant movement, and for this reason, he must learn to orient himself with fixed points of reference to always be ready to catch a shot.",
        "es-mx":
            "El portero está en constante movimiento, y por eso debe aprender a orientarse con puntos de referencia fijos para quedar siempre bien parado.",
    },
    "crack-gigi-buffon-skills-reaction-capacity-title": {
        "en-us": "Reaction capacity",
        "es-mx": "Capacidad de reacción",
    },
    "crack-gigi-buffon-skills-reaction-capacity-desc": {
        "en-us":
            "In this fun exercise, you will learn to work on your reaction, an essential motor skill for any goalkeeper.",
        "es-mx":
            "En este divertido ejercicio, aprender a trabajar en tu reacción, una capacidad motriz imprescindible para cualquier portero.",
    },
    "crack-gigi-buffon-skills-high-output-1-title": {
        "en-us": "Come out (Part 1)",
        "es-mx": "Salida (parte 1)",
    },
    "crack-gigi-buffon-skills-high-output-1-desc": {
        "en-us":
            "When you gain the confidence to come out strong, your command of the box and your presence in the game change completely. Learn how to do it in this exercise.",
        "es-mx":
            "Cuando adquieres la confianza suficiente para salir con decisión, tu dominio del área y tu presencia en el juego cambian por completo. Aprende cómo hacerlo en este ejercicio.",
    },
    "crack-gigi-buffon-skills-high-output-2-title": {
        "en-us": "Come out (Part 2)",
        "es-mx": "Salida (parte 2)",
    },
    "crack-gigi-buffon-skills-high-output-2-desc": {
        "en-us":
            "Cuando adquieres la confianza suficiente para salir con decisión, tu dominio del área y tu presencia en el juego cambian por completo. Aprende cómo hacerlo en este ejercicio.",
        "es-mx":
            "When you gain the confidence to come out strong, your command of the box and your presence in the game change completely. Learn how to do it in this exercise.",
    },
    "crack-gigi-buffon-skills-low-output-title": {
        "en-us": "Low come out",
        "es-mx": "Salida baja",
    },
    "crack-gigi-buffon-skills-low-output-desc": {
        "en-us":
            "Become the absolute owner of the area by perfecting your low come outs. Be ready to cut a lot of attacks...and get pretty muddy.",
        "es-mx":
            "Hazte el dueño absoluto del área perfeccionando tus salidas bajas. Prepárate para cortar muchos ataques....y embarrarte bastante.",
    },
    "crack-gigi-buffon-fitness-squats-title": {
        "en-us": "Squats",
        "es-mx": "Sentadillas",
    },
    "crack-gigi-buffon-fitness-squats-desc": {
        "en-us":
            "The basic exercise for the soccer goalkeeper is the squat. But regardless of what is your position, or what your favorite sport is, this exercise will allow you to strengthen your lower body and core muscles.",
        "es-mx":
            "El ejercicio básico para el portero de fútbol es la sentadilla. Pero sin importar en qué posición juegues o cuál sea tu deporte favorito, este ejercicio te permitirá fortalecer la parte inferior del cuerpo y los músculos centrales.",
    },
    "crack-gigi-buffon-fitness-jump-squats-title": {
        "en-us": "Squat jumps.",
        "es-mx": "Sentadillas con salto",
    },
    "crack-gigi-buffon-fitness-jump-squats-desc": {
        "en-us":
            "This variant of squats will also help you improve your balance. Try to make the falls light, to take care of your joints. Ready to start jumping?",
        "es-mx":
            "Esta variante de sentadillas también te ayudará a mejorar tu equilibrio. Procura hacer las caídas livianas, para cuidar tus articulaciones. ¿Listo para empezar a saltar?",
    },
    "crack-gigi-buffon-fitness-squats-unstable-title": {
        "en-us": "Squat over unstable surface",
        "es-mx": "Sentadilla sobre superficie inestable",
    },
    "crack-gigi-buffon-fitness-squats-unstable-desc": {
        "en-us":
            "This variant is for those goalkeepers who specifically seek to improve their balance, recreating the instability they may feel on the field.",
        "es-mx":
            "Esta variante es para aquellos porteros que específicamente busquen mejorar su equilibrio, recreando la inestabilidad que pueden sentir en la cancha.",
    },
    "crack-gigi-buffon-fitness-weighted-squats-title": {
        "en-us": "Squat with weight",
        "es-mx": "Sentadilla con peso",
    },
    "crack-gigi-buffon-fitness-weighted-squats-desc": {
        "en-us":
            "Use any medicine ball to perform this simple and effective exercise. To do it well and avoid risks of injury, pay special attention to the contraction of the muscles of the torso.",
        "es-mx":
            "Utiliza cualquier balón medicinal para realizar este simple y efectivo ejercicio. Para hacerlo bien y evitar riesgos de lesiones, pon especial atención en la contracción de los músculos del torso.",
    },
    "crack-gigi-buffon-fitness-dynamic-movement-ball-title": {
        "en-us": "Dynamic movement with the ball",
        "es-mx": "Movimiento dinámico con balón",
    },
    "crack-gigi-buffon-fitness-dynamic-movement-ball-desc": {
        "en-us":
            "This exercise is fantastic for developing leg power. If this is your first time doing it, start with sets of 6 repetitions.",
        "es-mx":
            "Este ejercicio es fantásico para desarrollar potencia en las piernas. Si es la primera vez que lo haces, empieza con series de 6 repeticiones.",
    },
    "crack-gigi-buffon-fitness-single-leg-lunge-title": {
        "en-us": "Single leg lunge",
        "es-mx": "Estocada unipodal",
    },
    "crack-gigi-buffon-fitness-single-leg-lunge-desc": {
        "en-us":
            "This exercise will help you improve your jumps during the games, with a simulation of the load before the flight.",
        "es-mx":
            "Este ejercicio te permitirá mejorar tus saltos en la cancha con la simulación de la carga antes del vuelo.",
    },
    "crack-gigi-buffon-fitness-opposite-single-leg-lunge-title": {
        "en-us": "Single leg lunge (opposite leg)",
        "es-mx": "Estocada unipodal con pierna opuesta",
    },
    "crack-gigi-buffon-fitness-opposite-single-leg-lunge-desc": {
        "en-us":
            "Improve your jumps and soften your falls with this simple exercise, which will give you strength and stability throughout the lower part of your body.",
        "es-mx":
            "Mejora tus saltos y suaviza tus caídas con este simple ejercicio, que te brindará fuerza y estabilidad en toda la parte baja de tu cuerpo.",
    },
    "crack-gigi-buffon-fitness-strength-title": {
        "en-us": "Strength",
        "es-mx": "Fuerza",
    },
    "crack-gigi-buffon-fitness-strength-desc": {
        "en-us":
            "If you are already at an advanced level of training, this exercise will be of great help to strengthen your legs as the professionals do.",
        "es-mx":
            "Si ya estás en un nivel de entrenamiento avanzado, este ejercicio te resultará de gran ayuda para fortalecer tus piernas como lo hacen los profesionales.",
    },
    "crack-gigi-buffon-fitness-toning-abs-title": {
        "en-us": "Abs toning",
        "es-mx": "Tonificación de músculos abdominales",
    },
    "crack-gigi-buffon-fitness-toning-abs-desc": {
        "en-us":
            "Strong abdominals will allow you to improve your posture and balance, stabilize your spine and prevent injuries and pain in the back.",
        "es-mx":
            "Unos abdominales fuertes te permitirán mejorar tu postura y equilibrio, estabilizar tu columna y prevenir lesiones, dolores y molestias en la espalda.",
    },
    "crack-gigi-buffon-fitness-toning-abs-side-plank-title": {
        "en-us": "Abs toning with side plank",
        "es-mx": "Tonificación de músculos abdominales con plancha lateral",
    },
    "crack-gigi-buffon-fitness-toning-abs-side-plank-desc": {
        "en-us":
            "This variation of the previous exercise is designed to strengthen the obliques -the outer part of your abs.",
        "es-mx":
            "Esta variante del ejercicio anterior está diseñado para fortalecer los abdominales oblicuos, es decir, la parte exterior de tus abdominales.",
    },
    "crack-gigi-buffon-fitness-toning-abs-bridge-title": {
        "en-us": "Abs toning - bridge",
        "es-mx": "Tonificación de músculos abdominales - Puente",
    },
    "crack-gigi-buffon-fitness-toning-abs-bridge-desc": {
        "en-us":
            "Strengthen your abdominal waist with this simple and effective exercise, in which you have to give it all in 30-second executions.",
        "es-mx":
            "Fortalece tu cintura abdominal con este simple y efectivo ejercicio, en el que debes dejarlo todo en ejecuciones de solo 30 segundos.",
    },
    "crack-gigi-buffon-fitness-toning-abs-plank-fitball-title": {
        "en-us": "Abs toning- plank with fitball",
        "es-mx": "Tonificación de músculos abdominales- Plancha con fitball",
    },
    "crack-gigi-buffon-fitness-toning-abs-plank-fitball-desc": {
        "en-us":
            "Another variant to strengthen your abdominal muscles, working in the abdominal area in its fullness while your forearms support the entire weight of your body.",
        "es-mx":
            "Otra variante para fortalecer tus músculos abdominales, logrando trabajar la zona abdominal en su plenitud mientras tus antebrazos sostienen todo el peso de tu cuerpo.",
    },
    "crack-gigi-buffon-fitness-toning-waist-back-title": {
        "en-us": "Waist and back toning",
        "es-mx": "Tonificación cintura y espalda",
    },
    "crack-gigi-buffon-fitness-toning-waist-back-desc": {
        "en-us":
            "This exercise is particularly demanding for the torso muscles. To do it, you only need a fitball. Do you dare?",
        "es-mx":
            "Este ejercicio es particularmente exigente para los músculos del torso, y para hacerlo solo necesitas de una fitball. ¿Te animas?",
    },
    "crack-gigi-buffon-fitness-stability-abs-title": {
        "en-us": "Abs stability",
        "es-mx": "Estabilidad de músculos abdominales",
    },
    "crack-gigi-buffon-fitness-stability-abs-desc": {
        "en-us": "Another essential training for the toning and stability of your abdominal area.",
        "es-mx":
            "Otro entrenamiento imprescindible para la tonificación y estabilidad de tu zona abdominal.",
    },
    "crack-gigi-buffon-fitness-stimulation-abs-title": {
        "en-us": "Abs stimulation",
        "es-mx": "Estimulación de músculos abdominales",
    },
    "crack-gigi-buffon-fitness-stimulation-abs-desc": {
        "en-us":
            "The abs can also be trained standing up. Find out how in this demanding exercise.",
        "es-mx":
            "Los abdominales también se pueden entrenar de pie. Descubre cómo en este ejercicio para la estimulación de los músculos abdominales.",
    },
    "crack-gigi-buffon-fitness-toning-body-title": {
        "en-us": "Body toning",
        "es-mx": "Tonificación del cuerpo",
    },
    "crack-gigi-buffon-fitness-toning-body-desc": {
        "en-us":
            "This exercise will tone and strengthen your abdomen in an integral way. Discover all its variants in this simple and practical training.",
        "es-mx":
            "Este ejercicio tonificará y fortalecerá tu abdomen de forma integral. Descubre todas sus variantes en este sencillo y práctico ejercicio.",
    },
    "crack-gigi-buffon-fitness-toning-shoulder-pectoral-title": {
        "en-us": "Shoulder and pectoral toning",
        "es-mx": "Tonificación de hombros y pectorales",
    },
    "crack-gigi-buffon-fitness-toning-shoulder-pectoral-desc": {
        "en-us":
            "Learn to perform perfect push-ups: this is the basic exercise to strengthen the muscles of the pectorals and shoulders.",
        "es-mx":
            "Aprende a realizar push-ups perfectos: este es el ejercicio base para fortalecer la musculatura de los pectorales y los hombros.",
    },
    "crack-gigi-buffon-fitness-bench-push-ups-title": {
        "en-us": "Push-ups over bench",
        "es-mx": "Flexiones de brazos sobre banco",
    },
    "crack-gigi-buffon-fitness-bench-push-ups-desc": {
        "en-us": "Try this dynamic push-up variation. You just need a bench!",
        "es-mx":
            "Prueba esta dinámica variante de las push-up o flexiones. ¡Solo necesitas un banco!",
    },
    "crack-gigi-buffon-fitness-push-ups-unstable-title": {
        "en-us": "Push-ups over unstable surface",
        "es-mx": "Flexiones de brazos sobre superficie inestable",
    },
    "crack-gigi-buffon-fitness-push-ups-unstable-desc": {
        "en-us":
            "Train your shoulders and pectorals like never before, working directly on all the joints of these muscles.",
        "es-mx":
            "Entrena como nunca tus hombros y pectorales, trabajando directamente sobre todas las articulaciones de esas zonas del cuerpo.",
    },
    "crack-gigi-buffon-fitness-wirst-strength-title": {
        "en-us": "Wrist strength",
        "es-mx": "Fuerza de muñecas",
    },
    "crack-gigi-buffon-fitness-wirst-strength-desc": {
        "en-us":
            "Stimulating the joints of the wrists will be useful for any sport with a ball, particularly if goalkeeping is your thing.",
        "es-mx":
            "Estimular las articulaciones de las muñecas te servirá para cualquier deporte con balón, y en particular si lo tuyo es la portería en el fútbol.",
    },
    "crack-gigi-buffon-fitness-push-ups-handles-title": {
        "en-us": "Push-ups with handles",
        "es-mx": "Flexión de brazos con manijas",
    },
    "crack-gigi-buffon-fitness-push-ups-handles-desc": {
        "en-us":
            "If you are at a medium to advanced level of training, you will love this exercise to work your upper body!",
        "es-mx":
            "Si estás en un nivel de entrenamiento medio a avanzado, ¡este ejercicio para trabajar tu tren superior te va a encantar!",
    },
    "crack-gigi-buffon-fitness-dorsal-handles-title": {
        "en-us": "Dorsal muscles with handles",
        "es-mx": "Músculos dorsales con manijas",
    },
    "crack-gigi-buffon-fitness-dorsal-handles-desc": {
        "en-us":
            "This variant of the TRX adapted to soccer proposes a series of tractions that will make you give it all in each flexion.",
        "es-mx":
            "Esta variante del TRX adaptada al fútbol propone una serie de tracciones que harán dejarlo todo en cada flexión.",
    },
    "crack-gigi-buffon-fitness-back-muscles-handles-title": {
        "en-us": "Back muscles with handles",
        "es-mx": "Músculos espalda con manijas",
    },
    "crack-gigi-buffon-fitness-back-muscles-handles-desc": {
        "en-us":
            "This exercise is for you If you want to strengthen some of the most important muscles for the physical solvency that the goalkeeper position requires.",
        "es-mx":
            "Si quieres fortalecer algunos de los músculos más importantes para la solvencia física que requiere el puesto de portero, este ejercicio es para ti.",
    },
    "crack-gigi-buffon-fitness-shoulder-back-stability-title": {
        "en-us": "Shoulder and back stability",
        "es-mx": "Estabilidad de hombros y espalda.",
    },
    "crack-gigi-buffon-fitness-shoulder-back-stability-desc": {
        "en-us":
            "No goalkeeper should skip this exercise, which strengthens the areas most exposed to shocks within the area.",
        "es-mx":
            "Ningún portero puede darse el lujo de prescindir de este ejercicio, que fortalece las zonas más sensibles a los choques dentro del área.",
    },
    "visualization-reprogram-yourself-to-grow-title": {
        "en-us": "Reprogram yourself to grow",
        "es-mx": "Reprográmate para crecer",
    },
    "visualization-reprogram-yourself-to-grow-desc": {
        "en-us":
            "Every day is a new opportunity to grow. Walk with us as you believe and create your new reality. Live the!",
        "es-mx":
            "Cada día es una nueva oportunidad para crecer. Camina con nosotros mientras crees y creas tu nueva realidad. ¡Vívela!",
    },
    "visualization-the-pleasure-of-finishing-what-you-do-title": {
        "en-us": "The pleasure of finishing what you do",
        "es-mx": "El placer de terminar lo que haces",
    },
    "visualization-the-pleasure-of-finishing-what-you-do-desc": {
        "en-us":
            "Sometimes we leave a thousand tasks unfinished and end up overwhelmed. Allow yourself to choose and prioritize one at a time, and enjoy the satisfaction of the finished work.",
        "es-mx":
            "A veces, dejamos inconclusas mil tareas y terminamos abrumados. Permítete elegir y priorizar una a la vez, y disfruta la satisfacción del trabajo terminado.",
    },
    "visualization-energizing-visualization-title": {
        "en-us": "Energizing visualization",
        "es-mx": "Visualización energizante",
    },
    "visualization-energizing-visualization-desc": {
        "en-us":
            "Without energy? Take 5 minutes and join us to recharge yourself physically and mentally. Discover your power... You will feel like new!",
        "es-mx":
            "¿Sin energía? Tómate 5 min y acompáñanos a recargarte física y mentalmente. Descubre tu poder… ¡Te sentirás como nuevo!",
    },
    "visualization-your-idol-sleeps-in-your-bed-title": {
        "en-us": "Your idol sleeps in your own bed... and it's you",
        "es-mx": "Tu ídolo duerme en tu propia cama … y eres tú",
    },
    "visualization-your-idol-sleeps-in-your-bed-desc": {
        "en-us":
            "That which you admire in others is already in you... take the first step today to discover it and let it come out! We are mirrors and only you can release all that potential.",
        "es-mx":
            "Eso que admiras en otros, ya está en ti… ¡da hoy el primer paso a descubrirlo y dejarlo salir! Somos espejos y solo tú puedes liberar todo ese potencial.",
    },
    "visualization-make-your-mind-your-best-ally-title": {
        "en-us": "Make your mind your best ally",
        "es-mx": "Haz de tu mente tu mejor aliado",
    },
    "visualization-make-your-mind-your-best-ally-desc": {
        "en-us":
            "Imagination is our greatest generator of dreams and is also our best ally to achieve them. Use it to your advantage, visualize yourself succeeding… and achieve it!",
        "es-mx":
            "La imaginación es nuestro más grande generador de sueños y es también nuestra mejor aliada para conseguirlos. ¡Úsala a tu favor, visualízate triunfando… y lógralo!",
    },
    "visualization-visualize-it-first-in-your-mind-title": {
        "en-us": "Visualize it first in your mind",
        "es-mx": "Visualízalo primero en tu mente",
    },
    "visualization-visualize-it-first-in-your-mind-desc": {
        "en-us":
            "When we set unattainable goals, frustration overtakes us and we end up thinking that they are all unattainable. Breathe, stop. Everything can be achieved if we make a plan and trace the route in our minds.",
        "es-mx":
            "Cuando nos ponemos metas inalcanzables, la frustración nos alcanza y acabamos pensando que todas lo son. Respira, haz un alto. Todo se puede lograr si hacemos un plan y trazamos la ruta en nuestra mente.",
    },
    "visualization-change-your-habits-and-transform-your-life-title": {
        "en-us": "Change your habits and transform your life",
        "es-mx": "Cambia tus hábitos y transforma tu vida",
    },
    "visualization-change-your-habits-and-transform-your-life-desc": {
        "en-us":
            "Without realizing it, our habits can drive us or limit us. It is up to us to choose those that bring us closer to our goals and allow us to achieve that well-being that we so long for.",
        "es-mx":
            "Sin darnos cuenta, nuestros hábitos pueden impulsarnos o limitarnos. Esta en nosotros ir eligiendo aquellos que nos acerquen a nuestras metas y nos permitan alcanzar ese bienestar que tanto anhelamos.",
    },
    "visualization-embrace-your-strengths-title": {
        "en-us": "Embrace your strengths",
        "es-mx": "Abraza tus fortalezas",
    },
    "visualization-embrace-your-strengths-desc": {
        "en-us":
            "Each and every one of us comes with a “tool kit” to achieve what we set out to do. Not everyone is the same and that enriches us and makes us unique. Learn to use your kit, complement it and build your dreams one day at a time.",
        "es-mx":
            "Todos y cada uno de nosotros venimos con un “kit de herramientas” para lograr lo que nos proponemos. No todos son iguales y eso nos enriquece y nos hace únicos. Aprende a usar tu kit, compleméntalo y construye tus sueños un día a la vez.",
    },
    "visualization-how-to-give-the-best-to-your-team-title": {
        "en-us": "How to give the best to your team",
        "es-mx": "Cómo darle lo mejor a tu equipo",
    },
    "visualization-how-to-give-the-best-to-your-team-desc": {
        "en-us":
            "Just as a Swiss watch requires even the smallest piece to tell the exact time, in any team each member plays a role and without them, we would not meet our objectives. Recognize your value and that of your teammates... Live the best game of your life together!",
        "es-mx":
            "Así como un reloj suizo requiere hasta la pieza más pequeña para dar la hora exacta, en cualquier equipo cada integrante juega un papel y sin ellos, no cumpliríamos los objetivos. Reconoce tu valor y el de tus compañeros…  ¡Vivan juntos el mejor juego de su vida!",
    },
    "visualization-learn-to-handle-criticism-title": {
        "en-us": "Learn to handle criticism",
        "es-mx": "Aprende a manejar las críticas",
    },
    "visualization-learn-to-handle-criticism-desc": {
        "en-us":
            "Nobody is perfect! We have all been criticized: it hurts and can hurt our self-esteem. Don't let someone else's negative comments define you and always focus on your qualities and talents. Reignite your inner light and shine for everyone!",
        "es-mx":
            "¡Nadie es perfecto! A todos nos han criticado: duele y puede lastimar nuestra autoestima. No permitas que los comentarios negativos de alguien más te definan y enfócate siempre en tus cualidades y talentos. ¡Reenciende tu luz interior y brilla para todos!",
    },
    "test-video-data-visualization-reprogram-yourself-to-grow": {
        "en-us": "Visualization ∙ 5:40 mins",
        "es-mx": "Visualización ∙ 5:40 mins",
    },
    "test-video-data-visualization-the-pleasure-of-finishing-what-you-do": {
        "en-us": "Visualization ∙ 5:37 mins",
        "es-mx": "Visualización ∙ 5:37 mins",
    },
    "test-video-data-visualization-energizing-visualization": {
        "en-us": "Visualization ∙ 5:40 mins",
        "es-mx": "Visualización ∙ 5:40 mins",
    },
    "test-video-data-visualization-your-idol-sleeps-in-your-bed": {
        "en-us": "Visualization ∙ 4:35 mins",
        "es-mx": "Visualización ∙ 4:35 mins",
    },
    "test-video-data-visualization-make-your-mind-your-best-ally": {
        "en-us": "Visualization ∙ 6:45 mins",
        "es-mx": "Visualización ∙ 6:45 mins",
    },
    "test-video-data-visualization-visualize-it-first-in-your-mind": {
        "en-us": "Visualization ∙ 8:18 mins",
        "es-mx": "Visualización ∙ 8:18 mins",
    },
    "test-video-data-visualization-change-your-habits-and-transform-your-life": {
        "en-us": "Visualization ∙ 8:25 mins",
        "es-mx": "Visualización ∙ 8:25 mins",
    },
    "test-video-data-visualization-embrace-your-strengths": {
        "en-us": "Visualization ∙ 8:17 mins",
        "es-mx": "Visualización ∙ 8:17 mins",
    },
    "test-video-data-visualization-how-to-give-the-best-to-your-team": {
        "en-us": "Visualization ∙ 6:36 mins",
        "es-mx": "Visualización ∙ 6:36 mins",
    },
    "test-video-data-visualization-learn-to-handle-criticism": {
        "en-us": "Visualization ∙ 6:29 mins",
        "es-mx": "Visualización ∙ 6:29 mins",
    },
    "test-video-data-rafa-mindset-1": {
        "en-us": "Rafael Márquez ∙ 2:56 mins",
        "es-mx": "Rafael Márquez ∙ 2:56 mins",
    },
    "test-video-data-rafa-mindset-2": {
        "en-us": "Rafael Márquez ∙ 3:47 mins",
        "es-mx": "Rafael Márquez ∙ 3:47 mins",
    },
    "test-video-data-rafa-mindset-3": {
        "en-us": "Rafael Márquez ∙ 3:31 mins",
        "es-mx": "Rafael Márquez ∙ 3:31 mins",
    },
    "test-video-data-rafa-mindset-4": {
        "en-us": "Rafael Márquez ∙ 2:55 mins",
        "es-mx": "Rafael Márquez ∙ 2:55 mins",
    },
    "test-video-data-rafa-skills-headshot": {
        "en-us": "Rafael Márquez ∙ 4:30 mins",
        "es-mx": "Rafael Márquez ∙ 4:30 mins",
    },
    "test-video-data-rafa-skills-long-shot": {
        "en-us": "Rafael Márquez ∙ 3:43 mins",
        "es-mx": "Rafael Márquez ∙ 3:43 mins",
    },
    "test-video-data-rafa-skills-free-kick": {
        "en-us": "Rafael Márquez ∙ 3:41 mins",
        "es-mx": "Rafael Márquez ∙ 3:41 mins",
    },
    "test-video-data-rafa-skills-art-defending-1": {
        "en-us": "Rafael Márquez ∙ 3:04 mins",
        "es-mx": "Rafael Márquez ∙ 3:04 mins",
    },
    "test-video-data-rafa-skills-art-defending-2": {
        "en-us": "Rafael Márquez ∙ 3:15 mins",
        "es-mx": "Rafael Márquez ∙ 3:15 mins",
    },
    "test-video-data-rafa-stories-1": {
        "en-us": "Rafael Márquez ∙ 6:40 mins",
        "es-mx": "Rafael Márquez ∙ 6:40 mins",
    },
    "test-video-data-rafa-stories-2": {
        "en-us": "Rafael Márquez ∙ 3:36 mins",
        "es-mx": "Rafael Márquez ∙ 3:36 mins",
    },
    "test-video-data-rafa-stories-3": {
        "en-us": "Rafael Márquez ∙ 6:01 mins",
        "es-mx": "Rafael Márquez ∙ 6:01 mins",
    },
    "test-video-data-rafa-stories-4": {
        "en-us": "Rafael Márquez ∙ 4:11 mins",
        "es-mx": "Rafael Márquez ∙ 4:11 mins",
    },
    "test-video-data-pavel-mindset-1": {
        "en-us": "Pável Pardo ∙ 3:34 mins",
        "es-mx": "Pável Pardo ∙ 3:34 mins",
    },
    "test-video-data-pavel-mindset-2": {
        "en-us": "Pável Pardo ∙ 3:37 mins",
        "es-mx": "Pável Pardo ∙ 3:37 mins",
    },
    "test-video-data-pavel-mindset-3": {
        "en-us": "Pável Pardo ∙ 2:46 mins",
        "es-mx": "Pável Pardo ∙ 2:46 mins",
    },
    "test-video-data-pavel-mindset-4": {
        "en-us": "Pável Pardo ∙ 3:00 mins",
        "es-mx": "Pável Pardo ∙ 3:00 mins",
    },
    "test-video-data-pavel-skills-bike": {
        "en-us": "Pável Pardo ∙ 0:30 mins",
        "es-mx": "Pável Pardo ∙ 0:30 mins",
    },
    "test-video-data-pavel-skills-outside-shot": {
        "en-us": "Pável Pardo ∙ 0:41 mins",
        "es-mx": "Pável Pardo ∙ 0:41 mins",
    },
    "test-video-data-pavel-skills-pass-control-int": {
        "en-us": "Pável Pardo ∙ 0:53 mins",
        "es-mx": "Pável Pardo ∙ 0:53 mins",
    },
    "test-video-data-pavel-skills-pass-control-tri": {
        "en-us": "Pável Pardo ∙ 0:34 mins",
        "es-mx": "Pável Pardo ∙ 0:34 mins",
    },
    "test-video-data-pavel-skills-poison-dart": {
        "en-us": "Pável Pardo ∙ 1:15 mins",
        "es-mx": "Pável Pardo ∙ 1:15 mins",
    },
    "test-video-data-pavel-skills-zig-zag": {
        "en-us": "Pável Pardo ∙ 0:21 mins",
        "es-mx": "Pável Pardo ∙ 0:21 mins",
    },
    "test-video-data-pavel-stories-1": {
        "en-us": "Pável Pardo ∙ 4:44 mins",
        "es-mx": "Pável Pardo ∙ 4:44 mins",
    },
    "test-video-data-pavel-stories-2": {
        "en-us": "Pável Pardo ∙ 4:30 mins",
        "es-mx": "Pável Pardo ∙ 4:30 mins",
    },
    "test-video-data-pavel-stories-3": {
        "en-us": "Pável Pardo ∙ 3:04 mins",
        "es-mx": "Pável Pardo ∙ 3:04 mins",
    },
    "test-video-data-pavel-stories-4": {
        "en-us": "Pável Pardo ∙ 5:41 mins",
        "es-mx": "Pável Pardo ∙ 5:41 mins",
    },
    "test-video-data-jo-fitness-3x3-shuttle-run": {
        "en-us": "Coach Jo Canales ∙ 0:38 mins",
        "es-mx": "Coach Jo Canales ∙ 0:38 mins",
    },
    "test-video-data-jo-fitness-ball-taps": {
        "en-us": "Coach Jo Canales ∙ 0:20 mins",
        "es-mx": "Coach Jo Canales ∙ 0:20 mins",
    },
    "test-video-data-jo-fitness-ball-twist": {
        "en-us": "Coach Jo Canales ∙ 0:39 mins",
        "es-mx": "Coach Jo Canales ∙ 0:39 mins",
    },
    "test-video-data-jo-fitness-banded-pulls": {
        "en-us": "Coach Jo Canales ∙ 0:41 mins",
        "es-mx": "Coach Jo Canales ∙ 0:41 mins",
    },
    "test-video-data-jo-fitness-bicep-curls-db": {
        "en-us": "Coach Jo Canales ∙ 1:01 mins",
        "es-mx": "Coach Jo Canales ∙ 1:01 mins",
    },
    "test-video-data-jo-fitness-bicep-curls-object": {
        "en-us": "Coach Jo Canales ∙ 0:48 mins",
        "es-mx": "Coach Jo Canales ∙ 0:48 mins",
    },
    "test-video-data-jo-fitness-bicycle-abs": {
        "en-us": "Coach Jo Canales ∙ 0:53 mins",
        "es-mx": "Coach Jo Canales ∙ 0:53 mins",
    },
    "test-video-data-jo-fitness-box-dips": {
        "en-us": "Coach Jo Canales ∙ 0:45 mins",
        "es-mx": "Coach Jo Canales ∙ 0:45 mins",
    },
    "test-video-data-jo-fitness-broad-jump": {
        "en-us": "Coach Jo Canales ∙ 0:44 mins",
        "es-mx": "Coach Jo Canales ∙ 0:44 mins",
    },
    "test-video-data-jo-fitness-burpees": {
        "en-us": "Coach Jo Canales ∙ 0:55 mins",
        "es-mx": "Coach Jo Canales ∙ 0:55 mins",
    },
    "test-video-data-jo-fitness-c-ups": {
        "en-us": "Coach Jo Canales ∙ 0:37 mins",
        "es-mx": "Coach Jo Canales ∙ 0:37 mins",
    },
    "test-video-data-jo-fitness-deadlift": {
        "en-us": "Coach Jo Canales ∙ 1:00 mins",
        "es-mx": "Coach Jo Canales ∙ 1:00 mins",
    },
    "test-video-data-jo-fitness-floor-bench-db": {
        "en-us": "Coach Jo Canales ∙ 0:46 mins",
        "es-mx": "Coach Jo Canales ∙ 0:46 mins",
    },
    "test-video-data-jo-fitness-floor-press-object": {
        "en-us": "Coach Jo Canales ∙ 0:53 mins",
        "es-mx": "Coach Jo Canales ∙ 0:53 mins",
    },
    "test-video-data-jo-fitness-flutter-kicks": {
        "en-us": "Coach Jo Canales ∙ 0:39 mins",
        "es-mx": "Coach Jo Canales ∙ 0:39 mins",
    },
    "test-video-data-jo-fitness-glute-bridge": {
        "en-us": "Coach Jo Canales ∙ 0:37 mins",
        "es-mx": "Coach Jo Canales ∙ 0:37 mins",
    },
    "test-video-data-jo-fitness-good-mornings": {
        "en-us": "Coach Jo Canales ∙ 0:39 mins",
        "es-mx": "Coach Jo Canales ∙ 0:39 mins",
    },
    "test-video-data-jo-fitness-half-burpees": {
        "en-us": "Coach Jo Canales ∙ 0:51 mins",
        "es-mx": "Coach Jo Canales ∙ 0:51 mins",
    },
    "test-video-data-jo-fitness-high-knees-disp": {
        "en-us": "Coach Jo Canales ∙ 0:34 mins",
        "es-mx": "Coach Jo Canales ∙ 0:34 mins",
    },
    "test-video-data-jo-fitness-high-knees": {
        "en-us": "Coach Jo Canales ∙ 0:21 mins",
        "es-mx": "Coach Jo Canales ∙ 0:21 mins",
    },
    "test-video-data-jo-fitness-high-pulls-object": {
        "en-us": "Coach Jo Canales ∙ 0:47 mins",
        "es-mx": "Coach Jo Canales ∙ 0:47 mins",
    },
    "test-video-data-jo-fitness-inchworm-push-up": {
        "en-us": "Coach Jo Canales ∙ 0:59 mins",
        "es-mx": "Coach Jo Canales ∙ 0:59 mins",
    },
    "test-video-data-jo-fitness-inchworm": {
        "en-us": "Coach Jo Canales ∙ 0:57 mins",
        "es-mx": "Coach Jo Canales ∙ 0:57 mins",
    },
    "test-video-data-jo-fitness-in-out": {
        "en-us": "Coach Jo Canales ∙ 0:48 mins",
        "es-mx": "Coach Jo Canales ∙ 0:48 mins",
    },
    "test-video-data-jo-fitness-jumping-jacks": {
        "en-us": "Coach Jo Canales ∙ 0:41 mins",
        "es-mx": "Coach Jo Canales ∙ 0:41 mins",
    },
    "test-video-data-jo-fitness-jumping-lunges": {
        "en-us": "Coach Jo Canales ∙ 0:53 mins",
        "es-mx": "Coach Jo Canales ∙ 0:53 mins",
    },
    "test-video-data-jo-fitness-jump-squat": {
        "en-us": "Coach Jo Canales ∙ 0:44 mins",
        "es-mx": "Coach Jo Canales ∙ 0:44 mins",
    },
    "test-video-data-jo-fitness-lateral-jumps": {
        "en-us": "Coach Jo Canales ∙ 0:23 mins",
        "es-mx": "Coach Jo Canales ∙ 0:23 mins",
    },
    "test-video-data-jo-fitness-lunges-db": {
        "en-us": "Coach Jo Canales ∙ 0:52 mins",
        "es-mx": "Coach Jo Canales ∙ 0:52 mins",
    },
    "test-video-data-jo-fitness-lunges-object": {
        "en-us": "Coach Jo Canales ∙ 0:49 mins",
        "es-mx": "Coach Jo Canales ∙ 0:49 mins",
    },
    "test-video-data-jo-fitness-lunges": {
        "en-us": "Coach Jo Canales ∙ 0:48 mins",
        "es-mx": "Coach Jo Canales ∙ 0:48 mins",
    },
    "test-video-data-jo-fitness-mountain-climbers": {
        "en-us": "Coach Jo Canales ∙ 0:45 mins",
        "es-mx": "Coach Jo Canales ∙ 0:45 mins",
    },
    "test-video-data-jo-fitness-pendlay-row-db": {
        "en-us": "Coach Jo Canales ∙ 0:44 mins",
        "es-mx": "Coach Jo Canales ∙ 0:44 mins",
    },
    "test-video-data-jo-fitness-pendlay-row-object": {
        "en-us": "Coach Jo Canales ∙ 0:45 mins",
        "es-mx": "Coach Jo Canales ∙ 0:45 mins",
    },
    "test-video-data-jo-fitness-plank-dynamic": {
        "en-us": "Coach Jo Canales ∙ 1:01 mins",
        "es-mx": "Coach Jo Canales ∙ 1:01 mins",
    },
    "test-video-data-jo-fitness-plank-jacks": {
        "en-us": "Coach Jo Canales ∙ 0:45 mins",
        "es-mx": "Coach Jo Canales ∙ 0:45 mins",
    },
    "test-video-data-jo-fitness-plank-knee-elbow": {
        "en-us": "Coach Jo Canales ∙ 1:00 mins",
        "es-mx": "Coach Jo Canales ∙ 1:00 mins",
    },
    "test-video-data-jo-fitness-plank": {
        "en-us": "Coach Jo Canales ∙ 0:38 mins",
        "es-mx": "Coach Jo Canales ∙ 0:38 mins",
    },
    "test-video-data-jo-fitness-plank-toe-touches": {
        "en-us": "Coach Jo Canales ∙ 0:59 mins",
        "es-mx": "Coach Jo Canales ∙ 0:59 mins",
    },
    "test-video-data-jo-fitness-plank-tricep-extension": {
        "en-us": "Coach Jo Canales ∙ 0:51 mins",
        "es-mx": "Coach Jo Canales ∙ 0:51 mins",
    },
    "test-video-data-jo-fitness-pull-ups": {
        "en-us": "Coach Jo Canales ∙ 0:50 mins",
        "es-mx": "Coach Jo Canales ∙ 0:50 mins",
    },
    "test-video-data-jo-fitness-push-ups": {
        "en-us": "Coach Jo Canales ∙ 0:55 mins",
        "es-mx": "Coach Jo Canales ∙ 0:55 mins",
    },
    "test-video-data-jo-fitness-run": {
        "en-us": "Coach Jo Canales ∙ 0:47 mins",
        "es-mx": "Coach Jo Canales ∙ 0:47 mins",
    },
    "test-video-data-jo-fitness-russian-twist": {
        "en-us": "Coach Jo Canales ∙ 0:35 mins",
        "es-mx": "Coach Jo Canales ∙ 0:35 mins",
    },
    "test-video-data-jo-fitness-scissors": {
        "en-us": "Coach Jo Canales ∙ 0:42 mins",
        "es-mx": "Coach Jo Canales ∙ 0:42 mins",
    },
    "test-video-data-jo-fitness-shoulder-db": {
        "en-us": "Coach Jo Canales ∙ 0:53 mins",
        "es-mx": "Coach Jo Canales ∙ 0:53 mins",
    },
    "test-video-data-jo-fitness-shoulder-object": {
        "en-us": "Coach Jo Canales ∙ 0:49 mins",
        "es-mx": "Coach Jo Canales ∙ 0:49 mins",
    },
    "test-video-data-jo-fitness-shoulder-taps": {
        "en-us": "Coach Jo Canales ∙ 1:01 mins",
        "es-mx": "Coach Jo Canales ∙ 1:01 mins",
    },
    "test-video-data-jo-fitness-single-leg-bridge": {
        "en-us": "Coach Jo Canales ∙ 0:48 mins",
        "es-mx": "Coach Jo Canales ∙ 0:48 mins",
    },
    "test-video-data-jo-fitness-single-leg-deadlift": {
        "en-us": "Coach Jo Canales ∙ 1:14 mins",
        "es-mx": "Coach Jo Canales ∙ 1:14 mins",
    },
    "test-video-data-jo-fitness-single-unders": {
        "en-us": "Coach Jo Canales ∙ 0:53 mins",
        "es-mx": "Coach Jo Canales ∙ 0:53 mins",
    },
    "test-video-data-jo-fitness-sit-crunches": {
        "en-us": "Coach Jo Canales ∙ 0:44 mins",
        "es-mx": "Coach Jo Canales ∙ 0:44 mins",
    },
    "test-video-data-jo-fitness-split-squat": {
        "en-us": "Coach Jo Canales ∙ 0:49 mins",
        "es-mx": "Coach Jo Canales ∙ 0:49 mins",
    },
    "test-video-data-jo-fitness-squats-db": {
        "en-us": "Coach Jo Canales ∙ 1:11 mins",
        "es-mx": "Coach Jo Canales ∙ 1:11 mins",
    },
    "test-video-data-jo-fitness-squats-object": {
        "en-us": "Coach Jo Canales ∙ 0:54 mins",
        "es-mx": "Coach Jo Canales ∙ 0:54 mins",
    },
    "test-video-data-jo-fitness-squats": {
        "en-us": "Coach Jo Canales ∙ 0:56 mins",
        "es-mx": "Coach Jo Canales ∙ 0:56 mins",
    },
    "test-video-data-jo-fitness-step-ups": {
        "en-us": "Coach Jo Canales ∙ 0:58 mins",
        "es-mx": "Coach Jo Canales ∙ 0:58 mins",
    },
    "test-video-data-jo-fitness-superman": {
        "en-us": "Coach Jo Canales ∙ 0:35 mins",
        "es-mx": "Coach Jo Canales ∙ 0:35 mins",
    },
    "test-video-data-jo-fitness-trx-rows": {
        "en-us": "Coach Jo Canales ∙ 0:56 mins",
        "es-mx": "Coach Jo Canales ∙ 0:56 mins",
    },
    "test-video-data-jo-fitness-uneven-push-ups": {
        "en-us": "Coach Jo Canales ∙ 0:51 mins",
        "es-mx": "Coach Jo Canales ∙ 0:51 mins",
    },
    "test-video-data-jo-fitness-walking-lunges": {
        "en-us": "Coach Jo Canales ∙ 0:44 mins",
        "es-mx": "Coach Jo Canales ∙ 0:44 mins",
    },
    "test-video-data-jo-fitness-wall-sit": {
        "en-us": "Coach Jo Canales ∙ 0:47 mins",
        "es-mx": "Coach Jo Canales ∙ 0:47 mins",
    },
    "test-video-data-jo-fitness-cool-down": {
        "en-us": "Coach Jo Canales ∙ 6:43 mins",
        "es-mx": "Coach Jo Canales ∙ 6:43 mins",
    },
    "test-video-data-jo-fitness-pre-game-activation": {
        "en-us": "Coach Jo Canales ∙ 4:06 mins",
        "es-mx": "Coach Jo Canales ∙ 4:06 mins",
    },
    "test-video-data-jo-fitness-warm-up": {
        "en-us": "Coach Jo Canales ∙ 5:19 mins",
        "es-mx": "Coach Jo Canales ∙ 5:19 mins",
    },
    "test-video-data-jo-skills-displacement": {
        "en-us": "Coach Jo Canales ∙ 0:41 mins",
        "es-mx": "Coach Jo Canales ∙ 0:41 mins",
    },
    "test-video-data-jo-skills-pull-ups-instep": {
        "en-us": "Coach Jo Canales ∙ 0:28 mins",
        "es-mx": "Coach Jo Canales ∙ 0:28 mins",
    },
    "test-video-data-jo-skills-pull-ups-thigh": {
        "en-us": "Coach Jo Canales ∙ 0:33 mins",
        "es-mx": "Coach Jo Canales ∙ 0:33 mins",
    },
    "test-video-data-jo-skills-control-insteps-thighs": {
        "en-us": "Coach Jo Canales ∙ 0:35 mins",
        "es-mx": "Coach Jo Canales ∙ 0:35 mins",
    },
    "test-video-data-jo-skills-driving-lateral": {
        "en-us": "Coach Jo Canales ∙ 0:45 mins",
        "es-mx": "Coach Jo Canales ∙ 0:45 mins",
    },
    "test-video-data-jo-skills-driving-free": {
        "en-us": "Coach Jo Canales ∙ 0:51 mins",
        "es-mx": "Coach Jo Canales ∙ 0:51 mins",
    },
    "test-video-data-jo-skills-control-targeted": {
        "en-us": "Coach Jo Canales ∙ 1:07 mins",
        "es-mx": "Coach Jo Canales ∙ 1:07 mins",
    },
    "test-video-data-jo-skills-roulette-zidane": {
        "en-us": "Coach Jo Canales ∙ 0:38 mins",
        "es-mx": "Coach Jo Canales ∙ 0:38 mins",
    },
    "test-video-data-jo-skills-pass": {
        "en-us": "Coach Jo Canales ∙ 0:53 mins",
        "es-mx": "Coach Jo Canales ∙ 0:53 mins",
    },
    "test-video-data-jo-skills-pass-alternate": {
        "en-us": "Coach Jo Canales ∙ 0:45 mins",
        "es-mx": "Coach Jo Canales ∙ 0:45 mins",
    },
    "test-video-data-jo-skills-pass-control-alt": {
        "en-us": "Coach Jo Canales ∙ 0:46 mins",
        "es-mx": "Coach Jo Canales ∙ 0:46 mins",
    },
    "test-video-data-jo-skills-reception-chest": {
        "en-us": "Coach Jo Canales ∙ 0:24 mins",
        "es-mx": "Coach Jo Canales ∙ 0:24 mins",
    },
    "test-video-data-jo-skills-": {
        "en-us": "Coach Jo Canales ∙ 0:19 mins",
        "es-mx": "Coach Jo Canales ∙ 0:19 mins",
    },
    "test-video-data-jo-skills-shot-outside-box": {
        "en-us": "Coach Jo Canales ∙ 0:28 mins",
        "es-mx": "Coach Jo Canales ∙ 0:28 mins",
    },
    "test-video-data-jo-skills-volley-hitting": {
        "en-us": "Coach Jo Canales ∙ 0:36 mins",
        "es-mx": "Coach Jo Canales ∙ 0:36 mins",
    },
    "test-video-data-jo-skills-pull-ups-inner": {
        "en-us": "Coach Jo Canales ∙ 0:36 mins",
        "es-mx": "Coach Jo Canales ∙ 0:36 mins",
    },
    "test-video-data-jo-skills-pull-ups-inner-instep": {
        "en-us": "Coach Jo Canales ∙ 0:34 mins",
        "es-mx": "Coach Jo Canales ∙ 0:34 mins",
    },
    "test-video-data-jo-skills-zig-zag": {
        "en-us": "Coach Jo Canales ∙ 0:25 mins",
        "es-mx": "Coach Jo Canales ∙ 0:25 mins",
    },
    "test-video-data-carlos-mindset-1": {
        "en-us": "Carlos Salcido ∙ 3:15 mins",
        "es-mx": "Carlos Salcido ∙ 3:15 mins",
    },
    "test-video-data-carlos-mindset-2": {
        "en-us": "Carlos Salcido ∙ 4:19 mins",
        "es-mx": "Carlos Salcido ∙ 4:19 mins",
    },
    "test-video-data-carlos-mindset-3": {
        "en-us": "Carlos Salcido ∙ 2:49 mins",
        "es-mx": "Carlos Salcido ∙ 2:49 mins",
    },
    "test-video-data-carlos-mindset-4": {
        "en-us": "Carlos Salcido ∙ 3:22 mins",
        "es-mx": "Carlos Salcido ∙ 3:22 mins",
    },
    "test-video-data-carlos-skills-outline": {
        "en-us": "Carlos Salcido ∙ 4:36 mins",
        "es-mx": "Carlos Salcido ∙ 4:36 mins",
    },
    "test-video-data-carlos-skills-approach-speed": {
        "en-us": "Carlos Salcido ∙ 4:22 mins",
        "es-mx": "Carlos Salcido ∙ 4:22 mins",
    },
    "test-video-data-carlos-stories-1": {
        "en-us": "Carlos Salcido ∙ 6:02 mins",
        "es-mx": "Carlos Salcido ∙ 6:02 mins",
    },
    "test-video-data-carlos-stories-2": {
        "en-us": "Carlos Salcido ∙ 3:05 mins",
        "es-mx": "Carlos Salcido ∙ 3:05 mins",
    },
    "test-video-data-carlos-stories-3": {
        "en-us": "Carlos Salcido ∙ 6:43 mins",
        "es-mx": "Carlos Salcido ∙ 6:43 mins",
    },
    "test-video-data-carlos-stories-4": {
        "en-us": "Carlos Salcido ∙ 4:19 mins",
        "es-mx": "Carlos Salcido ∙ 4:19 mins",
    },
    "test-video-data-jared-mindset-1": {
        "en-us": "Jared Borgetti ∙ 3:48 mins",
        "es-mx": "Jared Borgetti ∙ 3:48 mins",
    },
    "test-video-data-jared-mindset-2": {
        "en-us": "Jared Borgetti ∙ 3:03 mins",
        "es-mx": "Jared Borgetti ∙ 3:03 mins",
    },
    "test-video-data-jared-mindset-3": {
        "en-us": "Jared Borgetti ∙ 3:07 mins",
        "es-mx": "Jared Borgetti ∙ 3:07 mins",
    },
    "test-video-data-jared-mindset-4": {
        "en-us": "Jared Borgetti ∙ 2:48 mins",
        "es-mx": "Jared Borgetti ∙ 2:48 mins",
    },
    "test-video-data-jared-skills-header": {
        "en-us": "Jared Borgetti ∙ 4:56 mins",
        "es-mx": "Jared Borgetti ∙ 4:56 mins",
    },
    "test-video-data-jared-skills-scorer": {
        "en-us": "Jared Borgetti ∙ 2:40 mins",
        "es-mx": "Jared Borgetti ∙ 2:40 mins",
    },
    "test-video-data-jared-skills-art-forward": {
        "en-us": "Jared Borgetti ∙ 2:44 mins",
        "es-mx": "Jared Borgetti ∙ 2:44 mins",
    },
    "test-video-data-jared-stories-1": {
        "en-us": "Jared Borgetti ∙ 4:40 mins",
        "es-mx": "Jared Borgetti ∙ 4:40 mins",
    },
    "test-video-data-jared-stories-2": {
        "en-us": "Jared Borgetti ∙ 4:32 mins",
        "es-mx": "Jared Borgetti ∙ 4:32 mins",
    },
    "test-video-data-jared-stories-3": {
        "en-us": "Jared Borgetti ∙ 8:11 mins",
        "es-mx": "Jared Borgetti ∙ 8:11 mins",
    },
    "test-video-data-jared-stories-4": {
        "en-us": "Jared Borgetti ∙ 4:52 mins",
        "es-mx": "Jared Borgetti ∙ 4:52 mins",
    },
    "test-video-data-charlyn-mindset-1": {
        "en-us": "Charlyn Corral ∙ 5:10 mins",
        "es-mx": "Charlyn Corral ∙ 5:10 mins",
    },
    "test-video-data-charlyn-mindset-2": {
        "en-us": "Charlyn Corral ∙ 5:11 mins",
        "es-mx": "Charlyn Corral ∙ 5:11 mins",
    },
    "test-video-data-charlyn-mindset-3": {
        "en-us": "Charlyn Corral ∙ 3:45 mins",
        "es-mx": "Charlyn Corral ∙ 3:45 mins",
    },
    "test-video-data-charlyn-mindset-4": {
        "en-us": "Charlyn Corral ∙ 2:56 mins",
        "es-mx": "Charlyn Corral ∙ 2:56 mins",
    },
    "test-video-data-charlyn-skills-cutting-hitting-ball": {
        "en-us": "Charlyn Corral ∙ 2:34 mins",
        "es-mx": "Charlyn Corral ∙ 2:34 mins",
    },
    "test-video-data-charlyn-skills-little-hat": {
        "en-us": "Charlyn Corral ∙ 1:25 mins",
        "es-mx": "Charlyn Corral ∙ 1:25 mins",
    },
    "test-video-data-charlyn-stories-1": {
        "en-us": "Charlyn Corral ∙ 6:52 mins",
        "es-mx": "Charlyn Corral ∙ 6:52 mins",
    },
    "test-video-data-charlyn-stories-2": {
        "en-us": "Charlyn Corral ∙ 3:10 mins",
        "es-mx": "Charlyn Corral ∙ 3:10 mins",
    },
    "test-video-data-charlyn-stories-3": {
        "en-us": "Charlyn Corral ∙ 4:00 mins",
        "es-mx": "Charlyn Corral ∙ 4:00 mins",
    },
    "test-video-data-charlyn-stories-4": {
        "en-us": "Charlyn Corral ∙ 3:22 mins",
        "es-mx": "Charlyn Corral ∙ 3:22 mins",
    },
    "test-video-data-norma-mindset-1": {
        "en-us": "Norma Palafox ∙ 5:48 mins",
        "es-mx": "Norma Palafox ∙ 5:48 mins",
    },
    "test-video-data-norma-mindset-2": {
        "en-us": "Norma Palafox ∙ 5:04 mins",
        "es-mx": "Norma Palafox ∙ 5:04 mins",
    },
    "test-video-data-norma-mindset-3": {
        "en-us": "Norma Palafox ∙ 3:43 mins",
        "es-mx": "Norma Palafox ∙ 3:43 mins",
    },
    "test-video-data-norma-mindset-4": {
        "en-us": "Norma Palafox ∙ 4:49 mins",
        "es-mx": "Norma Palafox ∙ 4:49 mins",
    },
    "test-video-data-norma-skills-speed": {
        "en-us": "Norma Palafox ∙ 0:41 mins",
        "es-mx": "Norma Palafox ∙ 0:41 mins",
    },
    "test-video-data-norma-skills-dribbling": {
        "en-us": "Norma Palafox ∙ 1:49 mins",
        "es-mx": "Norma Palafox ∙ 1:49 mins",
    },
    "test-video-data-norma-skills-walls": {
        "en-us": "Norma Palafox ∙ 1:48 mins",
        "es-mx": "Norma Palafox ∙ 1:48 mins",
    },
    "test-video-data-norma-stories-1": {
        "en-us": "Norma Palafox ∙ 7:49 mins",
        "es-mx": "Norma Palafox ∙ 7:49 mins",
    },
    "test-video-data-norma-stories-2": {
        "en-us": "Norma Palafox ∙ 12:59 mins",
        "es-mx": "Norma Palafox ∙ 12:59 mins",
    },
    "test-video-data-oswaldo-mindset-1": {
        "en-us": "Oswaldo Sánchez ∙ 4:04 mins",
        "es-mx": "Oswaldo Sánchez ∙ 4:04 mins",
    },
    "test-video-data-oswaldo-mindset-2": {
        "en-us": "Oswaldo Sánchez ∙ 4:09 mins",
        "es-mx": "Oswaldo Sánchez ∙ 4:09 mins",
    },
    "test-video-data-oswaldo-mindset-3": {
        "en-us": "Oswaldo Sánchez ∙ 3:23 mins",
        "es-mx": "Oswaldo Sánchez ∙ 3:23 mins",
    },
    "test-video-data-oswaldo-mindset-4": {
        "en-us": "Oswaldo Sánchez ∙ 4:47 mins",
        "es-mx": "Oswaldo Sánchez ∙ 4:47 mins",
    },
    "test-video-data-oswaldo-stories-1": {
        "en-us": "Oswaldo Sánchez ∙ 5:53 mins",
        "es-mx": "Oswaldo Sánchez ∙ 5:53 mins",
    },
    "test-video-data-oswaldo-stories-2": {
        "en-us": "Oswaldo Sánchez ∙ 5:17 mins",
        "es-mx": "Oswaldo Sánchez ∙ 5:17 mins",
    },
    "test-video-data-oswaldo-stories-3": {
        "en-us": "Oswaldo Sánchez ∙ 6:54 mins",
        "es-mx": "Oswaldo Sánchez ∙ 6:54 mins",
    },
    "test-video-data-oswaldo-stories-4": {
        "en-us": "Oswaldo Sánchez ∙ 7:36 mins",
        "es-mx": "Oswaldo Sánchez ∙ 7:36 mins",
    },
    "test-video-data-gigi-stories-1": {
        "en-us": "Buffon Academy ∙ 3:03 mins",
        "es-mx": "Buffon Academy ∙ 3:03 mins",
    },
    "test-video-data-gigi-stories-2": {
        "en-us": "Buffon Academy ∙ 3:19 mins",
        "es-mx": "Buffon Academy ∙ 3:19 mins",
    },
    "test-video-data-gigi-stories-3": {
        "en-us": "Buffon Academy ∙ 4:28 mins",
        "es-mx": "Buffon Academy ∙ 4:28 mins",
    },
    "test-video-data-gigi-stories-4": {
        "en-us": "Buffon Academy ∙ 5:52 mins",
        "es-mx": "Buffon Academy ∙ 5:52 mins",
    },
    "test-video-data-gigi-mindset-teamwork": {
        "en-us": "Buffon Academy ∙ 5:29 mins",
        "es-mx": "Buffon Academy ∙ 5:29 mins",
    },
    "test-video-data-gigi-mindset-mental-growth": {
        "en-us": "Buffon Academy ∙ 6:00 mins",
        "es-mx": "Buffon Academy ∙ 6:00 mins",
    },
    "test-video-data-gigi-mindset-perseverance-value": {
        "en-us": "Buffon Academy ∙ 0:34 mins",
        "es-mx": "Buffon Academy ∙ 0:34 mins",
    },
    "test-video-data-gigi-mindset-leadership": {
        "en-us": "Buffon Academy ∙ 6:58 mins",
        "es-mx": "Buffon Academy ∙ 6:58 mins",
    },
    "test-video-data-gigi-skills-stop-grab": {
        "en-us": "Buffon Academy ∙ 1:45 mins",
        "es-mx": "Buffon Academy ∙ 1:45 mins",
    },
    "test-video-data-gigi-skills-side-falls": {
        "en-us": "Buffon Academy ∙ 1:41 mins",
        "es-mx": "Buffon Academy ∙ 1:41 mins",
    },
    "test-video-data-gigi-skills-goal-orientation": {
        "en-us": "Buffon Academy ∙ 12:59 mins",
        "es-mx": "Buffon Academy ∙ 12:59 mins",
    },
    "test-video-data-gigi-skills-reaction-capacity": {
        "en-us": "Buffon Academy ∙ 1:41 mins",
        "es-mx": "Buffon Academy ∙ 1:41 mins",
    },
    "test-video-data-gigi-skills-high-output-1": {
        "en-us": "Buffon Academy ∙ 1:20 mins",
        "es-mx": "Buffon Academy ∙ 1:20 mins",
    },
    "test-video-data-gigi-skills-high-output-2": {
        "en-us": "Buffon Academy ∙ 2:03 mins",
        "es-mx": "Buffon Academy ∙ 2:03 mins",
    },
    "test-video-data-gigi-skills-low-output": {
        "en-us": "Buffon Academy ∙ 0:44 mins",
        "es-mx": "Buffon Academy ∙ 0:44 mins",
    },
    "test-video-data-gigi-skills-skill": {
        "en-us": "Buffon Academy ∙ 1:02 mins",
        "es-mx": "Buffon Academy ∙ 1:02 mins",
    },
    "test-video-data-gigi-fitness-squats": {
        "en-us": "Buffon Academy ∙ 0:49 mins",
        "es-mx": "Buffon Academy ∙ 0:49 mins",
    },
    "test-video-data-gigi-fitness-jump-squats": {
        "en-us": "Buffon Academy ∙ 0:29 mins",
        "es-mx": "Buffon Academy ∙ 0:29 mins",
    },
    "test-video-data-gigi-fitness-squats-unstable": {
        "en-us": "Buffon Academy ∙ 0:53 mins",
        "es-mx": "Buffon Academy ∙ 0:53 mins",
    },
    "test-video-data-gigi-fitness-weighted-squats": {
        "en-us": "Buffon Academy ∙ 0:31 mins",
        "es-mx": "Buffon Academy ∙ 0:31 mins",
    },
    "test-video-data-gigi-fitness-dynamic-movement-ball": {
        "en-us": "Buffon Academy ∙ 0:34 mins",
        "es-mx": "Buffon Academy ∙ 0:34 mins",
    },
    "test-video-data-gigi-fitness-single-leg-lunge": {
        "en-us": "Buffon Academy ∙ 0:39 mins",
        "es-mx": "Buffon Academy ∙ 0:39 mins",
    },
    "test-video-data-gigi-fitness-opposite-single-leg-lunge": {
        "en-us": "Buffon Academy ∙ 0:42 mins",
        "es-mx": "Buffon Academy ∙ 0:42 mins",
    },
    "test-video-data-gigi-fitness-strength": {
        "en-us": "Buffon Academy ∙ 0:54 mins",
        "es-mx": "Buffon Academy ∙ 0:54 mins",
    },
    "test-video-data-gigi-fitness-toning-abs": {
        "en-us": "Buffon Academy ∙ 0:55 mins",
        "es-mx": "Buffon Academy ∙ 0:55 mins",
    },
    "test-video-data-gigi-fitness-toning-abs-side-plank": {
        "en-us": "Buffon Academy ∙ 0:33 mins",
        "es-mx": "Buffon Academy ∙ 0:33 mins",
    },
    "test-video-data-gigi-fitness-toning-abs-bridge": {
        "en-us": "Buffon Academy ∙ 0:37 mins",
        "es-mx": "Buffon Academy ∙ 0:37 mins",
    },
    "test-video-data-gigi-fitness-toning-abs-plank-fitball": {
        "en-us": "Buffon Academy ∙ 0:40 mins",
        "es-mx": "Buffon Academy ∙ 0:40 mins",
    },
    "test-video-data-gigi-fitness-toning-waist-back": {
        "en-us": "Buffon Academy ∙ 0:35 mins",
        "es-mx": "Buffon Academy ∙ 0:35 mins",
    },
    "test-video-data-gigi-fitness-stability-abs": {
        "en-us": "Buffon Academy ∙ 0:54 mins",
        "es-mx": "Buffon Academy ∙ 0:54 mins",
    },
    "test-video-data-gigi-fitness-stimulation-abs": {
        "en-us": "Buffon Academy ∙ 0:35 mins",
        "es-mx": "Buffon Academy ∙ 0:35 mins",
    },
    "test-video-data-gigi-fitness-toning-body": {
        "en-us": "Buffon Academy ∙ 0:36 mins",
        "es-mx": "Buffon Academy ∙ 0:36 mins",
    },
    "test-video-data-gigi-fitness-toning-shoulder-pectoral": {
        "en-us": "Buffon Academy ∙ 0:52 mins",
        "es-mx": "Buffon Academy ∙ 0:52 mins",
    },
    "test-video-data-gigi-fitness-bench-push-ups": {
        "en-us": "Buffon Academy ∙ 0:26 mins",
        "es-mx": "Buffon Academy ∙ 0:26 mins",
    },
    "test-video-data-gigi-fitness-push-ups-unstable": {
        "en-us": "Buffon Academy ∙ 0:34 mins",
        "es-mx": "Buffon Academy ∙ 0:34 mins",
    },
    "test-video-data-gigi-fitness-wirst-strength": {
        "en-us": "Buffon Academy ∙ 0:37 mins",
        "es-mx": "Buffon Academy ∙ 0:37 mins",
    },
    "test-video-data-gigi-fitness-push-ups-handles": {
        "en-us": "Buffon Academy ∙ 0:35 mins",
        "es-mx": "Buffon Academy ∙ 0:35 mins",
    },
    "test-video-data-gigi-fitness-dorsal-handles": {
        "en-us": "Buffon Academy ∙ 0:32 mins",
        "es-mx": "Buffon Academy ∙ 0:32 mins",
    },
    "test-video-data-gigi-fitness-back-muscles-handles": {
        "en-us": "Buffon Academy ∙ 0:39 mins",
        "es-mx": "Buffon Academy ∙ 0:39 mins",
    },
    "test-video-data-gigi-fitness-shoulder-back-stability": {
        "en-us": "Buffon Academy ∙ 0:36 mins",
        "es-mx": "Buffon Academy ∙ 0:36 mins",
    },
    "crack-carlos-salcido-challege-sit-down-kick-ups-title": {
        "en-us": "50 Sit down kick ups",
        "es-mx": "50 Dominadas sentado",
    },
    "crack-carlos-salcido-challege-motorcycle-title": {
        "en-us": "Motorcycle",
        "es-mx": "Motocicleta",
    },
    "crack-charlyn-corral-challege-neck-stall-push-ups-title": {
        "en-us": "Neck stall push-ups",
        "es-mx": "Lagartijas con balón en cuello",
    },
    "crack-charlyn-corral-challege-hump-arround-wolrd-title": {
        "en-us": "hump and arround the wolrd",
        "es-mx": "Joroba con vuelta al mundo",
    },
    "crack-pavel-pardo-challenge-20-kick-ups-title": {
        "en-us": "20 Kick ups",
        "es-mx": "20 Dominadas",
    },
    "crack-rafa-marquez-challenge-hit-crossbar-title": {
        "en-us": "Hit crossbar",
        "es-mx": "Golpe al travesaño",
    },
    "crack-jared-borgetti-challenge-fake-headshot-title": {
        "en-us": "Fake headshot",
        "es-mx": "Remate a perfil cambiado",
    },
    "crack-gigi-buffon-challenge-juggles-title": {
        "en-us": "Juggles",
        "es-mx": "Malabares",
    },
    "crack-program-start-program": {
        "en-us": "Start program",
        "es-mx": "Empezar",
    },
    "crack-program-continue-program": {
        "en-us": "Continue program",
        "es-mx": "Continuar",
    },
    "crack-program-intensity-low": {
        "en-us": "Low",
        "es-mx": "Baja",
    },
    "crack-program-intensity-mid": {
        "en-us": "Medium",
        "es-mx": "Media",
    },

    "crack-program-intensity-high": {
        "en-us": "High",
        "es-mx": "Alta",
    },
    "crack-program-leave-training": {
        "en-us": "Go out",
        "es-mx": "Salir",
    },
    "crack-program-leave-training-title": {
        "en-us": "Wait Crack",
        "es-mx": "Espera Crack",
    },
    "crack-program-leave-training-desc": {
        "en-us":
            "If you exit the workout your progress for the day will be deleted and you will have to restart today's workout",
        "es-mx":
            "Si sales del workout, tu progreso del día se borrará y tendrás que reiniciar el entrenamiento de hoy",
    },
    "direct-link-title": {
        "en-us": "Welcome Crack!",
        "es-mx": "¡Bienvenido, Crack!",
    },
    "direct-link-desc": {
        "en-us":
            "We were waiting for you, it's time to develop your skills to reach your full potential.",
        "es-mx":
            "Te estábamos esperando, es momento desarrollar tus habilidades para alcanzar tu máximo potencial.",
    },
    "crack-program-action-cracks": {
        "en-us": "Cracks",
        "es-mx": "Cracks",
    },
    "crack-program-master-core-action-body": {
        "en-us": "5 days focused 100% on core work, 30 min of work per day!!",
        "es-mx": "5 días enfocados 100% al trabajo de core, 30 min diarios de trabajo!!",
    },
    "crack-program-workout-express-action-body": {
        "en-us": "Trainings of between 15 and 20 minutes, intense and effective.",
        "es-mx": "Entrenamientos de entre 15 y 20 minutos, intensos y efectivos.",
    },
    "crack-program-action-body": {
        "en-us":
            "Program created to train by adapting the spaces and objects that are commonly found at home to achieve soccer and physical training",
        "es-mx":
            "Programa para entrenar adaptando los espacios y objetos que comúnmente hay en casa para lograr un entrenamiento de fútbol y físico sin salir",
    },
    "crack-program-stats-intensity": {
        "en-us": "Intensity",
        "es-mx": "Intensidad",
    },
    "crack-program-stats-duration": {
        "en-us": "Duration",
        "es-mx": "Duración",
    },
    "crack-program-stats-equipment": {
        "en-us": "Equipment",
        "es-mx": "Equipamiento",
    },
    "crack-program-list-card": {
        "en-us": "Workout for today",
        "es-mx": "Entrenamiento de hoy",
    },
    "crack-program-list-achievement": {
        "en-us": "Program trophy",
        "es-mx": "Trofeo del programa",
    },
    "crack-program-calendar-day": {
        "en-us": "Day",
        "es-mx": "Día",
    },
    "crack-program-calendar-week": {
        "en-us": "Week",
        "es-mx": "Semana",
    },
    "crack-program-leave": {
        "en-us": "Leave program",
        "es-mx": "Abandonar programa",
    },
    "program-training-summary-info-title": {
        "en-us": "Beast mode",
        "es-mx": "Modo bestia",
    },
    "program-training-summary-info-body": {
        "en-us":
            "Well done, Crack. Keep training to become the best. We challenge you to beat your personal record",
        "es-mx":
            "Bien hecho crack, sigue practicando para ser el mejor, te retamos a seguir mejorando tu marca personal",
    },
    "program-training-summary-comparison": {
        "en-us": "Workout session duration",
        "es-mx": "Tiempo de entrenamiento",
    },
    "program-training-summary-comparison-details": {
        "en-us": "Details",
        "es-mx": "Ver detalles",
    },
    "program-training-summary-comparison-details-workouts": {
        "en-us": "Rutinas",
        "es-mx": "Workouts",
    },
    "program-training-summary-comparison-details-duration": {
        "en-us": "Tiempo",
        "es-mx": "Duration",
    },
    "program-training-summary-comparison-details-complete-workout": {
        "en-us": "Workout completo",
        "es-mx": "Complete workout",
    },
    "program-training-summary-survey": {
        "en-us": "How did you feel about today's training?",
        "es-mx": "¿Cómo te sentiste con el entrenamiento de hoy?",
    },
    "program-training-summary-survey-physical": {
        "en-us": "Physically",
        "es-mx": "Físicamente",
    },
    "program-training-summary-survey-emotional": {
        "en-us": "Emotionally",
        "es-mx": "Emocionalmente",
    },
    "program-training-summary-survey-exhausted": {
        "en-us": "Exhausted",
        "es-mx": "Agotado",
    },
    "program-training-summary-survey-tired": {
        "en-us": "Tired",
        "es-mx": "Cansado",
    },
    "program-training-summary-survey-active": {
        "en-us": "Active",
        "es-mx": "Activo",
    },
    "program-training-summary-survey-strong": {
        "en-us": "Strong",
        "es-mx": "Fuerte",
    },
    "program-training-summary-survey-energy": {
        "en-us": "+Energy",
        "es-mx": "+Energía",
    },
    "program-training-summary-survey-angry": {
        "en-us": "Angry",
        "es-mx": "Enojado",
    },
    "program-training-summary-survey-sad": {
        "en-us": "Sad",
        "es-mx": "Triste",
    },
    "program-training-summary-survey-calm": {
        "en-us": "Calm",
        "es-mx": "Tranquilo",
    },
    "program-training-summary-survey-happy": {
        "en-us": "Happy",
        "es-mx": "Contento",
    },
    "program-training-summary-survey-motivated": {
        "en-us": "Motivated",
        "es-mx": "Motivado",
    },
    "program-training-share-days": {
        "en-us": "days",
        "es-mx": "días",
    },
    "achievement-fitness-x3-title": {
        "en-us": "Keep it up!",
        "es-mx": "Vamos por más",
    },
    "achievement-fitness-x5-title": {
        "en-us": "Keep going!",
        "es-mx": "¡Sigue así!",
    },
    "achievement-fitness-x25-title": {
        "en-us": "Spectacular",
        "es-mx": "Espectacular",
    },
    "achievement-mindset-x5-title": {
        "en-us": "You are Invincible",
        "es-mx": "Eres invencible",
    },
    "achievement-program-x1-title": {
        "en-us": "You did it!",
        "es-mx": "¡Lo lograste!",
    },
    "achievement-player-game-title": {
        "en-us": "That was Awesome!",
        "es-mx": "¡Hoy hiciste doblete!",
    },
    "achievement-player-month-title": {
        "en-us": "Your Body, your Temple",
        "es-mx": "¡Tu cuerpo a otro nivel!",
    },
    "achievement-player-team-title": {
        "en-us": "Way to go!",
        "es-mx": "Siempre hacia adelante",
    },
    "achievement-player-back-title": {
        "en-us": "What a Comeback",
        "es-mx": "Gran regreso ¡Sigue así!",
    },
    "achievement-player-morning-title": {
        "en-us": "Winning streak!",
        "es-mx": "¡Racha ganadora!",
    },
    "achievement-player-night-title": {
        "en-us": "Night Owl Training",
        "es-mx": "Noches Mágicas de MasterCracks",
    },
    "achievement-challenge-x1-title": {
        "en-us": "1st Challenge complete",
        "es-mx": "Gran logro",
    },
    "achievement-challenge-x5-title": {
        "en-us": "Next Challenge",
        "es-mx": "¡Siguiente reto!",
    },
    "achievement-watch-week-title": {
        "en-us": "DreamTeam",
        "es-mx": "11 titular",
    },
    "achievement-watch-month-title": {
        "en-us": "Diamond in the rough!",
        "es-mx": "Joya en ascenso",
    },
    "achievement-watch-year-title": {
        "en-us": "Rookie of the year",
        "es-mx": "Novato del año!",
    },
    "achievement-day-birthday-title": {
        "en-us": "Birthday Crack!",
        "es-mx": "¡Feliz cumple, crack!",
    },
    "achievement-day-new-years-title": {
        "en-us": "Goal Achieved",
        "es-mx": "Propósito cumplido",
    },
    "achievement-day-international-sports-title": {
        "en-us": "Welcome to the Team!",
        "es-mx": "Bienvenid@ al equipo",
    },
    "achievement-app-download-title": {
        "en-us": "Trending Mode!",
        "es-mx": "Serás tendencia",
    },
    "achievement-app-purchase-title": {
        "en-us": "Season Starts",
        "es-mx": "Inicia la temporada",
    },
    "achievement-app-sharing-title": {
        "en-us": "Contract Signed",
        "es-mx": "Fichaje firmado",
    },
    "achievement-app-account-title": {
        "en-us": "There is no I in Team",
        "es-mx": "Eres un@ de nosotros",
    },
    "achievement-app-profile-title": {
        "en-us": "Captain Badge",
        "es-mx": "Gafete de capitán",
    },
    "achievement-captain-badge-title": {
        "en-us": "Special Training",
        "es-mx": "Entrenamiento especial",
    },
    "achievement-glove-gold-title": {
        "en-us": "WonderWALL",
        "es-mx": "Eres una muralla",
    },
    "achievement-glove-silver-title": {
        "en-us": "No ball allowed",
        "es-mx": "Ningún balón te pasa",
    },
    "achievement-glove-bronce-title": {
        "en-us": "Goalkeeper Time!",
        "es-mx": "Listo para atajar",
    },
    "achievement-femenil-gold-title": {
        "en-us": "On the EDGE",
        "es-mx": "¡Eres de 10!",
    },
    "achievement-femenil-silver-title": {
        "en-us": "Excelent!",
        "es-mx": "¡Excelente!",
    },
    "achievement-femenil-bronce-title": {
        "en-us": "Great Work!",
        "es-mx": "Gran trabajo",
    },
    "achievement-shoe-gold-title": {
        "en-us": "1st Challenge complete",
        "es-mx": "Jugador estelar",
    },
    "achievement-ball-gold-title": {
        "en-us": "Multitasker",
        "es-mx": "Jugador plurifuncional",
    },
    "achievement-stars-x3-title": {
        "en-us": "Golden boot",
        "es-mx": "Goleador nato",
    },
    "achievement-stars-x10-title": {
        "en-us": "Golden ball",
        "es-mx": "balón de oro",
    },
    "achievement-position-complete-title": {
        "en-us": "Goal Achieved!",
        "es-mx": "Meta superada",
    },
    "achievement-position-program-title": {
        "en-us": "On Fire!",
        "es-mx": "¡Estás on fire!",
    },
    "achievement-position-goalkeeper-title": {
        "en-us": "Goalkeeper King",
        "es-mx": "¡Hay portero!",
    },
    "achievement-position-striker-title": {
        "en-us": "Pichichi mode!",
        "es-mx": "Nuevo killer",
    },
    "achievement-position-defender-title": {
        "en-us": "Great play",
        "es-mx": "Gran jugada",
    },
    "achievement-position-midfielder-title": {
        "en-us": "Conquering MasterCracks",
        "es-mx": "A repartir balones",
    },
    "achievement-world-cup-title": {
        "en-us": "The WORLD is yours",
        "es-mx": "Conquista el mundo",
    },
    "achievement-jo-canales-program-title": {
        "en-us": "Jo Canales' Card",
        "es-mx": "Card de Jo Canales",
    },
    "achievement-fitness-x3-desc": {
        "en-us": "For the user who trains 3 days in a row.",
        "es-mx": "Para el usuario que entrena 3 días seguidos.",
    },
    "achievement-fitness-x5-desc": {
        "en-us": "For the user who makes 5 fitness videos.",
        "es-mx": "Para el usuario que hace 5 videos de fitness.",
    },
    "achievement-fitness-x25-desc": {
        "en-us": "For the user who makes 25 fitness videos.",
        "es-mx": "Para el usuario que hace 25 videos de fitness.",
    },
    "achievement-mindset-x5-desc": {
        "en-us": "For the user who watches Mindset videos 5 days in a row.",
        "es-mx": "Para el usuario que ve videos de Mindset 5 días.",
    },
    "achievement-program-x1-desc": {
        "en-us": "For the user completing their first program.",
        "es-mx": "Para el usuario que completa su primer programa.",
    },
    "achievement-player-game-desc": {
        "en-us": "For the user who completes the fitness program and plays a match.",
        "es-mx": "Para el usuario que completa el programa fitness y juega un partido.",
    },
    "achievement-player-month-desc": {
        "en-us":
            "For the user who completes the fitness program and plays a match 4 weeks in a row.",
        "es-mx":
            "Para el usuario que completa el programa fitness y juega un partido 4 semanas seguidas.",
    },
    "achievement-player-team-desc": {
        "en-us": "For the user who raises his level.",
        "es-mx": "Para el usuario que sube su nivel.",
    },
    "achievement-player-back-desc": {
        "en-us":
            "For the user who returned after 2 weeks absence and made a program without failure.",
        "es-mx":
            "Para el usuario que regresó tras 2 semanas ausente e hizo un programa sin fallas.",
    },
    "achievement-player-morning-desc": {
        "en-us": "For the user who trains 7 mornings in a row.",
        "es-mx": "Para el usuario que entrena 7 mañanas seguidas.",
    },
    "achievement-player-night-desc": {
        "en-us": "For the user who trains 7 nights  in a row.",
        "es-mx": "Para el usuario que entrena 7 noches seguidas.",
    },
    "achievement-challenge-x1-desc": {
        "en-us": "This achievement is for any user who uploads their first challenge take.",
        "es-mx": "Este logro es para cualquier usuario que suba su primer challenge.",
    },
    "achievement-challenge-x5-desc": {
        "en-us": "For the user who uploads 5 Challenges.",
        "es-mx": "Para el usuario que sube 5 Challenges.",
    },
    "achievement-watch-week-desc": {
        "en-us": "For the user who is part of the 11 who consumed the most content in a week.",
        "es-mx":
            "Para el usuario que forma parte de los 11 que más contenidos consumió en una semana.",
    },
    "achievement-watch-month-desc": {
        "en-us": "For the new user who has watched and shared the most content in the month.",
        "es-mx": "Para el nuevo usuario que más contenido ha observado y compartido en el mes.",
    },
    "achievement-watch-year-desc": {
        "en-us": "You are the new user, who has watched and shared the most content in the year.",
        "es-mx": "Eres el nuevo usuario, que más contenido ha observado y compartido en el año.",
    },
    "achievement-day-birthday-desc": {
        "en-us": "For the user who enters MasterCracks on his birthday.",
        "es-mx": "Para el usuario que entra a MasterCracks el día de su cumpleaños.",
    },
    "achievement-day-new-years-desc": {
        "en-us": "For the user who enters MasterCracks in New Year.",
        "es-mx": "Para el usuario que entra a MasterCracks en Año Nuevo.",
    },
    "achievement-day-international-sports-desc": {
        "en-us": "Medal for first time payment.",
        "es-mx": "Realizaste un pago por primera vez.",
    },
    "achievement-app-download-desc": {
        "en-us": "Medal when you share your first video to social media.",
        "es-mx": "Medalla cuando compartes tu primer video en las redes sociales.",
    },
    "achievement-app-purchase-desc": {
        "en-us": "Medal when you download the app on google play or apple.",
        "es-mx": "Cuando descargas la aplicación en google play o apple.",
    },
    "achievement-app-sharing-desc": {
        "en-us": "First achievement that the user earns for making their account.",
        "es-mx": "Primer achievement que gana el usuario por hacer su cuenta.",
    },
    "achievement-app-account-desc": {
        "en-us": "For the user who completed his profile.",
        "es-mx": "Para el usuario que completó su perfil.",
    },
    "achievement-app-profile-desc": {
        "en-us": "For the user who completes 3 Mindset programs.",
        "es-mx": "Para el usuario que completa 3 programas de Mindset",
    },
    "achievement-captain-badge-desc": {
        "en-us":
            "Special training for the user who enters MasterCracks on April 6 International Sports Day.",
        "es-mx":
            "Para el usuario que entra a MasterCracks el 6 de abril Día Internacional de los Deportes.",
    },
    "achievement-glove-gold-desc": {
        "en-us": "For the user who completes 10 goalkeeper programs.",
        "es-mx": "Para el usuario que completa 10 programas de portero.",
    },
    "achievement-glove-silver-desc": {
        "en-us": "For the user who completes 5 goalkeeper programs.",
        "es-mx": "Para el usuario que completa 5 programas de portero.",
    },
    "achievement-glove-bronce-desc": {
        "en-us": "For the user who completes 2 goalkeeper programs.",
        "es-mx": "Para el usuario que completa 2 programas de portero.",
    },
    "achievement-femenil-gold-desc": {
        "en-us": "For the user who consumed 10 women's programs.",
        "es-mx": "Para el usuario que consumió 10 programas de femenil.",
    },
    "achievement-femenil-silver-desc": {
        "en-us": "For the user who consumed 5 fermale  player programs.",
        "es-mx": "Para el usuario que consumió 5 programas de jugadoras.",
    },
    "achievement-femenil-bronce-desc": {
        "en-us": "For the user who consumed 3 femanle player programs.",
        "es-mx": "Para el usuario que consumió 3 programas de jugadoras.",
    },
    "achievement-shoe-gold-desc": {
        "en-us": "Completed all content and challenges of a positon.",
        "es-mx": "Terminaste todos los contenidos y challenges de una posición.",
    },
    "achievement-ball-gold-desc": {
        "en-us": "For the user who completes a program from each position.",
        "es-mx": "Para el usuario que completa un programa de cada posición.",
    },
    "achievement-stars-x3-desc": {
        "en-us": "Consumed all content and did all challenges that culminate in a goal.",
        "es-mx":
            "Consumiste todo los contenidos e hiciste todos los challenges que culminan en gol.",
    },
    "achievement-stars-x10-desc": {
        "en-us":
            "You are the user that has accomplish more content and share in the platform in the season.",
        "es-mx":
            "Eres el usuario que más contenido ha realizado y compartido en la plataforma en la temporada.",
    },
    "achievement-position-complete-desc": {
        "en-us": "For the user who completes three programs.",
        "es-mx": "Para el usuario que completa tres programas.",
    },
    "achievement-position-program-desc": {
        "en-us": "For the user who uploads 10 challenges.",
        "es-mx": "Para el usuario que sube 10 challenges.",
    },
    "achievement-position-goalkeeper-desc": {
        "en-us": "For being the best goalkeeper in content consumed in the month.",
        "es-mx": "Por ser el mejor portero en contenido consumido en el mes.",
    },
    "achievement-position-striker-desc": {
        "en-us": "For being the best striker in content consumed in the month.",
        "es-mx": "Por ser el mejor delantero en contenido consumido en el mes.",
    },
    "achievement-position-defender-desc": {
        "en-us": "For being the best defender in content consumed in the month.",
        "es-mx": "Por ser el mejor defensa en contenido consumido en el mes.",
    },
    "achievement-position-midfielder-desc": {
        "en-us": "For being the best midfielder  in content consumed in the month.",
        "es-mx": "Por ser el mejor medio en contenido consumido en el mes.",
    },
    "achievement-world-cup-desc": {
        "en-us": "For the user who consumed content from 5 players of different nationalities.",
        "es-mx":
            "Para el usuario que consumió contenido de 5 jugadores de nacionalidades distintas.",
    },
    "achievement-jo-canales-program-desc": {
        "en-us": "For the user who finishes a Jo Canales program.",
        "es-mx": "Para el usuario que termine un programa de Jo Canales.",
    },
    "achievement-program-full-body-title": {
        "en-us": "Jo Canales' Card",
        "es-mx": "Card de Jo Canales",
    },
    "achievement-program-full-body-desc": {
        "en-us": "For the user who finishes a Jo Canales program.",
        "es-mx": "Para el usuario que termine un programa de Jo Canales.",
    },
    "achievement-program-road-qatar-title": {
        "en-us": "Jo Canales' Card",
        "es-mx": "Card de Jo Canales",
    },
    "achievement-program-road-qatar-desc": {
        "en-us": "For the user who finishes a Jo Canales program.",
        "es-mx": "Para el usuario que termine un programa de Jo Canales.",
    },
    "achievement-program-pre-season-title": {
        "en-us": "Jo Canales' Card",
        "es-mx": "Card de Jo Canales",
    },
    "achievement-program-pre-season-desc": {
        "en-us": "For the user who finishes a Jo Canales program.",
        "es-mx": "Para el usuario que termine un programa de Jo Canales.",
    },
    "achievement-program-new-years-resolution-title": {
        "en-us": "Jo Canales' Card",
        "es-mx": "Card de Jo Canales",
    },
    "achievement-program-new-years-resolution-desc": {
        "en-us": "For the user who finishes a Jo Canales program.",
        "es-mx": "Para el usuario que termine un programa de Jo Canales.",
    },
    "achievement-type-medal": {
        "en-us": "Medals",
        "es-mx": "Medallas",
    },
    "achievement-type-trophy": {
        "en-us": "Trophies",
        "es-mx": "Trofeos",
    },
    "achievement-type-card": {
        "en-us": "Cards",
        "es-mx": "Tarjetas",
    },
    "programs-time-1-minutes": {
        "en-us": "1m",
        "es-mx": "1m",
    },
    "programs-time-3-minutes": {
        "en-us": "3m",
        "es-mx": "3m",
    },
    "programs-time-4-minutes": {
        "en-us": "4m",
        "es-mx": "4m",
    },
    "programs-time-6-minutes": {
        "en-us": "6m",
        "es-mx": "6m",
    },
    "programs-time-7-minutes": {
        "en-us": "7m",
        "es-mx": "7m",
    },
    "programs-time-9-minutes": {
        "en-us": "9m",
        "es-mx": "9m",
    },
    "programs-time-14-minutes": {
        "en-us": "14m",
        "es-mx": "14m",
    },
    "programs-time-15-minutes": {
        "en-us": "15m",
        "es-mx": "15m",
    },
    "programs-time-16-minutes": {
        "en-us": "16m",
        "es-mx": "16m",
    },
    "programs-time-20-minutes": {
        "en-us": "20m",
        "es-mx": "20m",
    },
    "programs-time-25-minutes": {
        "en-us": "25m",
        "es-mx": "25m",
    },
    "programs-time-30-minutes": {
        "en-us": "30m",
        "es-mx": "30m",
    },
    "programs-time-3-day": {
        "en-us": "3 days",
        "es-mx": "3 días",
    },
    "programs-time-5-day": {
        "en-us": "5 days",
        "es-mx": "5 días",
    },
    "programs-time-6-day": {
        "en-us": "6 days",
        "es-mx": "6 días",
    },
    "programs-time-7-day": {
        "en-us": "7 days",
        "es-mx": "7 días",
    },
    "programs-time-10-day": {
        "en-us": "10 days",
        "es-mx": "10 días",
    },
    "programs-time-11-day": {
        "en-us": "11 days",
        "es-mx": "11 días",
    },
    "programs-time-14-day": {
        "en-us": "14 days",
        "es-mx": "14 días",
    },
    "programs-time-8-weeks": {
        "en-us": "8 weeks",
        "es-mx": "8 semanas",
    },
    "program-secret-training-day-01": {
        "en-us": "Day 1",
        "es-mx": "Día 1",
    },
    "program-secret-training-day-02": {
        "en-us": "Day 2",
        "es-mx": "Día 2",
    },
    "program-secret-training-day-03": {
        "en-us": "Day 3",
        "es-mx": "Día 3",
    },
    "program-secret-training-day-04": {
        "en-us": "Day 4",
        "es-mx": "Día 4",
    },
    "program-secret-training-day-05": {
        "en-us": "Day 5",
        "es-mx": "Día 5",
    },
    "program-secret-training-day-06": {
        "en-us": "Day 6",
        "es-mx": "Día 6",
    },
    "program-secret-training-day-07": {
        "en-us": "Day 7",
        "es-mx": "Día 7",
    },
    "program-secret-training-day-08": {
        "en-us": "Day 8",
        "es-mx": "Día 8",
    },
    "program-secret-training-day-09": {
        "en-us": "Day 9",
        "es-mx": "Día 9",
    },
    "program-secret-training-day-10": {
        "en-us": "Day 10",
        "es-mx": "Día 10",
    },
    "program-secret-training-day-11": {
        "en-us": "Day 11",
        "es-mx": "Día 11",
    },
    "program-secret-training-day-bonus": {
        "en-us": "Bonus",
        "es-mx": "Tiempo Extra",
    },
    "program-secret-stress-management-title": {
        "en-us": "Stress management",
        "es-mx": "Maneja el estrés",
    },
    "program-secret-stress-management-desc": {
        "en-us":
            "Don't let stress control your life and start managing it like the great Cracks. Achieve it by training less than 10 minutes per day!",
        "es-mx":
            "No dejes que el estrés controle tu vida y empieza a manejarlo como los grandes Cracks. ¡Lógralo entrenando menos de 10 minutos por día!",
    },
    "program-secret-stress-management-benefit": {
        "en-us":
            "Mental clarity, better decision making, general improvement in your health, healthier relationships, more energy and focus.",
        "es-mx":
            "Claridad mental, mejor toma de decisiones, mejora general de tu salud, relaciones más saludables, más energía y concentración.",
    },
    "program-secret-stress-management-day01-desc": {
        "en-us": "What is stress?",
        "es-mx": "¿Qué es el estrés?",
    },
    "program-secret-stress-management-day02-desc": {
        "en-us": "Building resilience to stress",
        "es-mx": "Generando resiliencia al estrés",
    },
    "program-secret-stress-management-day03-desc": {
        "en-us": "Meditate and change your life",
        "es-mx": "Medita y cambia tu vida",
    },
    "program-secret-stress-management-day04-desc": {
        "en-us": "Guided Meditation",
        "es-mx": "Meditación guiada",
    },
    "program-secret-stress-management-day05-desc": {
        "en-us": "Conscious breathing",
        "es-mx": "Respiración consciente",
    },
    "program-secret-stress-management-day06-desc": {
        "en-us": "Gratitude",
        "es-mx": "Gratitud",
    },
    "program-secret-stress-management-day07-desc": {
        "en-us": "Visualize and change your life",
        "es-mx": "Visualiza y cambia tu vida",
    },
    "program-secret-stress-management-day08-desc": {
        "en-us": "Take the best of nature",
        "es-mx": "Toma lo mejor de la naturaleza",
    },
    "program-secret-stress-management-day09-desc": {
        "en-us": "Digital detox + relaxation",
        "es-mx": "Digital detox + relajación",
    },
    "program-secret-stress-management-day10-desc": {
        "en-us": "Me time and…until next time!",
        "es-mx": "Me time y…¡hasta la próxima!",
    },
    "program-secret-sleep-title": {
        "en-us": "Sleep Program",
        "es-mx": "Programa de Sueño",
    },
    "program-secret-sleep-desc": {
        "en-us":
            "Did you know that there are more than 80 different sleep disorders? Rest and sufficient sleep ensure good physical performance of the body, balance of emotions and mental clarity. With this 10-day program, develop healthy sleeping habits that ensure a better quality of life and help you make the most of your time active. Sleep is health. Follow this training and change your life by sleeping as it should be.",
        "es-mx":
            "¿Sabías que existen más de 80 diferentes trastornos de sueño? El descanso y dormir lo suficiente aseguran el buen rendimiento físico del cuerpo, el equilibrio de las emociones y la claridad mental. Con este programa de 10 días, desarrolla hábitos de sueño saludables que te aseguren una mejor calidad de vida y te apoyen a aprovechar al máximo tu tiempo en activo. El sueño es la salud. Sigue este entrenamiento y cambia tu vida durmiendo como debe ser.",
    },
    "program-secret-sleep-benefit": {
        "en-us":
            "Reduces the appearance of diseases; healthy weight maintenance; decreased stress; mood improvement; less irritability. Mental clarity and better physical and professional performance.",
        "es-mx":
            "Reduce la aparición de enfermedades; mantenimiento de peso saludable; disminución del estrés; mejora del estado de ánimo; menor irritabilidad. Claridad mental y mejor rendimiento físico y profesional.",
    },
    "program-secret-sleep-day01-desc": {
        "en-us": "The Secret of the Cracks",
        "es-mx": "El Secreto de los Grandes Cracks",
    },
    "program-secret-sleep-day02-desc": {
        "en-us": "Sleep test",
        "es-mx": "Test de sueño",
    },
    "program-secret-sleep-day03-desc": {
        "en-us": "Know your enemy",
        "es-mx": "Conoce a tu enemigo",
    },
    "program-secret-sleep-day04-desc": {
        "en-us": "Visualization: Challenge your limiting beliefs",
        "es-mx": "Visualización: desafía tus creencias limitantes",
    },
    "program-secret-sleep-day05-desc": {
        "en-us": "Perform at your best by knowing your chronotype",
        "es-mx": "Rinde al máximo conociendo tu cronotipo",
    },
    "program-secret-sleep-day06-desc": {
        "en-us": "The best night… is built during the day!",
        "es-mx": "La mejor noche… ¡se construye durante el día!",
    },
    "program-secret-sleep-day07-desc": {
        "en-us": "Your worst nightmares depend on your dinner",
        "es-mx": "Tus peores pesadillas dependen de tu cena",
    },
    "program-secret-sleep-day08-desc": {
        "en-us": "Your dream sanctuary",
        "es-mx": "Tu santuario de sueño",
    },
    "program-secret-sleep-day09-desc": {
        "en-us": "The ritual to kill the Coco",
        "es-mx": "El ritual para matar al Coco",
    },
    "program-secret-sleep-day10-desc": {
        "en-us": "Connect better to have better dreams",
        "es-mx": "Conecta mejor para tener mejores sueños",
    },
    "program-secret-sleep-day11-desc": {
        "en-us": "You did it, Crack!",
        "es-mx": "¡Lo lograste, Crack!",
    },
    "program-secret-sleep-day12-desc": {
        "en-us": "Sleep meditation",
        "es-mx": "Meditación para dormir",
    },
    "crack-program-cover-intensity": {
        "en-us": "Medium intensity",
        "es-mx": "Intensidad media",
    },
    "crack-program-14-days": {
        "en-us": "14 days, including 2 days off.",
        "es-mx": "14 días, incluyendo 2 días de descanso.",
    },
    "crack-program-round": {
        "en-us": "Round",
        "es-mx": "Ronda",
    },
    "crack-program-round-complete": {
        "en-us": "Full Round",
        "es-mx": "Ronda Completa",
    },
    "crack-jo-canales-warm-up": {
        "en-us": "Warm up",
        "es-mx": "Warm up",
    },
    "crack-jo-canales-warm-up-1": {
        "en-us": "Warm up 1",
        "es-mx": "Warm up 1",
    },
    "crack-jo-canales-warm-up-2": {
        "en-us": "Warm up 2",
        "es-mx": "Warm up 2",
    },
    "crack-jo-canales-amrap": {
        "en-us": "AMRAP",
        "es-mx": "AMRAP",
    },
    "crack-jo-canales-amrap-1": {
        "en-us": "AMRAP 1",
        "es-mx": "AMRAP 1",
    },
    "crack-jo-canales-amrap-2": {
        "en-us": "AMRAP 2",
        "es-mx": "AMRAP 2",
    },
    "crack-jo-canales-amrap-3": {
        "en-us": "AMRAP 3",
        "es-mx": "AMRAP 3",
    },
    "crack-jo-canales-workout": {
        "en-us": "Workout",
        "es-mx": "Workout",
    },
    "crack-jo-canales-workout-1": {
        "en-us": "Workout 1",
        "es-mx": "Workout 1",
    },
    "crack-jo-canales-workout-2": {
        "en-us": "Workout 2",
        "es-mx": "Workout 2",
    },
    "crack-jo-canales-workout-3": {
        "en-us": "Workout 3",
        "es-mx": "Workout 3",
    },
    "crack-jo-canales-workout-4": {
        "en-us": "Workout 4",
        "es-mx": "Workout 4",
    },
    "program-workout-full-body-1": {
        "en-us": "force block",
        "es-mx": "Bloque de fuerza",
    },
    "program-workout-aerobic": {
        "en-us": "Aerobic",
        "es-mx": "Aeróbico",
    },
    "program-workout-physical-conditioning": {
        "en-us": "Physical conditioning",
        "es-mx": "Acondicionamiento físico",
    },
    "program-workout-physical-technical": {
        "en-us": "Physical-Technical",
        "es-mx": "Físico-Técnico ",
    },
    "program-workout-technical": {
        "en-us": "Technical",
        "es-mx": "Técnico ",
    },
    "program-workout-physical": {
        "en-us": "Physical",
        "es-mx": "Físico ",
    },
    "crack-jo-canales-skills": {
        "en-us": "Skills",
        "es-mx": "Skills",
    },
    "crack-jo-canales-control-pass": {
        "en-us": "Control and pass",
        "es-mx": "Dominio y pase",
    },
    "terms-and-conditions": {
        "en-us": termsEN,
        "es-mx": termsES,
    },
    "privacy-policy": {
        "en-us": privacyEN,
        "es-mx": privacyES,
    },
    "responsive-landing-title": {
        "en-us":
            "<h3 data-preset='tijuana'>Improve your life with <span data-preset='torreon'>MasterCracks</span></h3>",
        "es-mx":
            "<h3 data-preset='tijuana'>Transforma tu vida con <span data-preset='torreon'>MasterCracks</span></h3>",
    },
    "responsive-landing-subtitle": {
        "en-us":
            "Develop your full potential alongside the great soccer stars such as Rafael Márquez, Charlyn Corral, Pavel Pardo, among other great icons.",
        "es-mx":
            "Desarrolla tu máximo potencial físico, mental y emocional con la guía de MasterCracks como Rafa Márquez, Pável Pardo, Carlos Salcido, Norma Palafox, Jared Borgetti, Charlyn Corral y Oswaldo Sánchez, entre otros, y un cuerpo técnico de entrenadores y coachs que te llevarán a alcanzar tu mejor versión en todas las áreas de tu vida.",
    },
    "responsive-landing-body-1": {
        "en-us":
            "Join our community and start working today from the comfort of your home, to transform your life: our step-by-step programs will lead you to improve your habits, define and channel your purposes, work on your ability to resignify failures, improve your diet and your sleep, discover the power of visualization and positive internal speech, develop your leadership skills and reach your best physical version, with a luxury coaching staff",
        "es-mx":
            "Únete a nuestra comunidad y empieza a trabajar hoy mismo, desde el confort de tu casa, para transformar tu vida: nuestros programas paso a paso te llevarán a mejorar tus hábitos, definir y encauzar tus propósitos, trabajar en tu capacidad para resignificar los fracasos, mejorar tu alimentación y tu sueño, descubrir el poder de la visualización y el habla interna positiva, desarrollar tus aptitudes de liderazgo y alcanzar tu mejor versión física, con un cuerpo técnico de lujo.",
    },
    "responsive-landing-body-2": {
        "en-us":
            "Also, only during December, enjoy our professional training program Rumbo a Qatar with coach <strong>Jo Canales</strong>, physical trainer for <strong>Checo Pérez</strong> and other great stars of the sport.",
        "es-mx":
            "Además, solo durante diciembre, disfruta de nuestro programa de entrenamiento profesional Rumbo a Qatar con el coach <strong>Jo Canales</strong>, preparador físico de <strong>Checo Pérez</strong> y otros grandes cracks del deporte.",
    },
    "responsive-landing-button": {
        "en-us": "Join MasterCracks Beta",
        "es-mx": "Únete a MasterCracks Beta",
    },
    "payment-choose-title": {
        "en-us": "Choose your plan",
        "es-mx": "Elige tu plan",
    },
    "payment-choose-desc": {
        "en-us": "What you get",
        "es-mx": "Lo que obtendrás",
    },
    "payment-choose-security-desc": {
        "en-us":
            "Unlock all content, programs, tips, tools,challenges,skills from the bests MasterCracks of the world",
        "es-mx":
            "Desbloquea todos los contenidos, programas, consejos, retos, habilidades y clases de los mejores Cracks del futbol mundial",
    },
    "payment-choose-open-pay": {
        "en-us": "Openpay",
        "es-mx": "Openpay",
    },
    "payment-choose-open-pay-desc": {
        "en-us": "Secure transactions with",
        "es-mx": "Transacciones seguras con",
    },
    "payment-choose-type-annually": {
        "en-us": "Annual Plan",
        "es-mx": "Plan Anual",
    },
    "payment-choose-cost-annually": {
        "en-us": "$999MXN/year",
        "es-mx": "$999MXN al año",
    },
    "payment-choose-discount-annually": {
        "en-us": "Pay 10 months, get 12",
        "es-mx": "Paga 10 meses, recibe 12",
    },
    "payment-choose-desc-annually": {
        "en-us":
            "With your suscription you will unlock all content, programs, tips, tools,challenges,skills from the bests MasterCracks of the world",
        "es-mx":
            "Con tu suscripción tendrás acceso a todos los contenidos, programas, consejos, retos, habilidades y clases de los mejores Cracks del futbol mundial",
    },
    "payment-choose-type-monthly": {
        "en-us": "Monthly plan",
        "es-mx": "Plan Mensual",
    },
    "payment-choose-cost-monthly": {
        "en-us": "$99MXN/Month ",
        "es-mx": "$99MXN mensualmente",
    },
    "payment-choose-discount-monthly": {
        "en-us": "En caso de que haya descuentos, poner los % aqui ",
        "es-mx": "En caso de que haya descuentos, poner los % aqui ",
    },
    "payment-choose-desc-monthly": {
        "en-us":
            "With your suscription you will unlock all content, programs, tips, tools,challenges,skills from the bests MasterCracks of the world",
        "es-mx":
            "Con tu suscripción tendrás acceso a todos los contenidos, programas, consejos, retos, habilidades y clases de los mejores Cracks del futbol mundial",
    },
    "payment-confirm-title": {
        "en-us": "Confirm your plan",
        "es-mx": "Confirma tu plan",
    },
    "payment-confirm-button": {
        "en-us": "Confirm suscription",
        "es-mx": "Confirma tu suscripción",
    },
    "payment-confirm-edit-button": {
        "en-us": "Edit payment method",
        "es-mx": "Editar método de pago",
    },
    "payment-confirm-apply-button": {
        "en-us": "Apply code",
        "es-mx": "Aplicar código",
    },
    "payment-confirm-saved-code": {
        "en-us": "Subtotal:",
        "es-mx": "Subtotal:",
    },
    "payment-confirm-you-saved": {
        "en-us": "Saved:",
        "es-mx": "Ahorraste:",
    },
    "payment-confirm-placeholder-coupon": {
        "en-us": "xxxxxxx",
        "es-mx": "xxxxxxx",
    },
    "payment-confirm-label-coupon": {
        "en-us": "Promotion code (optional)",
        "es-mx": "Código de promoción (opcional)",
    },
    "payment-confirm-validate-error": {
        "en-us": "Coupon rejected",
        "es-mx": "Cupón rechazado",
    },
    "payment-confirm-validate-success": {
        "en-us": "Coupon accepted",
        "es-mx": "Cupón aceptado",
    },
    "payment-status-success-title": {
        "en-us": "Payment accepted",
        "es-mx": "Pago aceptado",
    },
    "payment-status-success-desc": {
        "en-us":
            "Thanks for your payment Crack  you have now access to all content, programs, tips, tools,challenges,skills from the bests MasterCracks of the world",
        "es-mx":
            "Gracias por tu compra Crack, ya tienes acceso a todos los contenidos, programas, consejos, retos, habilidades y clases de los mejores Cracks del futbol mundial",
    },
    "payment-status-success-button": {
        "en-us": "Lets Start",
        "es-mx": "Empezar",
    },
    "payment-status-process-title": {
        "en-us": "Payment in process",
        "es-mx": "Pago en proceso",
    },
    "payment-status-process-desc": {
        "en-us":
            "Thank you for being part of MasterCracks, we are processing your payment, when finished we will send you the status of the process by mail.",
        "es-mx":
            "Gracias por ser parte de MasterCracks, estamos procesando tu pago, al terminar te enviarémos por correo el estatus del proceso.",
    },
    "payment-status-process-button": {
        "en-us": "Confirm",
        "es-mx": "Aceptar",
    },
    "payment-status-error-title": {
        "en-us": "Payment Declined",
        "es-mx": "Pago Rechazado",
    },
    "payment-status-error-desc": {
        "en-us":
            "We couldn't process your payment, check your entered data or try using another card",
        "es-mx":
            "No pudimos procesar tu pago, revisa bien tus datos ingresados o intenta utilizando otra tarjeta",
    },
    "payment-status-error-button": {
        "en-us": "Review payment method",
        "es-mx": "Revisar método de pago",
    },
    "payment-status-pending-title": {
        "en-us": "Generated code",
        "es-mx": "Código generado",
    },
    "payment-status-pending-transaction": {
        "en-us": "Your transction number is: xxxxxx (number)",
        "es-mx": "Tu número de transacción es: xxxxxx (número)",
    },
    "payment-status-pending-desc": {
        "en-us":
            "Thanks for your interest Crack, we have generated a unique code which you can see in your email so you can make your payment in cash.",
        "es-mx":
            "Gracias por tu interés Crack, hemos generando un código único el cual podrás ver en tu correo electrónico para que puedas realizar tu pago en efectivo.",
    },
    "payment-status-pending-button": {
        "en-us": "download code",
        "es-mx": "Descargar código",
    },

    "payment-status-cancel-title": {
        "en-us": "Are you sure you want to cancel your plan?",
        "es-mx": "¿Estás seguro de querer cancelar tu plan?",
    },
    "payment-status-cancel-desc": {
        "en-us":
            "Canceling your plan will remove automatic charges and keep your personal data safe. The free version of Mastercracks will be enabled at the end of the date established in the plan that is already in progress.",
        "es-mx":
            "Al cancelar tu plan se eliminarán los cobros automáticos y se mantendrán tus datos personales seguros. Se habilitará la versión gratuita de Mastercracks al terminar la fecha establecida en el plan que ya está en curso.",
    },
    "payment-status-cancel-button": {
        "en-us": "Cancel plan",
        "es-mx": "Cancelar plan",
    },
    "payment-status-cancelled-title": {
        "en-us": "payment-status-cancelled-title",
        "es-mx": "payment-status-cancelled-title",
    },
    "payment-status-cancelled-desc": {
        "en-us": "payment-status-cancelled-desc",
        "es-mx": "payment-status-cancelled-desc",
    },
    "payment-status-cancelled-button": {
        "en-us": "payment-status-cancelled-button",
        "es-mx": "payment-status-cancelled-button",
    },
    "payment-status-is-error-on-cancel-title": {
        "en-us": "payment-status-is-error-on-cancel-title",
        "es-mx": "payment-status-is-error-on-cancel-title",
    },
    "payment-status-is-error-on-cancel-desc": {
        "en-us": "payment-status-is-error-on-cancel-desc",
        "es-mx": "payment-status-is-error-on-cancel-desc",
    },
    "payment-status-is-error-on-cancel-button": {
        "en-us": "payment-status-is-error-on-cancel-button",
        "es-mx": "payment-status-is-error-on-cancel-button",
    },
    "payment-and-plans": {
        "en-us": "Plans and Payments",
        "es-mx": "Planes y Pagos",
    },
    "payment-plan-change": {
        "en-us": "Change Plan",
        "es-mx": "Cambiar Plan",
    },
    "payment-method-title": {
        "en-us": "Payment Method",
        "es-mx": "Método de pago",
    },
    "payment-change-plan": {
        "en-us": "Change",
        "es-mx": "Cambiar",
    },
    "payment-cancel-plan": {
        "en-us": "Cancel plan",
        "es-mx": "Cancelar plan",
    },
    "payment-detail-title": {
        "en-us": "Plan Details",
        "es-mx": "Detalles del plan",
    },
    "payment-associated": {
        "en-us": "Payment",
        "es-mx": "Pago",
    },
    "payment-cash": {
        "en-us": "Cash",
        "es-mx": "Efectivo",
    },
    "payment-edition-button": {
        "en-us": "Update payment method",
        "es-mx": "Editar método de pago",
    },
    "payment-history": {
        "en-us": "Payment History",
        "es-mx": "Historial de pagos",
    },
    "payment-history-see-more": {
        "en-us": "See more",
        "es-mx": "Ver más",
    },
    "payment-active-card": {
        "en-us": "Active credit card",
        "es-mx": "Tarjeta activa",
    },
    "payment-next-payment": {
        "en-us": "Next payment",
        "es-mx": "Próximo pago",
    },
    "payment-method-card": {
        "en-us": "Pay with Credit or Debit Card",
        "es-mx": "Pago con Tarjeta de Crédito o Débito",
    },
    "payment-method-cash": {
        "en-us": "Pay In Cash",
        "es-mx": "Pago en efectivo",
    },
    "payment-method-apple-pay": {
        "en-us": "Pay with",
        "es-mx": "Paga con",
    },
    "payment-method-google-pay": {
        "en-us": "Buy with",
        "es-mx": "Compra con",
    },
    "credit-card-date": {
        "en-us": "Valid Thru:",
        "es-mx": "Válido hasta:",
    },
    "credit-card-code": {
        "en-us": "CVV",
        "es-mx": "CVV",
    },
    "credit-card-name-label": {
        "en-us": "Name and last name",
        "es-mx": "Nombre y apellido",
    },
    "credit-card-name-placeholder": {
        "en-us": "Enter your first and last name",
        "es-mx": "Ingresa tu nombre y apellido",
    },
    "credit-card-code-label": {
        "en-us": "CVV",
        "es-mx": "CVV",
    },
    "credit-card-code-placeholder": {
        "en-us": "xxx",
        "es-mx": "xxx",
    },
    "credit-card-number-label": {
        "en-us": "Card Number",
        "es-mx": "Número de tarjeta",
    },
    "credit-card-number-placeholder": {
        "en-us": "xxxxxxx",
        "es-mx": "xxxxxxx",
    },
    "credit-card-date-label": {
        "en-us": "Due date",
        "es-mx": "Fecha de vencimiento",
    },
    "credit-card-date-placeholder": {
        "en-us": "MM/YR",
        "es-mx": "MM/AA",
    },
    "payment-setup-title": {
        "en-us": "Set up your payment",
        "es-mx": "Configura tu pago",
    },
    "payment-setup-desc": {
        "en-us":
            "Your membership begins as soon as you set up payment. Cancel online whenever you want.",
        "es-mx":
            "Tu membresía comienza en cuanto configures el pago. Cancela online cuando quieras.",
    },
    // no esta incluidos
    "payment-confirm-title-credit-card": {
        "en-us": "Confirm your payment",
        "es-mx": "Confirma tu pago",
    },
    "payment-confirm-title-cash": {
        "en-us": "Confirm your payment",
        "es-mx": "Confirma tu pago",
    },
    "payment-confirm-title-in-app": {
        "en-us": "Confirm your payment",
        "es-mx": "Confirma tu pago",
    },
    "payment-confirm-desc-cash": {
        "en-us":
            "Paying in cash is very easy. By selecting this payment method you can make your payment at more than 23,000 points throughout the country. Remember that you have 48 hours to make your payment, otherwise your order will be canceled.",
        "es-mx":
            "Pagar en efectivo es muy sencillo. al seleccionar este método de pago podrás realizar tu pago en más de 23,000 puntos en todo el país. Recuerda que tienes 48 horas para realizar tu pago, de lo contrario tu orden será cancelada.",
    },
    "payment-confirm-subTitle": {
        "en-us": "Confirm your payment method and add a promo code if you have one.",
        "es-mx": "Confirma tu método de pago y agrega un código de promoción si lo tienes.",
    },
    "payment-confirm-desc-term-policy": {
        "en-us": "By confirming your purchase you are accepting our",
        "es-mx": "Al confirmar tu compra estás aceptando nuestros",
    },
    "payment-confirm-desc-term": {
        "en-us": "terms and conditions",
        "es-mx": "términos y condiciones",
    },
    "payment-confirm-desc-policy": {
        "en-us": "privacy policy",
        "es-mx": "política de privacidad",
    },
    "payment-confirm-desc-and": {
        "en-us": "and",
        "es-mx": "y",
    },
    "choose-your-plan-title": {
        "en-us": "Choose your plan",
        "es-mx": "Elige tu plan",
    },
    "choose-your-plan-continue": {
        "en-us": "Continue",
        "es-mx": "Continuar",
    },
    "choose-your-plan-description": {
        "en-us":
            "You can cancel at any time. If you choose to change your plan while you have one active, you will be charged for the new plan you chose.",
        "es-mx":
            "Puedes cancelar en cualquier momento. Si eliges cambiar tu plan mientras tienes uno activo, se te aplicará el cargo correspondiente al nuevo plan que elegiste.",
    },
    "plan-monthly-title": {
        "en-us": "Monthly Plan",
        "es-mx": "Plan Mensual",
    },
    "plan-monthly-subtitle": {
        "en-us": "/ monthly",
        "es-mx": "/ Mensual",
    },
    "plan-annually-title": {
        "en-us": "Anual Plan",
        "es-mx": "Plan Anual",
    },
    "plan-annually-subtitle": {
        "en-us": "/ Annually",
        "es-mx": "/ Anualmente",
    },
    "plan-specification-1-title": {
        "en-us": "+10 World-Class Cracks",
        "es-mx": "+10 Cracks mundiales",
    },
    "plan-specification-1-subtitle": {
        "en-us": "and a winning coaching staff for you",
        "es-mx": "y tu cuerpo técnico de lujo",
    },
    "plan-specification-2-title": {
        "en-us": "+250 pieces of training",
        "es-mx": "+250 entrenamientos",
    },
    "plan-specification-2-subtitle": {
        "en-us": "Short, dynamic, and interactive",
        "es-mx": "Cortos, interactivos y dinámicos",
    },
    "plan-specification-3-title": {
        "en-us": "+12 Physical programas",
        "es-mx": "+12 Programas físicos",
    },
    "plan-specification-3-subtitle": {
        "en-us": "with proven impact, for training from home",
        "es-mx": "con impacto probado, para entrenar desde tu casa",
    },
    "plan-specification-4-title": {
        "en-us": "2 free months",
        "es-mx": "2 Meses gratuitos",
    },
    "plan-specification-4-subtitle": {
        "en-us": "Of tailored physical and mental workout",
        "es-mx": "De entrenamiento personalizado a tu medida",
    },
    "leaving-app-description": {
        "en-us": "You are about to leave the app to our website to make your payment",
        "es-mx": "Estás a punto de salir de la app a nuestro sitio web para realizar tu pago",
    },
    "leaving-app-continue": {
        "en-us": "Continue",
        "es-mx": "Siguiente",
    },
    "payment-method-add": {
        "en-us": "Add payment method",
        "es-mx": "Añadir método de pago",
    },
    "payment-method-predetermined": {
        "en-us": "Select as default",
        "es-mx": "Seleccionar como principal",
    },
    "payment-method-delete": {
        "en-us": "Remove card",
        "es-mx": "Eliminar tarjeta",
    },
    "payment-method-cancel-button": {
        "en-us": "Cancel",
        "es-mx": "Cancelar",
    },

    "payment-delete-credit-card-desc": {
        "en-us":
            "If you run out of cards when your plan ends, the features you have will be deactivated.",
        "es-mx":
            "Si te quedas sin tarjetas cuando finalice tu plan, las funciones que tienes se desactivarán.",
    },
    "payment-delete-credit-card-button": {
        "en-us": "Remove",
        "es-mx": "Eliminar",
    },
    "payment-delete-credit-card-title": {
        "en-us": "Are you sure you want to delete this card?",
        "es-mx": "¿Estás seguro de querer eliminar esta tarjeta?",
    },
    "payment-history-title": {
        "en-us": "Payment history",
        "es-mx": "Historial de pagos",
    },
    "payment-history-desc": {
        "en-us":
            "You can receive a note of your subscription payment by clicking on the right side of your payment history, if you need to invoice your expenses, please contact",
        "es-mx":
            "Puedes recibir una nota de tu pago de suscripción al clickear del lado derecho de tu historial de pago, si necesitas facturar tus gastos, por favor ponte en contacto en",
    },
    "payment-history-contact": {
        "en-us": "team@mastercracks.club",
        "es-mx": "team@mastercracks.club",
    },
    "payment-history-subtitle": {
        "en-us": "History",
        "es-mx": "Historial",
    },
    "button-start-payment": {
        "en-us": "CHOOSE YOUR PLAN",
        "es-mx": "ELIGE TU PLAN",
    },
    "payment-status-charge-pending-button": {
        "en-us": "Confirm",
        "es-mx": "Aceptar",
    },
    "payment-status-charge-error-button": {
        "en-us": "payment-status-charge-error-button",
        "es-mx": "payment-status-charge-error-button",
    },
    "payment-cash-charge-ref": {
        "en-us": "The reference number is:",
        "es-mx": "El número de referencia es:",
    },
    "payment-cash-charge-link": {
        "en-us": "Download code here",
        "es-mx": "Descargar código aquí",
    },
    "payment-details-thanks": {
        "en-us": "Thank you for choosing MasterCracks",
        "es-mx": "Gracias por elegir MasterCracks",
    },
    "payment-details-quote": {
        "en-us": "A life with sport will always be better.",
        "es-mx": "Una vida con deporte siempre será mejor.",
    },
    "payment-details-total": {
        "en-us": "Total",
        "es-mx": "Total",
    },
    "payment-details-payment": {
        "en-us": "Payment",
        "es-mx": "Pago",
    },
    "payment-details-contact-us": {
        "en-us": "If you need to invoice your payment, please contact us at team@mastercracks.club",
        "es-mx":
            "Si necesitas facturar tu pago, por favor ponte en contacto con nosotros en team@mastercracks.club",
    },
    "trial-premium-title": {
        "en-us": "Start your 7 days free trial",
        "es-mx": "Comienza tu prueba gratis de 7 días",
    },
    "trial-premium-subtitle": {
        "en-us": "Credit card is NOT required.",
        "es-mx": "NO necesitas agregar ninguna tarjeta de crédito.",
    },
    "trial-premium-button": {
        "en-us": "Get started",
        "es-mx": "Empezar",
    },
    "questionnaire-submit": {
        "en-us": "Submit",
        "es-mx": "Enviar",
    },
    "questionnaire-finalize": {
        "en-us": "Complete",
        "es-mx": "Finalizar",
    },
    "questionnaire-question-placeholder": {
        "en-us": "Type here",
        "es-mx": "Escribe aquí",
    },
    "user-interest-title": {
        "en-us": "What would you like to achieve?",
        "es-mx": "¿Qué quieres lograr?",
    },
    "user-interest-desc": {
        "en-us": "Selected one or more options you would like to work with",
        "es-mx": "Selecciona una o más opciones con las que te gustaría trabajar",
    },
    "user-interest-option-1": {
        "en-us": "Improve my physical health",
        "es-mx": "Mejorar mi condición física",
    },
    "user-interest-option-2": {
        "en-us": "Strengthen my mind",
        "es-mx": "Fortalecer mi mente",
    },
    "user-interest-option-3": {
        "en-us": "Reduce stress and anxiety",
        "es-mx": "Reducir estrés y ansiedad",
    },
    "user-interest-option-4": {
        "en-us": "Get a better sleep",
        "es-mx": "Dormir mejor",
    },
    "user-interest-option-5": {
        "en-us": "All the above",
        "es-mx": "Todas las anteriores",
    },
    "secret-page-title": {
        "en-us": "The 11 secrets",
        "es-mx": "Los 11 secretos",
    },
    "secret-mental-yes-value-title": {
        "en-us": "The value of yes",
        "es-mx": "El valor del sí",
    },
    "tip-yes-value-title": {
        "en-us": "The value of yes",
        "es-mx": "El valor del sí",
    },
    "tip-strengths-weaknesses-title": {
        "en-us": "The value of yes",
        "es-mx": "Fortalezas y debilidades",
    },
    "tip-team-but-up-to-you-title": {
        "en-us": "The value of yes",
        "es-mx": "Es en equipo pero depende de ti",
    },
    "tip-whom-they-come-title": {
        "en-us": "The value of yes",
        "es-mx": "Las cosas de quien vienen",
    },
    "tip-success-end-title": {
        "en-us": "The value of yes",
        "es-mx": "El éxito no es el final",
    },
    "tip-answers-beginning-title": {
        "en-us": "The value of yes",
        "es-mx": "La respuesta está en el incio",
    },
    "tip-get-out-bed-title": {
        "en-us": "The value of yes",
        "es-mx": "Levántate de la cama",
    },
    "tip-best-coach-title": {
        "en-us": "The value of yes",
        "es-mx": "El mejor entrenador del mundo eres tú",
    },
    "tip-well-done-nap-title": {
        "en-us": "The value of yes",
        "es-mx": "El valor de una siesta bien hecha",
    },
    "tip-effects-jetlag-title": {
        "en-us": "The value of yes",
        "es-mx": "Cómo contrarestar los efectos del JetLag",
    },
    "tip-know-your-chronotype-title": {
        "en-us": "The value of yes",
        "es-mx": "Conoce tu cronotipo",
    },
    "tip-caffeine-use-title": {
        "en-us": "The value of yes",
        "es-mx": "Cómo optimizar el uso de la cafeína",
    },
    "tip-sleep-environment-title": {
        "en-us": "The value of yes",
        "es-mx": "Creando tu ambiente de sueño ideal",
    },
    "tip-effect-electronic-screens-title": {
        "en-us": "The value of yes",
        "es-mx": "Efecto nocivo de las pantallas electrónicas antes de dormir",
    },
    "tip-athletes-dream-title": {
        "en-us": "The value of yes",
        "es-mx": "El sueño del atleta",
    },
    "tip-dont-stop-learning-title": {
        "en-us": "The value of yes",
        "es-mx": "Ya eres buen@... Ahora no dejes de aprender",
    },
    "tip-win-in-your-mind-title": {
        "en-us": "The value of yes",
        "es-mx": "Primero gana en tu mente, luego gana en la cancha",
    },
    "tip-finish-what-you-do-title": {
        "en-us": "The value of yes",
        "es-mx": "Termina lo que haces",
    },
    "about-us-title-1": {
        "en-us": "What we do MasterCracks",
        "es-mx": "Qué hacemos en MasterCracks",
    },
    "about-us-subtitle-1": {
        "en-us": "We are a disruptive transformational app that is MUCH MORE THAN JUST AN APP",
        "es-mx": "Somos una disruptiva app transformacional que es MUCHO MÁS QUE UNA APP",
    },
    "about-us-subtitle-2": {
        "en-us":
            "<span data-preset='concepcion'>MasterCracks</span> is a community, and also a space for personal and <span data-preset='lampa'>group growth, motivation, and inspiration.</span>",
        "es-mx":
            "<span data-preset='concepcion'>MasterCracks</span> es una una comunidad, y también un espacio de <span data-preset='lampa'>crecimiento, motivación e inspiración.</span>",
    },
    "about-us-desc-1": {
        "en-us":
            "<p data-preset='lanus' class='line-break'>With the guidance of a <span data-preset='concepcion'>World-Class coaching staff</span> and step-by-step personalized training programs, you will transform your life emotionally, mentally, and physically, and reach the best version of yourself in key areas for good living such as sleep, resilience, leadership, self-motivation, stress management, good habits, and financial health.</p>",
        "es-mx":
            "<p data-preset='lanus' class='line-break'>Con la orientación de <span data-preset='concepcion'>tu propio cuerpo técnico de lujo</span> y entrenamientos personalizados paso a paso, transformarás tu vida emocional, mental y físicamente, para que disfrutes cada día de tu mejor versión en áreas clave como descanso, nutrición, resiliencia, auto-motivación, manejo del estrés, buenos hábitos y salud financiera.</p>",
    },
    "about-us-subtitle-3": {
        "en-us": "The Cracks that will show you the way",
        "es-mx": "Los Cracks que te mostrarán el camino",
    },
    "about-us-desc-2": {
        "en-us":
            "Rafa Márquez, Gigi Buffon, Pável Pardo, Carlos Salcido, Norma Palafox, Jared Borgetti, Charlyn Corral, and Oswaldo Sánchez. In addition to these Cracks, our coaching staff is composed of a selection of high-performance trainers and coaches.",
        "es-mx":
            "Rafa Márquez, Pável Pardo, Carlos Salcido, Norma Palafox, Jared Borgetti, Charlyn Corral y Oswaldo Sánchez. Y a ellos se les suma una selección de entrenadores y coaches que te llevarán a alcanzar tu mejor versión.",
    },
    "about-us-desc-3": {
        "en-us":
            "In addition to these Cracks, our coaching staff is composed of a selection of high-performance trainers and coaches.",
        "es-mx":
            "Y a ellos se les suma una selección de entrenadores y coaches que te llevarán a alcanzar tu mejor versión.",
    },
    "about-us-title-3": {
        "en-us": "Us",
        "es-mx": "Nosotros",
    },
    "about-us-desc-4": {
        "en-us":
            "<p data-preset='lanus' class='line-break'>The <span data-preset='concepcion'>MasterCracks</span> team is made up of a group of dreamers, passionate about personal development and soccer lovers; Led by our co-founder and CEO Alejandro Goldberg, the base of our team is in Mexico and the United States and extends with collaborators scattered throughout the world. </p>",
        "es-mx":
            "<p data-preset='lanus' class='line-break'>La plantilla de <span data-preset='concepcion'>MasterCracks</span> está compuesta por un grupo de soñadoras y soñadores apasionad@s por el desarrollo personal y amantes del fútbol;  conducida por nuestro co-fundador y CEO Alejandro Goldberg, la base de nuestro team está en México y Estados Unidos, y se extiende con colaboradores esparcidos por todo el mundo. </p>",
    },
    "about-us-desc-5": {
        "en-us":
            "<p data-preset='lanus' class='line-break'>Everything we do, we do it for you, so your feedback is worth gold! </p> Do not hesitate to write to us to tell us about your experience with the App or for any questions or suggestions: team@mastercracks.club.",
        "es-mx":
            "<p data-preset='lanus' class='line-break'>Todo lo que hacemos, lo hacemos por y para que nuestros usuarios puedan desarrollar su mejor versión, ¡así que tu feedback vale oro! </p> No dudes en escribirnos para contarnos tu experiencia en la App o por cualquier duda o sugerencia: team@mastercracks.club.",
    },
    "redirect-timer": {
        "en-us": "Returning to the start in %",
        "es-mx": "Regresando al inicio en %",
    },
    "redirect-button": {
        "en-us": "Return to start",
        "es-mx": "Regresar al inicio",
    },
    "error-500-title": {
        "en-us": "Something went wrong",
        "es-mx": "Algo Salió Mal",
    },
    "error-500-desc": {
        "en-us": "There was an unexpected error. We will redirect you to the start.",
        "es-mx": "Hubo un error inesperado, te redigiremos al inicio.",
    },
    "error-404-title": {
        "en-us": "Crack, you are in offside!",
        "es-mx": "¡Estás en fuera de lugar crack!",
    },
    "error-404-desc": {
        "en-us": "We couldn’t find this page.",
        "es-mx": "No encontramos la página que estabas buscando.",
    },
    "error-conection-title": {
        "es-mx": "Sin conexión a Internet.",
        "en-us": "No Internet connection.",
    },

    "error-conection-desc": {
        "en-us": "To enjoy our content, you need to be connected to the Internet.",
        "es-mx": "Para disfrutar de nuestros contenidos necesitas estar conectado a internet.",
    },
    "error-download-title": {
        "en-us": "Descarga la última versión.",
        "es-mx": "Download our latest version.",
    },
    "error-download-desc": {
        "en-us": "To keep enjoying our content, yoy have to get the latest version of the app.",
        "es-mx":
            "Para seguir disfrutando de nuestros contenidos necesitas descargar la última versión de la app.",
    },

    "faq-title-1": {
        "en-us": "General",
        "es-mx": "General",
    },

    "faq-question-1-title": {
        "en-us": "What is MasterCracks?",
        "es-mx": "¿Qué es MasterCracks?",
    },
    "faq-question-1-desc": {
        "en-us":
            "We are a self-growth platform whose purpose is to help you to unleash your maximum potential, so you can achieve your goals and your dreams through comprehensive training (physical, mental, and emotional) guided by legendary Cracks of soccer and a luxury coaching staff -made up of doctors, coaches, physical trainers, and personal/organizational transformation experts.",
        "es-mx":
            "Somos una plataforma de desarrollo personal que tiene como finalidad liberar tu máximo potencial, para que puedas cumplir tus metas y tus sueños a través de un entrenamiento integral (físico, mental y emocional) guiado por los grandes Cracks del fútbol y un cuerpo técnico de lujo, compuesto por doctores, coaches, preparadores físicos y expertos en transformación grupal y organizacional.",
    },
    "faq-question-2-title": {
        "en-us": "How does it works?",
        "es-mx": "¿Cómo funciona?",
    },
    "faq-question-2-desc": {
        "en-us":
            "Download the App now and start enjoying any of our fitness exercises or mental health programs, training wherever you want. Our transformative programs and the “11 secrets” trainings are made to walk you in the development of your best version, in fundamental aspects such as discipline, self-esteem, concentration, resilience and perseverance.",
        "es-mx":
            "Descarga la App y empieza a disfrutar de cualquiera de nuestros ejercicios de fitness o programas de acondicionamiento mental y emocional, ¡y entrena tu cuerpo y tu mente desde la comodidad de tu hogar! Encontrarás transformadores contenidos de mindset con los que desarrollarás tu mejor versión en los aspectos más importantes de tu vida, gracias a los 11 Secretos de los Crack",
    },
    "faq-title-2": {
        "en-us": "Plans and payment",
        "es-mx": "Planes y Pagos",
    },
    "faq-question-3-title": {
        "en-us": "How do the plans work?",
        "es-mx": "¿Cómo funcionan los planes?",
    },
    "faq-question-3-desc": {
        "en-us":
            "With your monthly or annual subscription to MasterCracks, you can access ALL the contents of the platform: physical and mental training programs, soccer skills learning modules, inspiring stories, and the 11 Secrets of Cracks growth programs.",
        "es-mx":
            "Con tu suscripción mensual o anual a MasterCracks, puedes acceder a TODOS los contenidos de la plataforma: programas de entrenamiento físico y mental, módulos de aprendizaje de skills futbolísticos, inspiradoras historias y el programa de crecimiento de los 11 Secretos de los Cracks.",
    },

    "faq-question-4-title": {
        "en-us": "What benefits do I get by getting the Monthly Plan?",
        "es-mx": "Qué beneficios obtengo al contratar el Plan Mensual?",
    },
    "faq-question-4-desc": {
        "en-us":
            "With your Monthly Plan, you have limitless access to all the contents of the platform: you can view and use the workouts whenever and as many times as you want, to find your best shape from the comfort of your home.",
        "es-mx":
            "Con tu Plan Mensual, tienes acceso a todos los contenidos de la plataforma, sin límite de uso: puedes ver y utilizar los entrenamientos cuando y cuantas veces quieras, para encontrar tu mejor forma desde la comodidad de tu hogar.",
    },
    "faq-question-5-title": {
        "en-us": "What benefits do I get by getting the Yearly Plan?",
        "es-mx": "¿Qué beneficios obtengo al contratar el Plan Anual?",
    },
    "faq-question-5-desc": {
        "en-us":
            "By paying your Yearly Plan, you enjoy MasterCracks with 2 months of free subscription.",
        "es-mx":
            "Abonando tu Plan Anual, disfrutas de MasterCracks con dos meses de suscripción gratis  + acceso a materiales adicionales exclusivos.",
    },
    "faq-question-6-title": {
        "en-us": "Can I use MasterCracks for free?",
        "es-mx": "¿Puedo usar MasterCracks gratis?",
    },
    "faq-question-6-desc": {
        "en-us":
            "Yes! You can start enjoying our app FREE for 14 days, without using your card. Then, if you enjoyed the experience, you can contract our Monthly or Yearly plans.",
        "es-mx":
            "Sí, hoy mismo puedes empezar a disfrutar GRATIS de nuestra app por 14 días, sin necesidad de usar tu tarjeta. Luego, si te sientes a gusto, puedes contratar nuestro plan mensual o anual.",
    },
    "faq-question-7-title": {
        "en-us": "How do coupons work?",
        "es-mx": "¿Cómo funcionan los cupones?",
    },
    "faq-question-7-desc": {
        "en-us":
            "If you have a promotional coupon, apply it on the payment page and the corresponding discount will automatically be assigned to you.",
        "es-mx":
            "Si tienes un cupón promocional, aplícalo en la página de pagos y automáticamente se te asignará el descuento correspondiente.",
    },
    "faq-question-8-title": {
        "en-us": "What happens if I cancel?",
        "es-mx": "¿Qué pasa si cancelo?",
    },
    "faq-question-8-desc": {
        "en-us":
            "You can cancel your Monthly Plan at any time, and you will stop paying for your subscription once the last stipulated transaction is completed. The Yearly Plan can be canceled only once a year. We'll send you a reminder when it's about to expire, so you can decide if you want to continue the subscription or cancel it before the plan is renewed.",
        "es-mx":
            "Puedes cancelar tu Plan Mensual en cualquier momento, y dejarás de pagar tu suscripción una vez que se complete la última transacción estipulada. Tu Plan Anual se cancela solo una vez por año. Te enviaremos un recordatorio cuando esté por caducar, para que decidas si quieres continuar la suscripción o cancelarla antes de que se haga la renovación del plan.",
    },
    "faq-question-9-title": {
        "en-us": "How do I cancel my subscription through Openpay?",
        "es-mx": "¿Cómo cancelo mi suscripción a través de Openpay?",
    },
    "faq-question-9-desc": {
        "en-us":
            "From the MasterCracks app, enter your profile settings and press the “Plans and payments” button. This option will open a new tab in your browser, where you will see the “Cancel subscription” option at the end.",
        "es-mx":
            "Desde la app MasterCracks, ingresa a la configuración de tu perfil, presiona la opción “Planes y pagos”, esta opción te abrirá una pestaña en tu navegador, donde podrás ver al final la opción “Cancelar suscripción”.",
    },
    "faq-question-10-title": {
        "en-us": "How do I cancel my subscription through Google Play?",
        "es-mx": "¿Cómo cancelo mi suscripción a través de Google Play?",
    },
    "faq-question-10-desc": {
        "en-us":
            'If you subscribed through Google Play, go to <a onClick=\'window.open("https://support.google.com/googleplay/answer/7018481?hl=es-419&co=GENIE.Platform%3DAndroid", "_system", "location=yes")\'>this link</a> to find out how to cancel your subscription.',
        "es-mx":
            'Si te suscribiste a través de Google Play, ingresa a <a onClick=\'window.open("https://support.google.com/googleplay/answer/7018481?hl=es-419&co=GENIE.Platform%3DAndroid", "_system", "location=yes")\'>este enlace</a> para saber cómo cancelar tu suscripción.',
    },
    "faq-question-11-title": {
        "en-us": "How do I cancel my subscription through the Play Store?",
        "es-mx": "¿Cómo cancelo mi suscripción a través de Play Store?",
    },
    "faq-question-11-desc": {
        "en-us":
            'If you subscribed through Play Store, go to <a onClick=\'window.open("https://support.apple.com/es-mx/HT202039", "_system", "location=yes")\'>this link</a> to find out how to cancel your subscription.',
        "es-mx":
            'Si te suscribiste a través de Play Store, ingresa a <a onClick=\'window.open("https://support.apple.com/es-mx/HT202039", "_system", "location=yes")\'>este enlace</a> para saber cómo cancelar tu suscripción.',
    },

    "landing-title": {
        "en-us": "Join MasterCracks",
        "es-mx": "Únete a MasterCracks",
    },
    "landing-button": {
        "en-us": "Create account",
        "es-mx": "Crear cuenta",
    },
    "landing-all-cracks-desc": {
        "en-us":
            "Join the MasterCracks community now and start enjoying our transformative. With the guidance of your own luxury coaching staff and step-by-step personalized training, MC will transform your life emotionally, mentally, and physically, so that you can enjoy your best every day. version.",
        "es-mx":
            "Únete ahora a la comunidad de MasterCracks  y empieza a disfrutar de la app que, con la orientación de tu propio <span data-preset='lampa'>cuerpo técnico de lujo y entrenamientos personalizados paso a paso, transformará tu vida </span>emocional, mental y físicamente, para que disfrutes cada día de tu mejor versión.",
    },
    "landing-business-steren-desc": {
        "en-us": "landing-steren-desc",
        "es-mx":
            "<p class='line-break'>¡Te damos la bienvenida a MasterCracks!.</p><p class='line-break'> Empieza a disfrutar hoy mismo de nuestro <span data-preset='lampa'>cuerpo técnico de lujo y entrenamientos personalizados</span> paso a paso, que te darán las herramientas para descubrir tu mejor versión y<span data-preset='lampa'> transformar tu vida</span> emocional, mental y físicamente, para que disfrutes cada día de tu mejor versión.</p>",
    },
    "landing-academias-por-la-paz-desc": {
        "en-us": "landing-academias-por-la-paz-desc",
        "es-mx":
            "<p class='line-break'>¡Te damos la bienvenida a MasterCracks!.</p><p class='line-break'> Hoy, Academias por La Paz, de la mano de MasterCracks, pone a tu alcance un sinfín de <span data-preset='lampa'>herramientas y contenido</span> para que descubras todo lo que puedes aprender, conocerte y crecer a nivel<span data-preset='lampa'> físico, mental y emocional</span> para que juntos construyamos una cultura de paz.</p><p class='line-break'>Nuestra misión conjunta es desarrollar un entorno sano donde el <span data-preset='lampa'>respeto, trabajo en equipo, solidaridad y confianza</span> te ayuden a alcanzar tus metas y sueños.</p><p class='line-break'>Empieza a disfrutar hoy mismo de nuestro <span data-preset='lampa'>cuerpo técnico de lujo y de todo el contenido que tenemos para ti!</span></p>",
    },
    "landing-rafael-marquez-desc": {
        "en-us":
            "<p class='line-break'>Hi there, Crack!</p><p class='line-break'> After playing five FIFA World Cups, having been Captain of the Mexican National Team, and wearing the jersey of teams like Barcelona, I have the privilege of being recognized throughout the world. You should know that <span data-preset='lampa'>behind my success there is only work, dedication and effort:</span> I would not achieve anything if I'd not lived by and for soccer!</p><p class='line-break'>Enter MasterCracks and discover all you can learn, get to know yourself, and <span data-preset='lampa'>grow physically and mentally with my personalized coaching for you.</span></p> <p class='line-break'>Un abrazo,</p><p class='line-break'>Rafa</p>",
        "es-mx":
            "<p class='line-break'>Hola, Crack.</p><p class='line-break'> Jugar cinco Mundiales, haber sido Capitán y leyenda de la Selección Mexicana y vestir la playera de equipos como el Barcelona me llevaron a ser reconocido en todo el mundo, pero debes saber que <span data-preset='lampa'>detrás de mi éxito solo hay trabajo, dedicación y esfuerzo:</span> no hubiera logrado nada si no hubiera vivido por y para el fútbol.</p><p class='line-break'>Ingresa a MasterCracks y descubre todo lo que puedes aprender, conocerte y crecer a nivel físico y mental con <span data-preset='lampa'>mi coaching personalizado para ti.</span></p> <p class='line-break'>Un abrazo,</p><p class='line-break'>Rafa</p>",
    },
    "landing-oswaldo-sanchez-desc": {
        "en-us":
            "<p class='line-break'>Hello, Crack!</p><p class='line-break'>Welcome you to the platform that will allow you to train your body, your mind, and your emotions to transform your life.</p><p class='line-break'>Enter MasterCracks and discover everything you can learn, get to know yourself, and grow physically and mentally with <span data-preset='lampa'>my personalized coaching for you.</span></p><p class='line-break'>I don't promise you that you will become a national team goalkeeper... but rest assured that <span data-preset='lampa'>our platform will help you to change your life.</span></p><p class='line-break'>Un abrazo,</p><p class='line-break'>“San Oswaldo”</p>",
        "es-mx":
            "<p class='line-break'>Hola, Crack.</p><p class='line-break'>Te doy la bienvenida a la plataforma que te permitirá entrenar tu cuerpo, tu mente y tus emociones para transformar tu vida.</p><p class='line-break'>Ingresa a MasterCracks y descubre todo lo que puedes aprender, conocerte y crecer a nivel físico y mental con <span data-preset='lampa'>mi coaching personalizado para ti.</span></p><p class='line-break'>No te prometo que te convertirás en un portero de selección…<span data-preset='lampa'>pero ten la certeza de que nuestra app te va a cambiar la vida.</span></p><p class='line-break'>Un abrazo,</p><p class='line-break'>“San Oswaldo”</p>",
    },
    "landing-norma-palafox-desc": {
        "en-us":
            "<p class='line-break'>Hello, Crack!</p><p class='line-break'>You probably already know that soccer not only changed my life… but it is my life.</p><p class='line-break'>However, today I do not want to talk to you about goals, assists, or titles, but about personal transformation: behind each reputed Crack, <span data-preset='lampa'>there is physical, mental, and emotional training happening 24/7</span>, and I did and do mine through MasterCracks.</p><p class='line-break'>Enter our app and discover everything you can learn, get to know yourself, and grow with my personalized coaching.</p><p class='line-break'>Hugs,</p><p class='line-break'>Norma</p>",
        "es-mx":
            "<p class='line-break'>Hola, Crack.</p><p class='line-break'>Probablemente ya sepas que el fútbol no solo cambió mi vida… sino que, simplemente, es mi vida.</p><p class='line-break'>Sin embargo, hoy no quiero hablarte de goles, asistencias o títulos, sino de <span data-preset='lampa'>transformación personal: detrás de cada Crack consagrado, hay un entrenamiento físico, mental y emocional</span> que sostiene las cualidades futbolísticas, y el mío lo hice y hago a través de MasterCracks.</p><p class='line-break'>Ingresa a nuestra app y descubre todo lo que puedes aprender, conocerte y crecer con <span data-preset='lampa'>mi coaching personalizado para ti.</span></p><p class='line-break'>Un abrazo,</p><p class='line-break'>Norma</p>",
    },
    "landing-buffon-academy-desc": {
        "en-us":
            "<p class='line-break'>Ciao, Crack!</p><p class='line-break'>If you thought that my love for football was going to end... you are absolutely wrong.</p><p class='line-break'>This beautiful passion never ends! Both in football and in life, I always have one more game to play or something new to learn.</p><p class='line-break'>You should think alike too: enter MasterCracks and <span data-preset='lampa'>discover everything you can learn, grow and get to know yourself with my personalized coaching.</span></p><p class='line-break'>Good luck!</p><p class='line-break'>Gigi Buffon</p>",
        "es-mx":
            "<p class='line-break'>Hola, Crack.</p><p class='line-break'>Si pensabas que el amor por el fútbol se me iba a acabar…estás equivocado.</p><p class='line-break'>¡Esta hermosa pasión no se termina nunca! Tanto en el fútbol como en la vida, siempre tengo un partido más por jugar o algo nuevo para aprender.</p><p class='line-break'>Tú también deberías hacerlo: ingresa a MasterCracks y descubre todo lo que puedes aprender, crecer y conocerte con <span data-preset='lampa'>mi coaching personalizado para ti.</span></p><p class='line-break'>Un abrazo,</p><p class='line-break'>Gigi Buffon</p>",
    },
    "landing-jo-canales-desc": {
        "en-us":
            "<p class='line-break'>Hello, Crack.</p><p class='line-break'>I want you to join MasterCracks so you can discover all the potential you have inside: I'm sure you have a lot to give!</p><p class='line-break'>Enter the App now and learn how you can improve your physical condition -without leaving your home- with <span data-preset='lampa'>with my personalized coaching for you.</span></p><p class='line-break'>Un abrazo,</p><p class='line-break'>Jo Canales</p>",
        "es-mx":
            "<p class='line-break'>Hola, Crack.</p><p class='line-break'>Quiero que te sumes a MasterCracks para que descubras todo el potencial que tienes dentro: ¡estoy seguro que tienes muchísimo para dar!</p><p class='line-break'>Ingresa ahora a la App y descubre cómo puedes mejorar tu condición física sin salir de tu casa con <span data-preset='lampa'>mi coaching personalizado para ti.</span></p><p class='line-break'>Un abrazo,</p><p class='line-break'>Jo</p>",
    },
    "landing-carlos-salcido-desc": {
        "en-us":
            "<p class='line-break'>Hello, Crack.</p><p class='line-break'>Today, I am grateful to be a legend of the Mexican National Team, but I had to walk a road full of obstacles and <span data-preset='lampa'>overcome many unexpected challenges to build my career.</span></p><p class='line-break'>Enter MasterCracks and discover everything you can learn, <span data-preset='lampa'>get to know yourself, and grow physically and mentally with my personalized coaching for you.</span></p><p class='line-break'>Big hug,</p><p class='line-break'>Carlos</p>",
        "es-mx":
            "<p class='line-break'>Hola, Crack.</p><p class='line-break'>Hoy en día tengo la fortuna de ser una leyenda de la Selección Mexicana, pero tuve que recorrer un <span data-preset='lampa'>camino lleno de obstáculos y superar muchísimos desafíos inesperados</span> para construir mi carrera.</p><p class='line-break'>Ingresa a MasterCracks y descubre todo lo que puedes aprender, conocerte y crecer a nivel físico y mental con <span data-preset='lampa'>mi coaching personalizado para ti.</span></p><p class='line-break'>Un abrazo,</p><p class='line-break'>Carlos</p>",
    },
    "landing-jared-borgetti-desc": {
        "en-us":
            "<p class='line-break'>Hi there, Crack.</p><p class='line-break'>My goals were celebrated by all Mexicans, but now I want to share with you something much more significant and transcendental, a tool that will <span data-preset='lampa'>allow you to reach your best version and discover the Crack that you carry inside to transform your life.</span></p><p class='line-break'>Enter MasterCracks and discover everything you can learn, get to know yourself, and grow physically and mentally with my personalized coaching for you.</p><p class='line-break'>Un abrazo de gol,</p><p class='line-break'>Jared</p>",
        "es-mx":
            "<p class='line-break'>Hola, Crack.</p><p class='line-break'>Mis goles fueron celebrados por todos los mexicanos, pero ahora quiero compartirte algo mucho más <span data-preset='lampa'>significante y trascendental</span>, una herramienta que te permitirá alcanzar tu mejor versión y descubrir al Crack que llevas dentro para <span data-preset='lampa'>transformar tu vida.</span></p><p class='line-break'>Ingresa a MasterCracks y descubre todo lo que puedes aprender, conocerte y crecer a nivel físico y mental con <span data-preset='lampa'>mi coaching personalizado para ti.</span></p><p class='line-break'>Un abrazo,</p><p class='line-break'>Jared</p>",
    },
    "landing-charlyn-corral-desc": {
        "en-us":
            "<p class='line-break'>Hello, Crack.</p><p class='line-break'>Today, soccer is at the center of my life, and it is what people recognize me for.</p><p class='line-break'>But I want you to know that behind each goal and each tournament won, there was an <span data-preset='lampa'>incessant physical, mental and emotional training </span>that allowed me to achieve these results.</p><p class='line-break'>You can do it too: enter MasterCracks and <span data-preset='lampa'>discover everything you can learn, get to know yourself, and grow with my personalized coaching for you.</span></p><p class='line-break'>Un abrazo,</p><p class='line-break'>Charlyn</p>",
        "es-mx":
            "<p class='line-break'>Hola, Crack.</p><p class='line-break'>Hoy en día, el fútbol está en el centro de mi vida, y es por lo que la gente me reconoce.</p><p class='line-break'>Pero quiero que sepas que <span data-preset='lampa'>detrás de cada gol y cada torneo conquistados, hubo un entrenamiento físico, mental y emocional incesante</span> que me permitió alcanzar estos resultados.</p><p class='line-break'>Tú también puedes hacerlo: ingresa a MasterCracks y descubre todo lo que puedes aprender, conocerte y crecer con <span data-preset='lampa'>mi coaching personalizado para ti.</span></p><p class='line-break'>Un abrazo,</p><p class='line-break'>Charlyn</p>",
    },
    "landing-pavel-pardo-desc": {
        "en-us":
            "<p class='line-break'>Hello, Crack!</p><p class='line-break'>Surely you already know my career and my main sporting achievements, but you should know that to achieve that success and recognition there was <span data-preset='lampa'>constant mental and physical work, a lot of resilience, and faith in myself that no one else had.</span></p><p class='line-break'>Enter MasterCracks and discover everything you can learn, get to know yourself, and <span data-preset='lampa'>grow physically and mentally with my personalized coaching for you.</span></p><p class='line-break'>Good luck and enjoy your transformational trip,</p><p class='line-break'>Pável</p>",
        "es-mx":
            "<p class='line-break'>Hola, Crack.</p><p class='line-break'>Seguramente ya conozcas mi trayectoria y mis principales logros deportivos, pero debes saber que para lograr ese éxito y reconocimiento hubo un <span data-preset='lampa'>trabajo mental y físico constante, mucha resiliencia y una fé en mí mismo que nadie más tenía.</span></p><p class='line-break'>Ingresa a MasterCracks y descubre todo lo que puedes aprender, conocerte y crecer a nivel físico y mental con <span data-preset='lampa'>mi coaching personalizado para ti.</span></p><p class='line-break'>Un abrazo,</p><p class='line-break'>Pável</p>",
    },
    "survey-start-title": {
        "en-us": "Hello Crack!",
        "es-mx": "¡Hola, Crack!",
    },
    "survey-start-desc": {
        "en-us":
            "<p class='line-break'>We invite you to answer some questions that will allow you <span data-preset='lampa'>to get to know yourself better and find out how you are today</span>, so that you have a point of reference about your performance and your quality of life.</p><p class='line-break'>You also help us personalize your experience on the App.</p><p class='line-break'>Your answers are totally confidential.</p>",
        "es-mx":
            "<p class='line-break'>A continuación, te invitamos a responder unas preguntas que <span data-preset='lampa'>te permitirán conocerte mejor y saber cómo te encuentras hoy</span>, para que tengas un punto de referencia sobre tu desempeño y tu calidad de vida.</p><p class='line-break'>También nos ayudas a personalizar tu experiencia en la App.</p><p class='line-break'>Tus respuestas son totalmente confidenciales.</p>",
    },
    "survey-start-button": {
        "en-us": "Start",
        "es-mx": "Iniciar",
    },

    "survey-finish-title": {
        "en-us": "All set, it's time to train!",
        "es-mx": "Listo, ¡es hora de entrenar!",
    },
    "survey-finish-desc": {
        "en-us": "Start enjoying our programs and awaken the Crack inside you.",
        "es-mx":
            "Empieza a disfrutar de nuestros programas y despierta al Crack que llevas dentro.",
    },

    "survey-question-selfesteem": {
        "en-us": "How satisfied are you with your self-esteem and self-image?",
        "es-mx": "¿Qué tan satisfecho estás con tu autoestima y autoimagen?",
    },

    "survey-question-happiness": {
        "en-us": "How would you rate your overall level of happiness and emotional well-being?",
        "es-mx": "¿Cómo calificarías tu nivel general de felicidad y bienestar emocional?",
    },

    "survey-question-emotions": {
        "en-us": "How well can you express and manage your emotions (anger, sadness, fear, joy)?",
        "es-mx":
            "¿Qué tan bien puedes expresar y manejar tus emociones (enojo, tristeza, miedo, alegría)?",
    },

    "survey-question-difficulties": {
        "en-us":
            "How resilient do you consider yourself when facing difficult or adverse situations?",
        "es-mx":
            "¿Cuán resiliente te consideras cuando enfrentas situaciones difíciles o adversas?",
    },

    "survey-question-nutrition": {
        "en-us": "How satisfied are you with your eating and nutrition habits?",
        "es-mx": "¿Qué tan satisfecho estás con tus hábitos de alimentación y nutrición?",
    },

    "survey-question-sleep": {
        "en-us": "How would you rate the quality of your sleep and the amount of rest you get?",
        "es-mx":
            "¿Cómo calificarías la calidad de tu sueño y la cantidad de descanso que obtienes?",
    },

    "survey-answer-satisfaction-1": {
        "en-us": "Very unsatisfied",
        "es-mx": "Muy insatisfecho",
    },
    "survey-answer-satisfaction-2": {
        "en-us": "Insatisfied",
        "es-mx": "Insatisfecho",
    },
    "survey-answer-satisfaction-3": {
        "en-us": "Neutral",
        "es-mx": "Neutral",
    },
    "survey-answer-satisfaction-4": {
        "en-us": "Satisfied",
        "es-mx": "Satisfecho",
    },
    "survey-answer-satisfaction-5": {
        "en-us": "Very satisfied",
        "es-mx": "Muy satisfecho",
    },

    "survey-answer-level-1": {
        "en-us": "Very low",
        "es-mx": "Muy bajo",
    },
    "survey-answer-level-2": {
        "en-us": "Low",
        "es-mx": "Bajo",
    },
    "survey-answer-level-3": {
        "en-us": "Neutral",
        "es-mx": "Neutral",
    },
    "survey-answer-level-4": {
        "en-us": "High",
        "es-mx": "Alto",
    },
    "survey-answer-level-5": {
        "en-us": "VeryHigh",
        "es-mx": "Muy alto",
    },

    "survey-answer-perception-1": {
        "en-us": "Very bad",
        "es-mx": "Muy mal",
    },
    "survey-answer-perception-2": {
        "en-us": "Bad",
        "es-mx": "Mal",
    },
    "survey-answer-perception-3": {
        "en-us": "Neutral",
        "es-mx": "Neutral",
    },
    "survey-answer-perception-4": {
        "en-us": "Good",
        "es-mx": "Bien",
    },
    "survey-answer-perception-5": {
        "en-us": "Very good",
        "es-mx": "Muy bien",
    },

    "survey-answer-resilience-1": {
        "en-us": "Very little resilient",
        "es-mx": "Muy poco resiliente",
    },
    "survey-answer-resilience-2": {
        "en-us": "Little resilient",
        "es-mx": "Poco resiliente",
    },
    "survey-answer-resilience-3": {
        "en-us": "Neutral",
        "es-mx": "Neutral",
    },
    "survey-answer-resilience-4": {
        "en-us": "Resilient",
        "es-mx": "Resiliente",
    },
    "survey-answer-resilience-5": {
        "en-us": "Very resilient",
        "es-mx": "Muy resiliente",
    },

    "survey-answer-quality-1": {
        "en-us": "Very poor",
        "es-mx": "Muy pobre",
    },
    "survey-answer-quality-2": {
        "en-us": "Poor",
        "es-mx": "Pobre",
    },
    "survey-answer-quality-3": {
        "en-us": "Neutral",
        "es-mx": "Neutral",
    },
    "survey-answer-quality-4": {
        "en-us": "Good",
        "es-mx": "Bueno",
    },
    "survey-answer-quality-5": {
        "en-us": "Excellent",
        "es-mx": "Excelente",
    },
    "survey-answer-frecuency-1": {
        "en-us": "Not at all",
        "es-mx": "Para nada",
    },
    "survey-answer-frecuency-2": {
        "en-us": "Quite often",
        "es-mx": "Bastante seguido",
    },
    "survey-answer-frecuency-3": {
        "en-us": "More than half the days",
        "es-mx": "Más de la mitad de los días",
    },
    "survey-answer-frecuency-4": {
        "en-us": "Practically daily",
        "es-mx": "Prácticamente a diario",
    },
    "survey-answer-difficulty-1": {
        "en-us": "Very difficult",
        "es-mx": "Muy difícil",
    },
    "survey-answer-difficulty-2": {
        "en-us": "Difficult",
        "es-mx": "Difícil",
    },
    "survey-answer-difficulty-3": {
        "en-us": "Neutral",
        "es-mx": "Neutral",
    },
    "survey-answer-difficulty-4": {
        "en-us": "Easy",
        "es-mx": "Fácil",
    },
    "survey-answer-difficulty-5": {
        "en-us": "Very easy",
        "es-mx": "Muy fácil",
    },
    "survey-answer-affectation-1": {
        "en-us": "Nothing",
        "es-mx": "Nada",
    },
    "survey-answer-affectation-2": {
        "en-us": "Something: it makes me lose several hours a day",
        "es-mx": "Algo: me hace perder varias horas al día",
    },
    "survey-answer-affectation-3": {
        "en-us": "Quite a lot: it is the reason for my constant procrastination",
        "es-mx": "Bastante: es el motivo de mi procrastinación constante",
    },
    "survey-answer-affectation-4": {
        "en-us": "A lot: stress paralyzes me",
        "es-mx": "Mucho: el estrés me paraliza",
    },
    "survey-answer-program-7am": {
        "en-us": "7 am",
        "es-mx": "7 am",
    },
    "survey-answer-program-9am": {
        "en-us": "9-10 am",
        "es-mx": "9-10 am",
    },
    "survey-answer-program-11am": {
        "en-us": "11 am onwards",
        "es-mx": "11 am en adelante",
    },
    "survey-answer-program-yes": {
        "en-us": "Yes",
        "es-mx": "Sí",
    },
    "survey-answer-program-no": {
        "en-us": "No",
        "es-mx": "No",
    },
    "survey-answer-program-urgent": {
        "en-us": "Urgent",
        "es-mx": "Urgente",
    },
    "survey-answer-program-important": {
        "en-us": "Important",
        "es-mx": "Importante",
    },
    "survey-answer-sleep-time-1": {
        "en-us": "I fall asleep right away",
        "es-mx": "Me quedo dormido enseguida",
    },
    "survey-answer-sleep-time-2": {
        "en-us": "It takes me 10 to 15 minutes",
        "es-mx": "Me lleva 10 a 15 minutos",
    },
    "survey-answer-sleep-time-3": {
        "en-us": "It takes me more than 30 minutes to fall asleep",
        "es-mx": "Tardo más de 30 minutos en dormirme",
    },
    "blog-title": {
        "en-us": "Blog",
        "es-mx": "Blog",
    },
    "blog-recommendations": {
        "en-us": "You might be interested",
        "es-mx": "Te puede interesar",
    },
    "contact-us-title": {
        "en-us": "Do you need to contact us?",
        "es-mx": "¿Necesitas contactarnos?",
    },
    "contact-us-desc": {
        "en-us":
            "If you have something to tell us, send us an email to team@mastercracks.club or contact us at +52 55 3008 5094, you can also fill out the following form and we will contact you, or call us directly at our MasterCracks Line.",
        "es-mx":
            "Si tienes algo qué contarnos, envíanos un mail a team@mastercracks.club o contáctanos al +52 55 3008 5094, también puedes ocupar el siguiente formulario y nosotros te contactaremos, o llámanos directamente a nuestra Línea MasterCracks.",
    },
    "contact-us-input": {
        "en-us": "Message",
        "es-mx": "Mensaje",
    },
    "contact-us-call": {
        "en-us": "or call us to",
        "es-mx": "o llámanos a",
    },
    "contact-us-line": {
        "en-us": "MasterCracks Line",
        "es-mx": "Línea MasterCracks",
    },
    "contact-us-adress": {
        "en-us": "Mar Báltico 2240, 202 CP 44610, GDL, Jalisco.",
        "es-mx": "Mar Báltico 2240, 202 CP 44610, GDL, Jalisco.",
    },
    "contact-us-confirm-title": {
        "en-us": "Message sent",
        "es-mx": "Mensaje Enviado",
    },
    "contact-us-confirm-desc": {
        "en-us": "Thank you for contacting us, we will respond as soon as possible.",
        "es-mx": "Gracias por ponerte en contacto, responderemos lo antes posible.",
    },
    "contact-us-confirm-button": {
        "en-us": "Back to my profile",
        "es-mx": "Volver a mi perfil",
    },
    mastercracks: {
        "en-us": "MasterCracks",
        "es-mx": "MasterCracks",
    },
    "payment-credit-cards": {
        "en-us": "Credit cards",
        "es-mx": "Tarjetas de crédito",
    },
    "payment-debit-cards": {
        "en-us": "Debit cards",
        "es-mx": "Tarjetas de débito",
    },
    "highlights-title": {
        "en-us": "LEARN FROM THOSE WHO HAVE WON EVERYTHING",
        "es-mx": "APRENDE DE LOS QUE YA LO GANARON TODO",
    },
    "highlights-subtitle": {
        "en-us": "Develop the winning mentality of the Cracks by training like the best.",
        "es-mx": "Desarrolla la mentalidad ganadora de los Cracks entrenando como los mejores. ",
    },
    "highlights-desc": {
        "en-us":
            "The cracks gave it all it on the soccer field, and now is your time to rock it on the field of YOUR life. The Mastecracks app programs you for success through physical and mental training.",
        "es-mx":
            "Los cracks la rompieron en la cancha del fúbol, ahora es tu momento para romperla en la cancha de TU vida. La app de Mastecracks te programa para el éxito a través de entrenamientos físicos y mentales.",
    },
    "highlights-1": {
        "en-us": "FIFA WC apperances",
        "es-mx": "Participaciones en Mundiales FIFA",
    },
    "highlights-2": {
        "en-us": "goals scored in top-notch leagues",
        "es-mx": "Goles en Primera División",
    },
    "highlights-3": {
        "en-us": "games with the Mexican National Team",
        "es-mx": "Partidos en la Selección Mayor",
    },
    "highlights-4": {
        "en-us": "Ligas MX conquered",
        "es-mx": "Ligas MX conquistadas",
    },
    "personalized-training-title-1": {
        "en-us": "Your tailor-made workout",
        "es-mx": "Tu entrenamiento personalizado",
    },
    "personalized-training-subtitle": {
        "en-us":
            "We know you want to success, but to achieve it, you have to train hard. In MasterCracks, we designed an application for you, with personalized training for achieve your goals.",
        "es-mx":
            "Sabemos que quieres el éxito, pero para lograrlo, tienes que entrenar. En MasterCracks diseñamos una aplicación para ti, con un entrenamiento personalizado para que logres tus objetivos.",
    },
    "personalized-training-desc": {
        "en-us": "You know what you want: we coach you to achieve it!",
        "es-mx": "Tu sabes qué quieres: ¡nosotros te coacheamos para que lo logres!",
    },
    "personalized-training-title-2": {
        "en-us": "How does it work?",
        "es-mx": "¿Cómo funciona?",
    },
    "training-programs-title": {
        "en-us": "Training programs",
        "es-mx": "Programas de entrenamiento",
    },
    "training-programs-subtitle": {
        "en-us": "Our programs are interactive, short and dynamic.",
        "es-mx": "Nuestros programas son interactivos, cortos y dinámicos.",
    },
    "training-programs-desc": {
        "en-us":
            "With our app, train like the cracks through programs that -based on science- will guide you to get back the control of your mind, body and emotions, regardless of the challenges you might be facing.",
        "es-mx":
            "Con nuestra app, entrena como los cracks a través de programas que, basados en la ciencia, te van a guiar para que tengas control de tu mente, cuerpo y emociones, sin importar el reto que tengas frente.",
    },
    "coaches-title": {
        "en-us": "Who coaches you?",
        "es-mx": "¿Quién te coachea?",
    },
    "coaches-subtitle": {
        "en-us": "You don't have to play in the First Division to have First Division coaches!",
        "es-mx": "¡No tienes que jugar en primera para tener coaches de Primera!",
    },
    "coaches-desc": {
        "en-us":
            "The soccer cracks and renowned coaches come together to train you. For the first time, everyone in the same place working for you!  Start from your cell phone and transform your life.",
        "es-mx":
            "Los cracks y reconocidos coaches se unen para ser tus entrenadores. Por primera vez, todos en el mismo lugar trabajando para ti, te están esperando para que, desde tu celular, transformes tu vida.",
    },
    "try-it-cta": {
        "en-us": "Try it now",
        "es-mx": "Pruébala ahora",
    },
    "step-by-step-title": {
        "en-us": "How does it work?",
        "es-mx": "¿Cómo funciona?",
    },
    "step-by-step-desc": {
        "en-us":
            "Download the MasterCracks App, register by answering some questions that will help us optimize your user experience, and start your transformational journey from the comfort of your home!",
        "es-mx":
            "Descarga la App de MasterCracks, regístrate contestando algunas preguntas que nos ayudarán a optimizar tu experiencia de uso, ¡y empieza tu camino de transformación desde la comodidad de tu hogar!",
    },
    "step-by-step-1": {
        "en-us": "Download the App",
        "es-mx": "Baja la Aplicación",
    },
    "step-by-step-2": {
        "en-us": "Answer a few simple questions",
        "es-mx": "Contesta unas sencillas preguntas",
    },
    "step-by-step-3": {
        "en-us": "Get your tailor-made training",
        "es-mx": "Recibe tu entrenamiento personalizado.",
    },

    "branding-main": {
        "en-us": "Dominate your life with",
        "es-mx": "Domina tu vida con",
    },
    "branding-slogan-1": {
        "en-us": "Take the learnings from our field to yours.",
        "es-mx": "Lleva los aprendizajes denuestra cancha a la tuya",
    },
    "download-cta": {
        "en-us": "Download the App",
        "es-mx": "Descarga la app",
    },
    "cracks-secrets-title": {
        "en-us": "The 11 Secrets of the Cracks®",
        "es-mx": "Los 11 secretos de los Cracks®",
    },
    "cracks-secrets-desc": {
        "en-us":
            "Our training methodology is based on the formula that brought the great Cracks of soccer to the top. Through the 11 secrets, you will develop the key skills that led them to success, so that you can be the protagonist of YOUR game.",
        "es-mx":
            "Nuestra metodología de entrenamiento se basa en las cualidades que llevaron a la cima a los grandes Cracks del fútbol. A través de los 11 secretos desarrollarás las habilidades clave que los llevaron al éxito, para que seas el protagonista de TU juego.",
    },

    "results-title": {
        "en-us": "Get results!",
        "es-mx": "¡Logra resultados!",
    },
    "results-desc-1": {
        "en-us":
            "<span class='bold'>Dominate your body</span> with programs to improve your diet and your rest, and fitness exercises tailored to your needs.",
        "es-mx":
            "<span class='bold'>Domina tu cuerpo</span> con programas para mejorar tu alimentación y tu descanso y ejercicios de fitness a tu medida.",
    },
    "results-desc-2": {
        "en-us":
            "<span class='bold'>Dominate your mind and your emotions</span> with personalized training.",
        "es-mx":
            "<span class='bold'>Domina tu mente y tus emociones</span> con entrenamientos personalizados.",
    },
    "results-desc-3": {
        "en-us":
            "<span class='bold'>Dominate your personal finances</span> with <span class='bold'>step-by-step</span> practical courses.",
        "es-mx":
            "<span class='bold'>Domina tus finanzas personales</span> con cursos prácticos <span class='bold'>paso a paso.</span>",
    },
    "branding-slogan-2": {
        "en-us": "Because your passion is the base of your transformation.",
        "es-mx": "Porque tu pasión es la base de tu transformación.",
    },
    "personalized-training-title": {
        "en-us": "Your training",
        "es-mx": "Tu entrenamiento",
    },
    "banner-secrets-title": {
        "en-us": "Discover the secrets of the Cracks",
        "es-mx": "Conoce los secretos de los Cracks",
    },
    "banner-secrets-desc": {
        "en-us":
            "We put at your disposal the 11 secrets that we discovered together with the cracks so that you too can be a “Crack of your life”, impacting the fundamental aspects to achieve what you propose.",
        "es-mx":
            "Ponemos a tu alcance los 11 secretos que descubrimos junto con los cracks para que seas tú también un “Crack de tu vida”, impactando los aspectos fundamentales para lograr lo que te propongas.",
    },
    "banner-secrets-button": {
        "en-us": "Discover",
        "es-mx": "Descubrir",
    },
    "personalized-programs-title": {
        "en-us": "Programs for you",
        "es-mx": "Programas para ti",
    },
    "personalized-programs-desc": {
        "en-us": "Daily guide to change your life",
        "es-mx": "Guía diaria que cambiará tu vida",
    },
    "subtitle-podcast": {
        "en-us": "Podcast",
        "es-mx": "Podcast",
    },
    "subtitle-podcast-desc": {
        "en-us": "Exclusive interviews with sports legends, experts and media personalities",
        "es-mx": "Entrevistas exclusivas con cracks del fútbol, expertos y personalidades.",
    },
    "coaching-staff-title": {
        "en-us": "Your coaching staff",
        "es-mx": "Tu cuerpo técnico",
    },
    "subtitle-stories-desc": {
        "en-us": "Get to know more about the Cracks.",
        "es-mx": "Conoce más de los Cracks.",
    },
    "subtitle-visualizations": {
        "en-us": "Visualizations",
        "es-mx": "Visualizaciones",
    },
    "subtitle-visualizations-desc": {
        "en-us": "Perfect for start your day.",
        "es-mx": "Ideal para empezar tu día.",
    },
    "show-more": {
        "en-us": "Show more",
        "es-mx": "Ver más",
    },
    "academias-por-la-paz-town-title": {
        "en-us": "Municipality",
        "es-mx": "Municipio",
    },
    "academias-por-la-paz-activity-title": {
        "en-us": "Discipline",
        "es-mx": "Disciplina",
    },
});

export type DataText = typeof DATA_TEXT;

export type UuidDataText = keyof DataText[UuidLanguage];

export const UUID_TEXT = Object.keys(DATA_TEXT[DEFAULT_LANGUAGE]) as UuidDataText[];

function get<Data extends { [K in keyof Data]: { [L in UuidLanguage]: string } }>(data: Data) {
    type UuidText = keyof Data;
    const uuids = Object.keys(data) as UuidText[];
    return DATA_LANGUAGE.reduce(
        (acc, uuidLang) => ({
            ...acc,
            [uuidLang]: uuids.reduce(
                (record, uuidText) => ({
                    ...record,
                    [uuidText]: data[uuidText][uuidLang],
                }),
                {} as { [T in UuidText]: Data[UuidText][UuidLanguage] },
            ),
        }),
        {} as { [L in UuidLanguage]: { [U in UuidText]: Data[U][L] } },
    );
}
