import { PathCreate } from "@platform/react-storybook/Component/Router/utils";
import { PathGetHome } from "./_segments/home";
import { PathGetSupport } from "./_segments/support";
import { PathGetBlog } from "./_segments/blog";

export function PathGetWww<R extends string>(ROOT: R) {
    const { HOME } = PathGetHome(ROOT, "www");
    const { SUPPORT } = PathGetSupport(ROOT);
    const { BLOGS } = PathGetBlog(ROOT);

    return {
        ROOT,
        get WWW() {
            return {
                HOME,
                SUPPORT,
                ABOUT: PathCreate(ROOT, "about"),
                CHAT: PathCreate(ROOT, "chat"),
                BLOGS,
            };
        },
    };
}
